import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import mapDispatchToProps from '../../../actions/index';
import FormContainer from '../FormContainer/FormContainer';
import TextInput from '../../shared/TextInput/TextInput';
import PasswordInput from '../../shared/PasswordInput/PasswordInput';
import Button from '../../shared/Button/Button';
import loginHoc from '../LoginHoc/LoginHoc';
import LinkRef from '../LinkRef/LinkRef';

const Login = (props) => {
  const { t } = useTranslation();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      props.doLogin(username, password);
    }
  };

  useEffect(() => {
    document.title = 'Timby - Login';
  }, []);

  return (
    <FormContainer
      title={t('login')}
      handleKeyDown={handleKeyDown}
      inputs={(
        <>
          <TextInput
            value={username}
            handleChange={(event) => setUsername(event.target.value)}
            placeholder={t('usernamePlaceholder')}
            title={t('username')}
          />
          <PasswordInput
            value={password}
            handleChange={(event) => setPassword(event.target.value)}
            placeholder={t('passwordPlaceholder')}
            title={t('password')}
            type="password"
          />
        </>
      )}
      submit={(
        <>
          <Button text={t('signin')} primary large handler={() => { props.doLogin(username, password); }} />
          <LinkRef to="/forgot-password" text={t('forgotMyPassword')} />
        </>
      )}
    />
  );
};

export default loginHoc(connect(null, mapDispatchToProps)(Login));

import {
  GET_STORIES,
  SELECT_STORY,
  UPDATE_STORY,
  DELETE_STORY,
  ADD_STORY,
} from '../types';

const initialState = {
  stories: [],
};

const storiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_STORIES:
      return {
        ...state,
        stories: action.payload.stories,
      };

    case ADD_STORY: {
      return {
        ...state,
        stories: [...state.stories, action.payload]
      };
    }
    case SELECT_STORY: 
      return {
        ...state,
      };
    case UPDATE_STORY:
      const affectedStory = action.payload;

      const updatedStories = state.stories.map((story) => {
        if (story.uuid === affectedStory.uuid) {
          return {...affectedStory};
        } 
        return {...story};
      });

      return {
        ...state,
        stories: [...updatedStories]
      };
    case DELETE_STORY:
      const storyId = action.payload;
      const filteredStories = state.stories.filter((elem) => {
        return (elem.uuid !== storyId);
      });
      return {
        ...state,
        stories: [...filteredStories],
      };
    default:
      return state;
  }
};

export default storiesReducer;

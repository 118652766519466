import React, { useState } from 'react';
import { Accordion } from 'semantic-ui-react';
import clsx from 'clsx';

const Collapsible = (props) => {
  const [activeIndex, setActiveIndex] = useState(-1);
  const [editMode, setEditMode] = useState(
    Array.from({ length: props.elements.length }).map(() => (false))
  );

  const cancelEditMode = (index) => {
    const updatedEditMode = [...editMode];
    updatedEditMode[index] = false;
    setEditMode(updatedEditMode);
    setActiveIndex(-1);
  };

  const moveToEditMode = (index) => {
    const updatedEditMode = Array.from({ length: props.elements.length }).map(() => (false));
    updatedEditMode[index] = true;
    setEditMode(updatedEditMode);
    setActiveIndex(index);
  };

  return (
    <Accordion
      className={clsx("collapsible__wrapper", {
        "collapsible__wrapper--bordered": props.bordered,
      })}
      styled
    >
      {props.elements.map((elem, index) => (
        <React.Fragment key={`${elem.header}-${index}`}>
          {props.editableMode ?
            <Accordion.Title
              active={activeIndex === index}
              index={index}
            >
              {props.renderHeader(elem, editMode[index] ?
                () => cancelEditMode(index) :
                () => moveToEditMode(activeIndex === index ? -1 : index),
                editMode[index]
              )}
            </Accordion.Title>
            :
            <Accordion.Title
              active={activeIndex === index}
              index={index}
              onClick={() => setActiveIndex(activeIndex === index ? -1 : index)}
            >
              {props.renderHeader(elem)}
            </Accordion.Title>
          }
          <Accordion.Content active={activeIndex === index || elem.active}>
            {props.renderContent(elem, editMode[index], editMode[index] ?
              () => cancelEditMode(index) :
              () => moveToEditMode(activeIndex === index ? -1 : index))}
          </Accordion.Content>
        </React.Fragment>
      ))}
    </Accordion >
  );
};

export default Collapsible

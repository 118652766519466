export default {
  login: 'تسجيل الدخول',
  username: 'اسم المستخدم',
  password: 'كلمه السر',
  email: 'بريد إلكتروني',
  firstname: 'الاسم الأول',
  lastname: 'الكنية',
  role: 'دور',
  passwordPlaceholder: 'أدخل كلمة المرور',
  usernamePlaceholder: 'ادخل اسم المستخدم',
  signin: 'تسجيل الدخول',
  forgotMyPassword: 'لقد نسيت كلمة المرور',
  requirements: 'متطلبات',
  resetPassword: 'إعادة تعيين كلمة المرور',
  resetPasswordSubtitle: 'أدخل اسم المستخدم المرتبط بحسابك.',
  resetPasswordSubmit: 'ارسل لي رابط إعادة تعيين كلمة المرور',
  resetLinkSent: 'إرسالها رابط إعادة تعيين',
  resetLinkSubtitle: 'لقد أرسلنا رابطًا لإعادة تعيين كلمة مرورك إلى البريد الإلكتروني المرتبط بحسابك.',
  resetLinkSubmit: 'ارسل لي الارتباط مرة أخرى',
  createNewPassword: 'إنشاء كلمة مرور جديدة',
  passwordReq1: '7 أحرف على الأقل',
  passwordReq2: 'يحتوي على حرف واحد كبير',
  passwordReq3: 'يحتوي 1 عدد',
  passwordReq4: 'يحتوي على حرف خاص واحد',
  passwordReq5: 'تحتوي على حرف واحد صغير',
  newPassword: 'كلمة السر الجديدة',
  newPasswordPlaceholder: 'أدخل كلمة المرور الجديدة',
  reEnterNewPassword: 'إعادة إدخال كلمة المرور الجديدة',
  reEnterNewPasswordPlaceholder: 'إعادة إدخال كلمة المرور الجديدة',
  createNewPasswordSubmit: 'تغيير كلمة المرور الخاصة بي',
  twoFactorAuth: 'أدخل عنوان البريد الالكتروني',
  twoFactorAuthSubtitle: 'لقد أرسلنا رمز التحقق إلى البريد الإلكتروني المرتبط بحسابك.',
  twoFactorAuthSubmit: 'ارسل لي رمز التحقق',
  twoFactorCode: 'أدخل رمز التحقق',
  twoFactorCodeSubtitle: 'لقد أرسلنا رمز التحقق إلى عنوان البريد الإلكتروني المرتبط بهذا الحساب.',
  enterCode: 'ادخل الرمز',
  enterCodePlaceholder: 'أدخل رمز 6 أرقام',
  twoFactorCodeSubmit: 'يتأكد',
  twoFactorCodeLink: 'إعادة قانون بلدي',
  twoFactorCodeCheckbox: 'لا تسألني عن 2FA مرة أخرى',
  reportsTitle: 'تقارير',
  newReport: 'تقرير جديد',
  admin: 'مشرف',
  tags: 'الكلمات',
  users: 'المستخدمين',
  exportreport: 'تقارير التصدير',
  profile: 'الملف الشخصي',
  terminology: 'المصطلح',
  mysettings: 'اعداداتي',
  edit: 'يحرر',
  organizations: 'المنظمات',
  analytics: 'تحليلات',
  analyticsTitle: 'هذا أسابيع يركز',
  analyticsSubmissions: 'المقدمة',
  analyticsReportByCat: 'أعلى التقارير حسب الفئة',
  analyticsFromLastWeek: 'من الأسبوع الماضي',
  analyticsRepSubmited: 'التقارير المقدمة',
  analyticsRepVerified: 'تقارير التحقق منها',
  analyticsIssuesResp: 'وردت القضايا',
  analyticsStoriesCreated: 'قصص خلق',
  analyticsGeographicPrio: 'الأولويات الجغرافية',
  analyticsPopularKeywords: 'كلمات شعبية',
  analyticsKeyword: 'Keyword',
  analyticsMentions: 'تنويهات',
  analyticsUsertypes: 'أنواع المستخدم',
  analyticsTopUsers: 'أعلى الصفحة عدد',
  analyticsUsers: 'المستخدمين',
  analyticsActivity: 'نشاط',
  contactUsers: 'المستخدمين الاتصال',
  send: 'إرسال',
  sendToEveryone: 'ارسل الى الجميع',
  sendSpecificUsers: 'إرسال رسالة إلى مستخدمين محددين',
  selectUsers: 'حدد المستخدمين',
  enterMessage: 'أدخل رسالتك هنا',
  search: 'يبحث',
  addNew: 'اضف جديد',
  save: 'يحفظ',
  ok: 'Ok',
  enterFirstName: 'أدخل الاسم الأول',
  enterLastName: 'إدخال اسم آخر',
  enterEmailPlaceholder: 'أدخل عنوان البريد الالكتروني',
  createReport: 'إنشاء تقرير',
  reportDetails: 'تفاصيل تقرير',
  reportTitleLabel: 'عنوان التقرير',
  reportTitlePlaceholder: 'أدخل عنوان التقرير',
  reportDescriptionLabel: 'وصف تقرير',
  reportDescriptionPlaceholder: 'أدخل وصف التقرير',
  reportAuthorLabel: 'حدد الكاتب',
  reportAuthorPlaceholder: 'حدد الكاتب',
  reportHour: 'ساعة',
  reportMinute: 'دقيقة',
  reportMapLabel: 'تحديد على الخريطة',
  reportAddMedia: 'وسائل الإعلام إضافة',
  reportUploadMedia: 'تحميل الصور والفيديو والصوت أو PDF',
  reportDragMedia: 'ملفات سحب وإسقاط وسائل الاعلام هنا',
  reportSupportMedia: 'نحن نؤيد االصور والفيديو والصوت أو ملفات PDF',
  reportBrowseMedia: 'تصفح',
  reportUploadFiles: 'الملفات التي يتم تحميلها',
  reportPublic: 'عام',
  reportSeeActivity: 'انظر النشاط',
  reportUnverified: 'غير مثبت عليه',
  reportVerified: 'تم التحقق',
  reportVerify: 'تحقق',
  reportSearch: 'تقارير البحث',
  reportedBy: 'تم عمل تقرير بواسطة',
  reportWhatHappening: 'ماذا يحدث',
  reportLocation: 'موقع',
  reportCoordinates: 'إحداثيات',
  copyDMS: 'نسخة DMS',
  copyDMM: 'نسخة DD',
  reportImageEvidence: 'صورة الدليل',
  reportImageDescription: 'وصف الصورة',
  reportSaveClose: 'حفظ وإغلاق',
  reportAudioEvidence: 'الأدلة السمعية',
  reportPDFEvidence: 'PDF التحميلات',
  reportVideoEvidence: 'فيديو دليل',
  reportVideoDescription: 'وصف الفيديو',
  reportUpdates: 'التحديثات',
  reportActivityUploadMedia: 'وسائل الإعلام تحميل',
  reportActivityAssignUser: 'المستخدم تعيين',
  reportActivitySetUrgency: 'مجموعة الاستعجال',
  reportActivityPlaceholder: 'أضف تعليقا أو وسائط إضافية لهذا التقرير',
  updateAction: 'تحديث',
  editReport: 'تحرير تقرير',
  goBack: 'عودة',
  reportCritical: 'هذا التقرير هو أمر حاسم',
  reportVeryUrgent: 'هذا التقرير عاجل للغاية',
  reportUrgent: 'هذا التقرير عاجل',
  reportFilter: 'منقي',
  reportStatus: 'حالة',
  reportFolders: 'المجلدات',
  urgent: 'عاجل',
  reportDateCreated: 'تاريخ الإنشاء',
  reportAssignment: 'تكليف',
  reportAssignee: 'الوكيل',
  reportAuthor: 'مؤلف',
  reportDateCreatedPlaceholder: 'حدد تاريخ',
  reportDateLabel: 'متى حدث يحدث؟',
  reportDatePlaceholder: 'أدخل تاريخ التقرير',
  reportMapTitle: 'أين حدثت',
  reportDMSCoordinate: 'إحداثيات DMS',
  reportDMMCoordinate: 'إحداثيات DMM',
  contactRecent: 'اشعارات حديثة',
  storyTitle: 'إنشاء المحتوى',
  storyCreate: 'إنشاء قصة',
  storySearch: 'محتوى البحث',
  published: 'نشرت',
  updated: 'محدث',
  publishedOn: 'نشرت يوم',
  storyType: 'نوع القصة',
  unpublish: 'إلغاء النشر',
  publish: 'ينشر',
  unpublished: 'غير منشورة',
  readFullReport: 'قراءة التقرير الكامل',
  coverImage: 'صورة الغلاف',
  storyTextContent: 'محتوى النص',
  storyTitlePlaceholder: 'أدخل لقبك هنا',
  storySubtitlePlaceholder: 'أدخل العنوان الفرعي الخاص بك هنا',
  closeReport: 'تقرير إغلاق',
  storyAddNew: 'أضف مربع نص جديد',
  saveChanges: 'احفظ التغييرات',
  userOrganizationPlaceholder: 'أدخل منظمات المستخدم',
  enable2FA: 'تمكين 2FA',
  cancel: 'يلغي',
  deleteWarning: 'لا يمكن التراجع',
  createTagGroup: 'إنشاء مجموعة علامات جديدة',
  createTag: 'إنشاء علامة جديدة للمجموعة',
  createUser: 'إنشاء مستخدم جديد',
  createOrganization: 'إنشاء منظمة جديدة',
  createLayer: 'قم بإنشاء طبقة جديدة',
  deleteTagGroup: '删除标签组',
  deleteTagGroupDescription: 'هل أنت متأكد أنك تريد حذف مجموعة العلامات هذه؟',
  deleteTag: 'حذف عنصر العلامة',
  deleteTagDescription: 'هل أنت متأكد أنك تريد حذف عنصر العلامة هذا؟',
  myTags: 'علاماتي',
  editTagGroup: 'تحرير مجموعة العلامات',
  mapParameters: 'معلمات الخريطة',
  latitude: 'خط العرض',
  longitude: 'خط الطول',
  zoomLevel: 'مستوى التكبير',
  layers: 'طبقات',
  vector: 'المتجه',
  raster: 'النقطية',
  layerName: 'اسم طبقة',
  layerShapeFiles: 'نحن نؤيد ملفات أشكال، KML، وGeoJSON أيضا',
  dragdropLayers: 'قم بسحب وإسقاط الطبقات هنا',
  color: 'اللون',
  translations: 'ترجمة',
  name: 'اسم',
  defaultLanguage: 'اللغة الافتراضية',
  selectReportToExport: 'اختر التقارير إلى التصدير',
  exportAll: 'تصدير الكل',
  export: 'تصدير',
  enterNameIn: 'أدخل الاسم في',
  enterLayerNameIn: 'أدخل اسم الطبقة في',
  enterLayerName: 'أدخل اسم الطبقة',
  rasterHostedUrl: 'URL مستضافة النقطية',
  enterRasterHostedUrl: 'أدخل عنوان URL المستضاف النقطي',
  enterColor: 'أدخل اللون',
  enterUserRole: 'பயனர் பாத்திரத்தை உள்ளிடவும்',
  deleteSuccess: 'نعم احذف',
  subject: 'موضوع',
  enterSubject: 'أدخل الموضوع',
  recipients: 'المستلمون',
  deleteUser: 'مسح المستخدم',
  deleteUserDescription: 'هل أنت متأكد أنك تريد حذف هذا المستخدم؟',
  deleteOrganization: 'حذف المنظمة',
  deleteOrganizationDescription: 'هل أنت متأكد أنك تريد حذف هذه المنظمة؟',
  singleSelect: 'تحديد مفرد',
  multiSelect: 'تحديد متعدد',
  textField: 'مجال التحرير مكان كتابة النص',
  searchInTheMap: 'ابحث أثناء تحريك الخريطة',
  select: 'يختار',
  enterReportTag: 'أدخل ملف {{tag}} الخاص بالتقرير',
  enterZoom: 'حدد تكبير',
  basemaps: 'Basemaps',
  map: 'خريطة',
  satelitte: 'الأقمار الصناعية',
  deleteReport: 'حذف التقرير',
  deleteReportDescription: 'هل أنت متأكد أنك تريد حذف هذا التقرير؟',
  closeAction: 'يغلق',
  reportLifecycle: 'دورة الحياة',
  languages: 'اللغات',
  additionalSettings: 'إعدادات إضافية',
  deleteLayer: 'حذف الطبقة',
  deleteLayerDescription: 'هل أنت متأكد أنك تريد حذف هذه الطبقة؟',
  apply: 'تطبيق',
  reset: 'மீட்டமை',
  unverify: 'رفض',
  enabledLanguages: 'اللغات الممكنة',
  selectLanguages: 'حدد اللغات',
  updateTerminology: 'تحديث المصطلحات',
  currentString: 'السلسلة الحالية',
  resetToDefault: 'إعادة تعيين إلى الافتراضي',
  dragCsv: 'قم بسحب وإسقاط ملف CSV للغة هنا',
  supportCsv: 'نحن ندعم CSV فقط',
  anonymousSignIn: 'عمليات تسجيل دخول مجهولة على الهاتف المحمول',
  mapsOnMobile: 'خرائط على الجوال',
  lessonPath: 'مسار الدروس',
  emergencyAlerts: 'تنبيهات الطوارئ',
  emergencyContactUser: 'مستخدم جهة اتصال الطوارئ',
  emergencyContactNumber: 'رقم الاتصال في حالات الطوارئ',
  contactNumber: 'رقم الاتصال',
  enterCustomLessonPath: 'أدخل مسار الدرس المخصص',
  urgency: 'அவசர',
  analyticsPage: 'صفحة التحليلات',
  notificationsPage: 'صفحة الإخطارات',
  storiesPage: 'صفحة القصص',
  dashboardConfiguration: 'تكوينات لوحة القيادة',
  publicSite: 'الموقع العام',
  mapOnPublicSite: 'الخريطة على الموقع العام',
  grievencePortal: 'بوابة التظلمات',
  localization: 'الموقع',
  timeZone: 'وحدة زمنية',
  mobileOrganizationLabel: 'منظمة تطبيقات الهاتف المتحرك',
  mobileOrganizationPlaceholder: 'اختر منظمة',
  sendByUsers: 'أرسل بواسطة المستخدمين',
  sendByRoles: 'أرسل حسب الدور',
  private: 'خاص',
  reportState: 'حالة',
  date: 'تاريخ',
  startDate: 'تاريخ البدء',
  endDate: 'تاريخ الانتهاء',
  organization: 'منظمة',
  seconds: 'ثواني',
  degrees: 'درجات',
  direction: 'اتجاه',
  default: 'تقصير',
  custom: 'العادة',
  addAdditionalEmergencyNumber: 'أضف رقم اتصال طوارئ إضافي',
  stories: 'القصص',
  messages: 'رسائل',
  addTagGroup: 'أضف مجموعة العلامات',
  addNewUser: 'إضافة مستخدم',
  addNewOrganization: 'أضف منظمة',
  addNewLayer: 'أضف طبقة',
  exportAction: 'يصدر',
};

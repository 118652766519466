import {
  GET_TAGS,
  APPLY_FILTERS,
  RESET_FILTERS,
} from '../types';

const initialState = {
  groupTags: [],
  selectedTags: {},
};

const tagsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TAGS:
      return {
        ...state,
        groupTags: action.payload.tags,
      };

    case APPLY_FILTERS: {
      const { tagGroup, selectedValue } = action.payload;
      const updatedSelectedTags = state.selectedTags;

      if (selectedValue === null || selectedValue === undefined || !selectedValue.length) {
        delete updatedSelectedTags[tagGroup];
      } else {
        updatedSelectedTags[tagGroup] = selectedValue;
      }

      return {
        ...state,
        selectedTags: { ...updatedSelectedTags },
      };
    }

    case RESET_FILTERS: {
      return {
        ...state,
        selectedTags: {},
      };
    }

    default:
      return state;
  }
};

export default tagsReducer;

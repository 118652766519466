import '../../../styles/index.scss';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import t from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Icon } from 'semantic-ui-react';
import clsx from 'clsx';
import AdminLayerContent from '../AdminLayerContent/AdminLayerContent';
import Collapsible from '../../ui/Collapsible/Collapsible';
import 'mapbox-gl/dist/mapbox-gl.css';
import AdminModal from '../AdminModal/AdminModal';
import { ReactComponent as EditIcon } from '../../../assets/images/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/images/delete.svg';
import { ReactComponent as CloseIcon } from '../../../assets/images/close.svg';
import RoundedIcon from '../../shared/RoundedIcon/RoundedIcon';
import mapDispatchToProps from '../../../actions/index';
import DeleteModal from '../DeleteModal/DeleteModal';

const AdminLayers = (props) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [deleteLayerModal, setDeleteLayerModal] = useState(false);
    const [selectedLayer, setSelectedLayer] = useState(null);

    useEffect(() => {
        document.title = 'Timby - Maps/Layers';
    }, []);

    useEffect(() => {
        // getMapInfo()
        props.getOrganizationOptions();
        props.getOrganizationLayers();
    }, [props.selectedOrganizationId]);

    const openDeleteLayerModal = (selectedLayer) => {
        setDeleteLayerModal(true);
        setSelectedLayer(selectedLayer);
    };

    const closeDeleteLayerModal = async () => {
        const response = await props.deleteLayer(selectedLayer.id);
        if (response) {
            setDeleteLayerModal(false);
            setSelectedLayer(null);
        }
    };

    const renderContent = (elem, editableMode, editHandler) => (
        <AdminLayerContent
            languages={props?.languages}
            defaultLanguage={props.defaultLanguage}
            layer={elem}
            editableMode={editableMode}
            onClose={editHandler}
        />
    );

    const renderHeader = (elem, editHandler, editableMode) => (
        <div className={clsx('collapsible__header', {
            'collapsible__header-active': editableMode,
        })}
        >
            <div
                role="button"
                tabIndex={0}
                onKeyDown={editHandler}
                onClick={editHandler}
                className="collapsible__header__text"
            >
                <Icon name="chevron right" />
                {elem[`name_${props.defaultLanguage}`] || elem.name_en || ''}
            </div>
            <div className="collapsible__actions">
                <RoundedIcon
                    SvgComponent={editableMode ? CloseIcon : EditIcon}
                    hover
                    handler={editHandler}
                    border
                />
                <RoundedIcon
                    SvgComponent={DeleteIcon}
                    hover
                    border
                    handler={() => openDeleteLayerModal(elem)}
                />
            </div>
        </div>
    );

    return (
        <div className="admin-container__content whitepagescrollbar">
            <div className="max-body-width">
                <div className="admin-container-header">
                    <h1 className="admin-container-header-title">
                        {t('layers')}
                    </h1>
                    <div className="admin-container-header-buttons">
                        <AdminModal
                            actionName={t('addNewLayer')}
                            onClose={() => setOpen(false)}
                            open={open}
                            onOpen={() => setOpen(true)}
                            header={t('createLayer')}
                        >
                            <AdminLayerContent
                                edit={false}
                                onClose={() => setOpen(false)}
                                languages={props?.languages}
                                defaultLanguage={props.defaultLanguage}
                            />
                        </AdminModal>
                    </div>
                </div>
                <div className="admin-container-body">
                    <Collapsible
                        bordered
                        editableMode
                        elements={props?.layers}
                        renderHeader={renderHeader}
                        renderContent={renderContent}
                    />
                </div>
                <DeleteModal
                    onClose={() => setDeleteLayerModal(false)}
                    open={deleteLayerModal}
                    onOpen={() => setDeleteLayerModal(true)}
                    hasTrigger={false}
                    header={t('deleteLayer')}
                    subtitle={t('deleteLayerDescription')}
                    deleteAction={closeDeleteLayerModal}
                />
            </div>
        </div>
    );
};

const mapStateToProps = ({ admin, common }) => ({
    languages: common.languages,
    defaultLanguage: common.defaultLanguage,
    selectedOrganizationId: admin.selectedOrganizationId,
    organizationOptions: admin.organizationOptions,
    layers: admin.layers,
});

AdminLayers.propTypes = {
    organizationOptions: t.object.isRequired,
    getOrganizationOptions: t.func.isRequired,
    deleteLayer: t.func.isRequired,
    updateOrganizationOptions: t.func.isRequired,
    getOrganizationLayers: t.func.isRequired,
    languages: t.array,
    defaultLanguage: t.string,
    layers: t.array,
    selectedOrganizationId: t.string.isRequired,
};

AdminLayers.defaultProps = {
    languages: [],
    defaultLanguage: 'en',
    layers: [],
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminLayers);

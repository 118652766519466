import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import mapDispatchToProps from '../../../actions/index';
import FormContainer from '../FormContainer/FormContainer';
import TextInput from '../../shared/TextInput/TextInput';
import Button from '../../shared/Button/Button';
import loginHoc from '../LoginHoc/LoginHoc';

const ForgotPassword = (props) => {
  const [username, setUsername] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    document.title = 'Timby - Forgot Password';
  }, []);

  return (
    <FormContainer
      title={t('resetPassword')}
      subtitle={t('resetPasswordSubtitle')}
      inputs={(
        <TextInput
          value={username}
          handleChange={(event) => setUsername(event.target.value)}
          placeholder={t('usernamePlaceholder')}
          title={t('username')}
        />
      )}
      submit={
        <Button text={t('resetPasswordSubmit')} primary large handler={() => props.requestPasswordRecovery(username)} />
      }
    />
  );
};

export default loginHoc(connect(null, mapDispatchToProps)(ForgotPassword));

/* eslint-disable react/jsx-props-no-spreading */
import { useTranslation } from 'react-i18next';
import React, { useState, createRef, useEffect } from 'react';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import types from 'prop-types';
import clsx from 'clsx';
import '../../../styles/index.scss';
import TextInput from '../../shared/TextInput/TextInput';
import Button from '../../shared/Button/Button';
import CustomRadio from '../../ui/CustomRadio/CustomRadio';
import mapDispatchToProps from '../../../actions/index';

function AdminLayerContent({
  layer, edit = true, onClose, languages, defaultLanguage, editableMode, createLayer, updateLayer,
}) {
  const { t } = useTranslation();
  const [type, setType] = useState(layer?.type || 'vector');
  const [name, setName] = useState(layer ? layer[`name_${defaultLanguage}`] || '' : '');
  const [color, setColor] = useState(layer?.color || '#000000');
  const [rasterURL, setRasterURL] = useState(layer?.location || '');
  const [file, setFile] = useState([]);
  const [layerTranslation, setLayerTranslation] = useState(
    languages.filter((obj) => obj.code !== defaultLanguage)
      .map((elem) => ({ name: layer ? layer[`name_${elem.code}`] || '' : '', text: elem.text, code: elem.code })),
  );
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setLayerTranslation(languages?.filter((obj) => obj.code !== defaultLanguage)
      .map((elem) => {
        const getTranslation = layerTranslation.find((edittedElem) =>
          (elem.code === edittedElem.code),
        );
        return ({
          name: getTranslation ? getTranslation.name || name : name,
          text: elem.text,
          code: elem.code,
        });
      }));
    const defaultName = layerTranslation.find((elem) => (elem.code === defaultLanguage));
    const layerDefaultName = layer ? layer[`name_${defaultLanguage}`] : '';
    setName(defaultName ? defaultName.name || layerDefaultName : (layerDefaultName));
  }, [languages, defaultLanguage, layer]);

  useEffect(() => {
    if (!editableMode) {
      setName(layer ? layer[`name_${defaultLanguage}`] || '' : '');
      setLayerTranslation(languages.filter((obj) => obj.code !== defaultLanguage)
        .map((elem) => ({ name: layer ? layer[`name_${elem.code}`] || '' : '', text: elem.text, code: elem.code })));
      setType(layer ? layer.type : 'vector');
      setRasterURL(layer ? layer.location : '');
      setErrors({});
    }
  }, [editableMode, layer]);

  const dropzoneRef = createRef();
  const openDialog = () => {
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };

  const formatTranslations = () => {
    const translations = {};
    layerTranslation.map((elem) => {
      translations[`${elem.code}`] = elem.name;
      return null;
    });
    translations[`${defaultLanguage}`] = name;
    return translations;
  };

  const handleUpdateLayer = async () => {
    const layerData = {
      name: formatTranslations(),
      type,
    };
    if (type === 'raster') {
      layerData.url = rasterURL;
    }
    if (type === 'vector') {
      layerData.color = color;
    }

    const result = await updateLayer(layer.id, layerData);
    if (result.success) {
      setErrors({});
      onClose();
    } else {
      setErrors(result.errors);
    }
  };

  const handleCreateUser = async () => {
    const layerData = {
      name: formatTranslations(),
      type,
    };
    if (type === 'vector') {
      layerData.color = color;
      layerData.vector = file;
    }
    if (type === 'raster') {
      layerData.url = rasterURL;
    }

    const result = await createLayer(layerData);
    if (result.success) {
      setErrors({});
      onClose();
    } else {
      setErrors(result.errors);
    }
  };

  const handleLayerTranslation = (value, index) => {
    const updatedTranslation = [...layerTranslation];
    updatedTranslation[index].name = value;
    setLayerTranslation(updatedTranslation);
  };

  return (
    <div className={clsx('admin-layer-content__wrapper', {
      'admin-layer-content__wrapper--create': !edit,
    })}
    >
      <div className="admin-layer-content__container">
        <div className="admin-layer-content__left">
          { !edit && (
            <div className="admin-layer-content__radio-group">
              <CustomRadio
                label={t('vector')}
                checked={type === 'vector'}
                onChange={() => setType('vector')}
              />
              <CustomRadio
                label={t('raster')}
                checked={type === 'raster'}
                onChange={() => setType('raster')}
              />
            </div>
          )}
          <TextInput
            title={t('layerName')}
            placeholder={t('enterLayerName')}
            value={name}
            hasError={!!errors[defaultLanguage]}
            errorDescription={errors[defaultLanguage]}
            handleChange={(event) => setName(event.target.value)}
          />
          {type === 'raster'
            && (
            <TextInput
              title={t('rasterHostedUrl')}
              placeholder={t('enterRasterHostedUrl')}
              hasError={!!errors.raster}
              errorDescription={errors.raster}
              value={rasterURL}
              handleChange={(event) => setRasterURL(event.target.value)}
            />
            )}
          {type === 'vector' && (
            <TextInput
              title={t('color')}
              placeholder={t('enterColor')}
              type="color"
              value={color}
              handleChange={(event) => setColor(event.target.value)}
            />
          )}
        </div>
        <div className="admin-layer-content__right">
          {type === 'vector' && !edit
          && (
          <Dropzone
            ref={dropzoneRef}
            noClick
            accept=".geojson, .json, .shp, .shx, .prj, .dbf, .cpg, .kml, .zip"
            onDrop={(acceptedFiles) => setFile(acceptedFiles)}
          >
            {({ getRootProps, getInputProps }) => (
              <div
                className={clsx(
                  'layer-drop__wrapper',
                  { 'layer-drop__wrapper-errors': !!errors.files },
                )}
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                <div className="layer-drop__header">
                  {t('dragdropLayers')}
                </div>
                <div className="layer-drop__subheader">
                  {t('layerShapeFiles')}
                </div>
                <Button text={t('reportBrowseMedia')} medium secondary handler={openDialog} />
              </div>
            )}
          </Dropzone>
          )}
          {file.length > 0 && (
            <div className="layer-drop__header admin-layer-content__right__status">
              {`Files Uploaded: ${file.length}` }
              <span />
            </div>
          )}
        </div>
      </div>
      <div className="admin-layer-content__translations">
        <div className="admin-layer-content__header">
          <div className="admin-layer-content__header-title">
            {t('translations')}
          </div>
        </div>
        {layerTranslation.map((elem, index) => (
          <TextInput
            title={`${t('layerName')} - ${elem.text}`}
            placeholder={`${t('enterLayerNameIn')} ${elem.text}`}
            value={elem.name}
            hasError={!!errors[elem.code]}
            errorDescription={errors[elem.code]}
            handleChange={(event) => handleLayerTranslation(event.target.value, index)}
          />
        ))}
      </div>
      <div className={clsx('admin-layer-content__actions', {
        'admin-layer-content__actions--create': !edit,
      })}
      >
        {edit
          && (
          <Button
            text={t('save')}
            primary
            medium
            handler={() => handleUpdateLayer()}
          />
          )}
      </div>
      {!edit
        && (
        <div className="admin-modal__actions">
          <Button text={t('cancel')} secondary medium handler={onClose} />
          <Button text={t('save')} primary medium handler={() => handleCreateUser()} />
        </div>
        )}
    </div>
  );
}

AdminLayerContent.propTypes = {
  layer: types.object,
  edit: types.bool,
  onClose: types.func.isRequired,
  languages: types.array.isRequired,
  createLayer: types.func.isRequired,
  updateLayer: types.func.isRequired,
  defaultLanguage: types.string.isRequired,
  editableMode: types.bool,
};

export default connect(null, mapDispatchToProps)(AdminLayerContent);

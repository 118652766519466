import '../../../styles/index.scss';
import React, { useEffect, useState } from 'react';
import { format, parse } from 'date-fns';
import ReactMapboxGl, {
  Layer, ZoomControl, Source, GeoJSONLayer, Cluster, Marker,
} from 'react-mapbox-gl';
import DrawControl from 'react-mapbox-gl-draw';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ReactComponent as LayersIcon } from '../../../assets/images/layers.svg';
import { ReactComponent as CloseIcon } from '../../../assets/images/close.svg';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import CustomCheckbox from '../../ui/CustomCheckbox/CustomCheckbox';
import MapPopup from './MapPopup';
import mapDispatchToProps from '../../../actions/index';
import CustomRadio from '../../ui/CustomRadio/CustomRadio';
import { MAPBOX_SECRET_KEY, REPORT_EVENT_DATE_PARSER } from '../../../shared/consts';
import {history} from "../../../index";

// sample layers
import limit from '../../../assets/Limite.json';
import roads from '../../../assets/Roads.json';

const MAP = 'map';
const SATELLITE = 'satelitte';

const Map = ReactMapboxGl({
  attributionControl: false,
  trackResize: true,
  accessToken:
    MAPBOX_SECRET_KEY,
});

const sampleLayers = [
  {
    name: 'Limit',
    id: 'limit',
    type: 'vector',
    color: '0000FF',
    location: limit,
    active: false,
  },
  {
    name: 'Roads',
    id: 'roads',
    type: 'vector',
    color: '000000',
    location: roads,
    active: false,
  },
  {
    name: 'Loss Layer',
    id: 'forest_loss',
    type: 'raster',
    location: "https://earthengine.google.org/static/hansen_2013/loss_alpha/{z}/{x}/{y}.png",
    active: false,
  },
  {
    name: 'Forest Gain',
    id: 'forest_gain',
    type: 'raster',
    location: "https://earthengine.google.org/static/hansen_2013/gain_alpha/{z}/{x}/{y}.png",
    active: false,
  },
  {
    name: 'Tree Cover',
    id: 'tree_cover',
    type: 'raster',
    location: "https://earthengine.google.org/static/hansen_2013/tree_alpha/{z}/{x}/{y}.png",
    active: false,
  },
  {
    name: 'Glad Alerts',
    id: 'glad_alerts',
    type: 'raster',
    location: "https://production-api.globalforestwatch.org/v1/true-color-tiles/glad/{z}/{x}/{y}",
    active: false,
  },
]

function ReportsMap(props) {
  const { t } = useTranslation();
  const { longitude, latitude, zoom } = props.organizationOptions;
  const [searchMap, setSearchMap] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [showLayers, setShowLayers] = useState(false);
  const [mapType, setMapType] = useState(MAP);
  const [layers, setLayers] = useState(sampleLayers);
  const [alreadyCentered, setAlreadyCentered] = useState(false);
  const [zoomMap, setZoomMap] = useState([zoom || 11]);
  const [longitudeMap, setLongitudeMap] = useState(parseFloat(longitude) || -0.2416815);
  const [latitudeMap, setLatitudeMap] = useState(parseFloat(latitude) || 51.5285582);
  const [selectedReport, setSelectedReport] = useState({});
  const [reportsToShow, setReportsToShow] = useState([]);

  useEffect(() => {
    if (props.organizationOptions.zoom && props.organizationOptions.longitude && props.organizationOptions.latitude) {
      setZoomMap([props.organizationOptions.zoom]);
      setLongitudeMap(parseFloat(props.organizationOptions.longitude));
      setLatitudeMap(parseFloat(props.organizationOptions.latitude));
    }
  }, [props.organizationOptions]);

  /* @fixme: Tried fixing layers, but I'm still experiencing network issues. I don't have time to try and fix this now.
  // thisismybackyard.fa02eb737d6b702a240eebfa5ac1f373
  // elem.location.replace('mapbox://', 'mapbox://thisismybackyard.'),
  useEffect(() => {
    const newLayers = props.layers.map((elem) => ({
      ...elem,
      location: 'mapbox://mapbox.country-boundaries-v1',
      active: false
    }));
    setLayers(newLayers);
  }, [props.layers]);
  */

  useEffect(() => {
    const mapTakenCoordinates = [];
    const checkedReports = props.reports.map((feature) => {
      let finishLatitude = parseFloat(feature.latitude);
      let finishLongiude = parseFloat(feature.longitude);

      const noisedLatitude = parseFloat(feature.latitude).toFixed(4);
      const noisedLongitude = parseFloat(feature.longitude).toFixed(4);
      let prevCoordinate = `${noisedLatitude},${noisedLongitude}`;

      while (mapTakenCoordinates.includes(prevCoordinate)) {
        finishLatitude = (parseFloat(feature.latitude) + (Math.random() * 0.00006) - 0.00003);
        finishLongiude = (parseFloat(feature.longitude) + (Math.random() * 0.00006) - 0.00003);
        prevCoordinate = `${finishLatitude.toFixed(5)},${finishLongiude.toFixed(5)}`;
      }
      mapTakenCoordinates.push(prevCoordinate);
      return ({
        ...feature, longitude: finishLongiude, latitude: finishLatitude, originalLatitude: feature.latitude, originalLongitude: feature.longitude,
      });
    });
    setReportsToShow(checkedReports);
  }, [props.reports]);

  useEffect(() => {
    if (showLayers) setAlreadyCentered(true);
  }, [showLayers]);

  const onZoomEnd = (_, event) => {
    if (event.fitboundUpdate && alreadyCentered) {

    }
  };

  const styles = {
    clusterMarker: {
      width: 28,
      height: 28,
      borderRadius: '50%',
      backgroundColor: '#DB807F',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'black',
      fontSize: 16,
      fontFamily: 'SFPro-Text',
      fontWeight: 'bold',

    },
    marker: {
      width: 16,
      height: 16,
      borderRadius: '50%',
      backgroundColor: '#DB807F',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
    },
  };

  const clusterMarker = (
    coordinates,
    pointCount,
  ) => (
    <Marker
      key={coordinates.toString()}
      coordinates={coordinates}
      className="report-map-cluster"
      style={styles.clusterMarker}
    >
      <div>{pointCount}</div>
    </Marker>
  );

  const selectReportPopup = async (report) => {
    setAlreadyCentered(true);
    const selectedReportInfo = await props.getMapSelectedReportInfo(report.uuid);
    if (selectedReportInfo) {
      const reportMapInfo = reportsToShow.find((elem) => (elem.uuid === selectedReportInfo.uuid));
      setSelectedReport({
        ...selectedReportInfo,
        longitude: reportMapInfo.longitude,
        latitude: reportMapInfo.latitude,
      });
      setShowPopUp(true);
    }
  };

  const setLayerActive = (elem, index) => {
    const selectedLayer = layers[index];
    selectedLayer.active = !selectedLayer.active;
    const newLayers = layers.map((layer) => {
      if (elem.id === layer.id) {
        return { ...selectedLayer };
      }
      return { ...layer };
    });
    setLayers([...newLayers]);
  };

  const onDrawCreate = ({ features }) => {
    console.log('onDrawCreate', features);
  };

  const onDrawUpdate = ({ features }) => {
    console.log('onDrawUpdate', features);
  };

  const controls = {
    polygon: true,
    trash: true,
  };

  const getStylesForVectors = (color) => {
    const linePaint = {
      'line-color': color,
      'line-width': 1,
    };

    const symbolLayout = {
      'text-field': '{place}',
      'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
      'text-offset': [0, 0.6],
      'text-anchor': 'top',
    };
    const symbolPaint = {
      'text-color': color,
    };

    /*
    const circleLayout = { visibility: 'visible' };
    const circlePaint = {
      'circle-color': color,
      'circle-stroke-color': color,
    };
    */
    // if you want points instead
    // return {circlePaint, circleLayout, linePaint, symbolLayout, symbolPaint,};
    return {linePaint, symbolLayout, symbolPaint,};
  };
  return (
    <>
      <Map
        style={mapType === MAP ? 'mapbox://styles/mapbox/basic-v9' : 'mapbox://styles/mapbox/satellite-v9'}
        containerStyle={{
          height: '100%',
          width: '100%',
        }}
        className="reports-map"
        zoom={zoomMap}
        renderChildrenInPortal
        center={!alreadyCentered && [longitudeMap, latitudeMap]}
        onZoomEnd={onZoomEnd}
        onStyleLoad={(map) => {
          map?.resize();
        }}
      >
        <Cluster
          ClusterMarkerFactory={clusterMarker}
          zoomOnClick
          zoomOnClickPadding={50}
        >
          {reportsToShow.map((feature) => (
            <Marker
              key={feature.uuid}
              style={styles.marker}
              coordinates={[parseFloat(feature.longitude), parseFloat(feature.latitude)]}
              data-feature={feature}
              onClick={() => selectReportPopup(feature)}
            />
          ))}
        </Cluster>
        {layers.map((elem) => {
          if (elem.type === 'vector' && elem.active) {
            return (
              <GeoJSONLayer
                key={`vector_${elem.id}`}
                data={elem.location}
                id={`vector_layer_${elem.id}`}
                {...getStylesForVectors('#'+elem.color)}
              />
            );
          }
          if (elem.type === 'raster' && elem.active) {
            return (
              <div key={`raster_${elem.id}`}>
                <Source
                  id={`${elem.id}_source`}
                  tileJsonSource={{
                    type: 'raster',
                    tiles: [
                      elem.location,
                    ],
                  }}
                />
                <Layer type="raster" id={`${elem.id}_layer`} sourceId={`${elem.id}_source`} />
              </div>
            );
          }
        })}
        {showPopUp && (
          <MapPopup
            coordinates={[
              parseFloat(selectedReport.longitude),
              parseFloat(selectedReport.latitude),
            ]}
            title={selectedReport.title}
            description={selectedReport.content}
            date={format(parse(selectedReport.event_date, REPORT_EVENT_DATE_PARSER, new Date()), 'dd LLL yyyy')}
            mainImage={selectedReport.media?.src}
            author={selectedReport.author}
            video={selectedReport.media?.count.video}
            image={selectedReport.media?.count.image}
            audio={selectedReport.media?.count.audio}
            document={selectedReport.media?.count.pdf}
            viewReport={() => { history.push({ pathname: `/report/${selectedReport.uuid}`, from: history.location.pathname }); }}
            closePopup={() => { setShowPopUp(false); }}
          />
        )}
        <div className="reports-map__searchFilter">
          <CustomCheckbox
            label={t('searchInTheMap')}
            checked={searchMap}
            onChange={() => setSearchMap(!searchMap)}
          />
        </div>
        {!searchMap
          && (
          <DrawControl
            style={searchMap ? [{ display: 'none !important' }] : []}
            controls={controls}
            onDrawCreate={onDrawCreate}
            onDrawUpdate={onDrawUpdate}
          />
          )}
        <ZoomControl className="reports-map__zoomControl" position="bottom-right" />
      </Map>
      <div className="reports-map__layers__box">
        {showLayers && (
          <div className="reports-map__layers__container">
            <CloseIcon className="reports-map__layers__container-close" onClick={() => setShowLayers(false)} />
            <div className="reports-map__layers__container-label">{t('basemaps')}</div>
            <div className="reports-map__layers__container-radios">
              <CustomRadio label={t('map')} value={MAP} name="group_type" onChange={(e, { value }) => setMapType(value)} checked={mapType === MAP} />
              <CustomRadio label={t('satelitte')} value={SATELLITE} name="group_type" onChange={(e, { value }) => setMapType(value)} checked={mapType === SATELLITE} />
            </div>
            {(layers.length > 0) && (
              <>
                <div className="reports-map__layers__container-label">{t('layers')}</div>
                <div className="reports-map__layers__list">
                  {(layers.map((elem, index) => (
                    <CustomCheckbox onChange={() => setLayerActive(elem, index)} key={`layercheck-${elem.id}`} label={elem.name} checked={elem.active} customCheckboxClass="reports-map__layers__list-element" />
                  )))}
                </div>
              </>
            )}
          </div>
        )}
        <div className="reports-map__layers__icon" onClick={() => setShowLayers(!showLayers)}>
          <LayersIcon />
        </div>
      </div>
    </>
  );
}

const mapStateToProps = ({ admin, common, reports }) => ({
  organizationOptions: admin.organizationOptions,
  layers: admin.layers,
  reports: reports.reports,
  defaultLanguage: common.defaultLanguage,
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportsMap);

import Cookies from 'js-cookie';
import {
  USER_LOGIN_SUCCESS,
  USER_LOGIN_TWOFACTOR,
  USER_TWO_FACTOR_SUCCESS,
  SAVE_USER_DATA,
  USER_LOGOUT,
  USER_SET_PASSWORD_ERROR,
  UPDATE_USER_PROFILE,
} from '../types';

import { decrypt, encrypt } from '../utils';

const user = localStorage.getItem('user');

const initialState = {
  userInfo: user ? JSON.parse(decrypt(user)) : {},
  tempMail: '',
  loggedIn: { error: '', success: Cookies.get('token') || null },
  twoFactor: localStorage.getItem('twoFactor') || false,
  token: user ? Cookies.get('token') : null,
  passwordErrors: [],
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGIN_SUCCESS: {
      const { userInfo, twoFactor } = action.payload;

      if (userInfo.password) {
        userInfo.password = encrypt(userInfo.password);
      }

      localStorage.setItem('user', encrypt(JSON.stringify(userInfo)));
      return {
        ...state,
        userInfo,
        twoFactor,
        ...action.payload,
      };
    }
    case USER_LOGIN_TWOFACTOR: {
      const { userInfo, twoFactor } = action.payload;
      if (userInfo.password) {
        userInfo.password = encrypt(userInfo.password);
      }
      return {
        ...state,
        userInfo,
        twoFactor,
        ...action.payload,
      };
    }

    case USER_TWO_FACTOR_SUCCESS:
      let newUserInfo = action.payload.userInfo;
      if (newUserInfo.password) {
        delete newUserInfo.password;
      }

      newUserInfo.needs2FA = false;
      localStorage.setItem('twoFactor', true);
      localStorage.setItem('user', encrypt(JSON.stringify(newUserInfo)));
      return {
        ...state,
        userInfo: newUserInfo,
        token: action.payload.token,
        twoFactor: action.payload.twoFactor,
      };

    case USER_LOGOUT:
      localStorage.removeItem('user');
      localStorage.removeItem('twoFactor');
      Cookies.remove('token');
      return {
        ...state,
        token: null,
      };

    case USER_SET_PASSWORD_ERROR:
      return {
        ...state,
        ...action.payload
      };
    case SAVE_USER_DATA:
      // Save on LocalStorage if needed
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_USER_PROFILE:
      let updatedUserInfo = action.payload.userInfo;
      localStorage.setItem('user', encrypt(JSON.stringify(updatedUserInfo)));
      return {
        ...state,
        userInfo: updatedUserInfo,
      };
    default:
      return state;
  }
};

export default userReducer;

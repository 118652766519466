export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_TWOFACTOR = 'USER_LOGIN_TWOFACTOR';
export const USER_LOGIN_ERROR = 'USER_LOGIN_ERROR';
export const USER_LOGOUT = 'USER_LOGOUT';
export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';
export const USER_TWO_FACTOR_SUCCESS = 'USER_TWO_FACTOR_SUCCESS';
export const USER_TWO_FACTOR_ERROR = 'USER_TWO_FACTOR_ERROR';
export const USER_SET_PASSWORD_ERROR = 'USER_SET_PASSWORD_ERROR';
export const SAVE_USER_DATA = 'SAVE_USER_DATA';

// Reports
export const GET_REPORTS = 'GET_REPORTS';
export const FETCH_REPORTS_ERROR = 'FETCH_REPORTS_ERROR';
export const SELECT_REPORT = 'SELECT_REPORT';
export const UNSELECT_REPORT = 'UNSELECT_REPORT';
export const DELETE_REPORT = 'DELETE_REPORT';
export const CREATE_REPORT = 'CREATE_REPORT';
export const UPDATE_ACTIVITY_REPORT = 'UPDATE_ACTIVITY_REPORT';
export const ADD_ACTIVITY_REPORT = 'ADD_ACTIVITY_REPORT';
export const RESET_REPORTS = 'RESET_REPORTS';

// Messages
export const GET_MESSAGES = 'GET_MESSAGES';
export const ADD_MESSAGE = 'ADD_MESSAGE';

// Fetching Actions
export const FETCHING_START = 'FETCHING_START';
export const FETCHING_END = 'FETCHING_END';
export const UPDATE_TIMBY_OPTIONS = 'UPDATE_TIMBY_OPTIONS';
export const UPDATE_DEFAULT_LANGUAGE = 'UPDATE_DEFAULT_LANGUAGE';

// Alerts Actions
export const ADD_NEW_MESSAGE = 'ADD_NEW_MESSAGE';
export const REMOVE_MESSAGES = 'REMOVE_MESSAGES';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';

// Admin Actions
export const GET_USERS = 'GET_USERS';
export const UPDATE_USER = 'UPDATE_USER';

// Organizations Actions
export const GET_ORGANIZATIONS = 'GET_ORGANIZATIONS';
export const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION';
export const CREATE_ORGANIZATION = 'GET_ORGANIZATION';
export const UPDATE_SELECTED_ORGANIZATION = 'UPDATE_SELECTED_ORGANIZATION';
export const DELETE_ORGANIZATION = 'DELETE_ORGANIZATION';

export const GET_ROLES = 'GET_ROLES';
// Layers/Maps Actions
export const GET_ORGANIZATION_OPTIONS = 'GET_ORGANIZATION_OPTIONS';
export const UPDATE_ORGANIZATION_OPTIONS = 'UPDATE_ORGANIZATION_OPTIONS';
export const GET_ORGANIZATION_LAYERS = 'GET_ORGANIZATION_LAYERS';

// Tags Actions
export const GET_TAGS = 'GET_TAGS';
export const ADD_GROUP_TAG = 'ADD_GROUP_TAG';
export const UPDATE_GROUP_TAG = 'UPDATE_GROUP_TAG';
export const DELETE_GROUP_TAG = 'DELETE_GROUP_TAG';
export const ADD_TAG = 'ADD_TAG';
export const UPDATE_TAG = 'UPDATE_TAG';
export const DELETE_TAG = 'DELETE_TAG';
export const APPLY_FILTERS = 'APPLY_FILTERS';
export const RESET_FILTERS = 'RESET_FILTERS';

// Stories Actions
export const GET_STORIES = 'GET_STORIES';
export const ADD_STORY = 'ADD_STORY';
export const SELECT_STORY = 'SELECT_STORY';
export const UPDATE_STORY = 'UPDATE_STORY';
export const DELETE_STORY = 'DELETE_STORY';

// Folders Actions
export const GET_FOLDERS = 'GET_FOLDERS';
export const ADD_FOLDER = 'ADD_FOLDER';
export const SELECT_FOLDER = 'SELECT_FOLDER';
export const ADD_REPORT_TO_FOLDER = 'ADD_REPORT_TO_FOLDER';
export const UNSELECT_FOLDER = 'UNSELECT_FOLDER';

import '../../../../styles/index.scss';
import React, { useEffect, useState } from 'react';
import types from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import mapDispatchToProps from '../../../../actions/index';
import SelectBox from '../../../ui/SelectBox/SelectBox';
import MessageCustomSelect from '../../MessageForm/MessageCustomSelect/MessageCustomSelect';
import { getRandomTint } from '../../../../utils';
import MediaUpload from '../../../shared/MediaUpload/MediaUpload';
import UploadedFile from '../../../ui/UploadedFile/UploadedFile';

const AdminLanguages = (props) => {
  const { t } = useTranslation();
  const [selectedAppLang, setSelectedAppLang] = useState('en');
  const [enabledLanguages, setEnabledLanguages] = useState([]);
  const [availableLanguages, setAvailableLanguages] = useState([]);
  const [languageCsv, setLanguageCsv] = useState(null);

  useEffect(() => {
    if (props.languages.length === 0) props.getOptions();
    props.getOrganizationOptions();
    document.title = 'Timby - Languages';
  }, []);

  useEffect(() => {
    if (props.languages) {
      setEnabledLanguages(props.languages.map((lang) => ({
        value: lang.code, text: lang.text, id: lang.code, color: getRandomTint(),
      })));
      const formattedLanguages = props.languages.map((lang) => ({
        value: lang.code, text: lang.text, key: lang.code, id: lang.code,
      }));
      setAvailableLanguages(formattedLanguages);
    }
  }, [props.languages]);

  const addCsv = (files) => {
    const file = files[0];
    if (file === undefined) return setLanguageCsv(null);

    return setLanguageCsv(file);
  };

  const downloadCurrentTerminology = () => {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(languageCsv);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
      <div className="admin-settings-section admin-languages">
        <div className="admin-settings-section__header">
          <h1 className="admin-settings-section__header-title">
            {t('languages')}
          </h1>
        </div>
        <div className="admin-settings__box">
          <div className="admin-settings__element">
            <SelectBox
                placeholder="Select Default Language"
                title={t('defaultLanguage')}
                value={selectedAppLang}
                options={availableLanguages}
                handleChange={(e, { value }) => setSelectedAppLang(value)}
            />
          </div>
          <div className="admin-settings__element">
            <div className="select-box enabled-languages-box">
              <div className="text-input__label">{t('enabledLanguages')}</div>
              <MessageCustomSelect
                  options={availableLanguages}
                  placeholder={t('selectLanguages')}
                  value={enabledLanguages}
                  selectedOptions={enabledLanguages}
                  changeSelectedOptions={setEnabledLanguages}
              />
            </div>
          </div>
        </div>

        <div className="admin-settings-section__header">
          <h2 className="admin-settings-section__header-title">
            {t('updateTerminology')}
          </h2>
          <div className="admin-settings-section__header-description">
            {
              'You can customize the strings used in TIMBY to fit your own organization’s terminology.'
              + 'For example, you can rename ‘Report’ to ‘Grievance’, or ‘Update’ to  ‘Response’.  '
              + 'Click the Languages file to download it, modify it, and upload it to effect the changes. '
              + 'Warning: Only attempt to modify the strings on your site if you know what you are doing. '
              + 'You can cause damage to your site otherwise. '
            }
          </div>
        </div>

        <div className="admin-terminology-box">
          <div className="admin-terminology-box__header">
            <div className="admin-terminology-box__header-title">{t('currentString')}</div>
            <div className="admin-terminology-box__header-buttons">
              <div className="reset-link">{t('resetToDefault')}</div>
            </div>
          </div>
          {languageCsv && (
              <UploadedFile
                  file={languageCsv}
                  removable={false}
                  downloadable
                  downloadHandler={downloadCurrentTerminology}
              />
          )}
          <MediaUpload
              addFile={addCsv}
              removeFile={() => {}}
              files={[]}
              acceptedFiles=".csv"
              title={t('dragCsv')}
              subtitle={t('supportCsv')}
          />
        </div>
      </div>
  );
};

const mapStateToProps = ({ common }) => ({
  languages: common.languages,
});

AdminLanguages.displayName = 'AdminLanguages';

AdminLanguages.propTypes = {
  languages: types.array.isRequired,
  getOptions: types.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminLanguages);

/* eslint-disable import/prefer-default-export */
const fullUri = (endpoint) => `${process.env.REACT_APP_BACKEND_URL || ''}${endpoint}`;

export const endpoints = {
  keepAlive: fullUri('/ping'),
  login: fullUri('/user/login'),
  twoFactor: fullUri('/user/login/verify'),
  resetPassword: fullUri('/user/password/reset'),
  setPasswordRecovery: (username) => fullUri(`/user/${username}/password`),
  timbyOptions: fullUri('/'),
  user: (username) => fullUri(`/user/${username}`),
  createUser: (orgId) => fullUri(`/organization/${orgId}/user`),
  organization: (orgId) => fullUri(`/organization/${orgId}`),
  organizationOptions: (orgId) => fullUri(`/organization/${orgId}/options`),
  createOrganization: fullUri('/organization'),
  getUsers: (orgId) => fullUri(`/organization/${orgId}/users`),
  getOrganizations: fullUri('/organizations'),
  getRoles: fullUri('/roles'),
  getTaxonomies: (orgId) => fullUri(`/organization/${orgId}/taxonomies`),
  createTaxonomyGroup: (orgId) => fullUri(`/organization/${orgId}/taxonomy/group`),
  alterTaxonomyGroupElement: (orgId, groupId) => fullUri(`/organization/${orgId}/taxonomy/group/${groupId}`),
  alterTaxonomyChildElement: (orgId, taxonomyId) => fullUri(`/organization/${orgId}/taxonomy/${taxonomyId}`),
  getLayers: (orgId) => fullUri(`/organization/${orgId}/layers`),
  getReports: () => fullUri(`/reports`),
  getReport: (repId) => fullUri(`/report/${repId}`),
  getReportActivity: (orgId, reportId) => fullUri(`/organization/${orgId}/report/${reportId}/activity`),
  updateReportActivity: (orgId, reportId) => fullUri(`/organization/${orgId}/report/${reportId}/update`),
  addReportToFolder: (orgId, folderId) => fullUri(`/organization/${orgId}/folder/${folderId}/add`),
  removeReportFromFolder: (orgId, folderId) => fullUri(`/organization/${orgId}/folder/${folderId}/del`),
  createReport: (orgId) => fullUri(`/organization/${orgId}/report`),
  alterReport: (orgId, reportId) => fullUri(`/organization/${orgId}/report/${reportId}`),
  getFolders: (orgId) => fullUri(`/organization/${orgId}/folders`),
  createFolder: (orgId) => fullUri(`/organization/${orgId}/folder`),
  updateFolder: (orgId, folderId) => fullUri(`/organization/${orgId}/folder/${folderId}`),
  createLayer: (orgId) => fullUri(`/organization/${orgId}/layer`),
  alterLayer: (orgId, layerId) => fullUri(`/organization/${orgId}/layer/${layerId}`),
  getBroadcastedMessages: (orgId) => fullUri(`/organization/${orgId}/broadcasts`),
  sendBroadcastMessage: (orgId) => fullUri(`/organization/${orgId}/broadcast`),
};

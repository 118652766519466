import i18n from 'i18next';

export const getLanguage = () => (i18n.language);

export const changeLanguage = (lang) => {
  i18n.changeLanguage(lang);
};

export const availableLanguages = () => (i18n.languages);

export const addBackendTranslation = (key, value, language = i18n.language) => {
  i18n.addResource(language, 'backend', key, value);
};

export const addBackendTranslations = (data, language = i18n.language) => {
  i18n.addResources(language, 'backend', data);
};

export const getBackendTranslation = (value, defaultValue, language = i18n.language) => (
  i18n.getResource(language, 'backend', value) || defaultValue
);

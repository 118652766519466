import '../../../../styles/index.scss';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import t from 'prop-types';
import { useTranslation } from 'react-i18next';
import ReactMapboxGl, { ZoomControl, Marker } from 'react-mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import TextInput from '../../../shared/TextInput/TextInput';
import SelectBox from '../../../ui/SelectBox/SelectBox';
import Button from '../../../shared/Button/Button';
import mapDispatchToProps from '../../../../actions/index';
import Timezones from '../../../../shared/timezones';
import { MAPBOX_SECRET_KEY } from '../../../../shared/consts';
import {formatLatLng} from '../../../../utils/index';

const Map = ReactMapboxGl({
  attributionControl: false,
  accessToken:
  MAPBOX_SECRET_KEY,
});

const AdminMap = (props) => {
  const { t } = useTranslation();
  const [errors, setErrors] = useState({});
  const [latitude, setLatitude] = useState(formatLatLng(props?.organizationOptions?.latitude));
  const [longitude, setLongitude] = useState(formatLatLng(props?.organizationOptions?.longitude));
  const [zoomLevel, setZoomLevel] = useState(props?.organizationOptions?.zoom || '8');
  const zoomOptions = Array.from(Array(23).keys()).map((elem) => (
      { key: `${elem}`, value: `${elem}`, text: `${elem}` }
  ));

  useEffect(() => {
    document.title = 'Timby - Maps/Layers';
  }, []);

  useEffect(() => {
    // getMapInfo()
    props.getOrganizationOptions();
  }, [props.selectedOrganizationId]);

  useEffect(() => {
    setLatitude(formatLatLng(props?.organizationOptions?.latitude));
    setLongitude(formatLatLng(props?.organizationOptions?.longitude));
    setZoomLevel(props?.organizationOptions?.zoom || '8');
  }, [props?.organizationOptions]);

  const onClickMap = (map, e) => {
    setLatitude(formatLatLng(e.lngLat.lat));
    setLongitude(formatLatLng(e.lngLat.lng));
  };

  const handleUpdateOrganizationOptions = () => {
    const organizationData = {
      latitude,
      longitude,
      zoom: zoomLevel,
    };
    props.updateOrganizationOptions(organizationData);
  };

  const setNewLatitude = (value) => {
    const parsedValue = parseFloat(value);
    const newErrors = errors;
    if (parsedValue < -90 || parsedValue > 90) {
      newErrors.latitude = t('validations.latitudeInvalidNumber');
      setErrors(newErrors);
      setLatitude('');
    }else{
      setLatitude(value);
      newErrors.latitude = null;
      setErrors(newErrors);
    }
  };

  const setNewLongitude = (value) => {
    const parsedValue = parseFloat(value);
    const newErrors = errors;
    if (parsedValue < -180 || parsedValue > 180) {
      newErrors.longitude = t('validations.longitudeInvalidNumber');
      setErrors(newErrors);
      setLongitude('');
    }else{
      setLongitude(value);
      newErrors.longitude = null;
      setErrors(newErrors);
    }
  };

  return (
      <div className="admin-settings-section">
        <div className="admin-settings-section__header">
          <div className="admin-settings-section__header-title">
            {t('mapParameters')}
          </div>
        </div>

        <div className="admin-settings__box">
          <div className="admin-map__map-wrapper">
            <Map
                style="mapbox://styles/mapbox/basic-v9"
                onStyleLoad={(map) => {
                  map.resize();
                }}
                center={[longitude, latitude]}
                zoom={[zoomLevel]}
                onClick={(map, e) => onClickMap(map, e)}
                className="admin-map__map"
            >
              <>
                {(longitude && latitude) && <Marker className="map__marker" coordinates={[longitude, latitude]} />}
                <ZoomControl className="reports-map__zoomControl" position="bottom-right" />
              </>
            </Map>
          </div>
          <div className="admin-map__parameters">
            <TextInput
              title={t('latitude')}
              value={latitude}
              type="number"
              placeholder={t('reportLatitudePlaceholder')}
              handleChange={(event) => setNewLatitude(event.target.value)}
              hasError={!!errors.latitude}
              errorDescription={errors.latitude}
            />
            <TextInput
              title={t('longitude')}
              value={longitude}
              placeholder={t('reportLongitudePlaceholder')}
              type="number"
              handleChange={(event) => { setNewLongitude(event.target.value); }}
              hasError={!!errors.longitude}
              errorDescription={errors.longitude}
            />
            <SelectBox
                placeholder={t('enterZoom')}
                title={t('zoomLevel')}
                value={zoomLevel}
                options={zoomOptions}
                handleChange={(e, { value }) => setZoomLevel(value)}
            />
            <div className='admin-map__parameters-button'>
              <Button text={t('save')} primary medium handler={() => handleUpdateOrganizationOptions()} />
            </div>
          </div>
        </div>

        <div className="admin-settings-section__header">
          <div className="admin-settings-section__header-title">
            {t('localization')}
          </div>
        </div>
        <div className="admin-settings__box">
          <div className="admin-settings__element admin-settings__element__timezone">
            <SelectBox
                title={t('timeZone')}
                hasTitle
                placeholder="Europe/Lisbon (UTC+1)"
                value={null}
                options={Timezones}
                handleChange={(e, { value }) => console.log(value)}
            />
          </div>
        </div>
      </div>
  );
};

const mapStateToProps = ({ admin }) => ({
  selectedOrganizationId: admin.selectedOrganizationId,
  organizationOptions: admin.organizationOptions,
});

AdminMap.propTypes = {
  organizationOptions: t.object.isRequired,
  getOrganizationOptions: t.func.isRequired,
  updateOrganizationOptions: t.func.isRequired,
  getOrganizationLayers: t.func.isRequired,
  selectedOrganizationId: t.string.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminMap);

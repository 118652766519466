import {
  ADD_FOLDER, ADD_REPORT_TO_FOLDER, GET_FOLDERS, SELECT_FOLDER, UNSELECT_FOLDER,
} from '../types';
import { addNewAlert, closeLoader, openLoader } from './CommonActions';
import i18n from '../translations/i18n';
import { ALERT_ERROR, ALERT_SUCCESS } from '../shared/consts';
import { getRandomColor } from '../utils';
import { endpoints } from './endpoints';

const folders = [
  {
    name: 'Biera farminge',
    reports: [],
    uuid: 'aaaa-bbbb-cccc-dddd',
    color: getRandomColor(),
  },
  {
    name: 'Skeleton coast park',
    reports: [],
    uuid: 'cccc-bbbb-cccc-dddd',
    color: getRandomColor(),
  },
  {
    name: 'Biera farminge',
    reports: [],
    uuid: 'dddd-bbbb-cccc-dddd',
    color: getRandomColor(),
  },

];

export const getFolders = () => async (dispatch, getState) => {
  const { user, admin } = getState();
  const { token } = user;

  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    dispatch(openLoader());
    const response = await fetch(`${endpoints.getFolders(admin.selectedOrganizationId)}`, options);
    const { error, status } = await response.json();

    if (status !== 200 && error) {
      if (status !== 200 && error && error.description) {
        dispatch(addNewAlert(error.description, ALERT_ERROR));

        // @todo: remove when it works.
        dispatch({ type: GET_FOLDERS, payload: { folders } });
      }
    } else {
      dispatch({ type: GET_FOLDERS, payload: { folders } });
    }
  } catch (e) {
    console.warn(e);
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
  } finally {
    dispatch(closeLoader());
  }

  return { success: true };
};

export const addNewFolder = (folderPayload) => async (dispatch, getState) => {
  const { user, admin } = getState();
  const { token } = user;

  const formData = new FormData();

  formData.append('name', folderPayload.name);
  formData.append('color', folderPayload.color);

  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: formData,
  };

  try {
    dispatch(openLoader());
    const response = await fetch(endpoints.createFolder(admin.selectedOrganizationId), options);
    const { error, status } = await response.json();

    if (status !== 200 && error) {
      if (status !== 200 && error && error.description) {
        dispatch(addNewAlert(error.description, ALERT_ERROR));

        // @todo: remove when it works.
        const folderInfo = {
          name: folderPayload.name,
          uuid: Math.floor(Math.random() * 1000),
          color: folderPayload.color,
          reports: [],
        };

        dispatch({ type: ADD_FOLDER, payload: { folder: folderInfo } });
      }
    } else {
      dispatch(addNewAlert(i18n.t('notifications.folderCreated'), ALERT_SUCCESS));
      dispatch({ type: ADD_FOLDER, payload: { folder: folderPayload } });
      dispatch(getFolders());
    }
  } catch (e) {
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
    console.warn(e);
    return { success: false, folders: [] };
  } finally {
    dispatch(closeLoader());
  }

  return { success: true };
};

export const selectFolder = (folderId) => async (dispatch, getState) => {
  const { folders } = getState();
  const foldersList = folders.folders;
  const selectedFolder = foldersList.find((elem) => elem.uuid === folderId);

  dispatch({ type: SELECT_FOLDER, payload: selectedFolder });
};

export const unselectFolder = () => async (dispatch) => {
  dispatch({ type: UNSELECT_FOLDER });
};

export const addReportToFolder = (foldersId, selectedReport) => async (dispatch) => {
  // const { user } = getState();
  // const { token } = user;
  const formData = new FormData();

  /* maybe instead of add report to folder  add folders to report */
  foldersId.map((elem) => (
    formData.append('folders[]', elem)
  ));

  /*
  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: formData,
  };
  */

  try {
    dispatch(openLoader());
    // const response = fetch(endpoints.addReportToFolder(folderId), options)
    /* const { error, status } = await response.json() */
    if (false) {
      /*
      if (status !== 200 && error && error.description) {
        dispatch(addNewAlert(error.description, ALERT_ERROR))
      }
      */
    } else {
      dispatch(addNewAlert(i18n.t('notifications.reportAddedFolder'), ALERT_SUCCESS));
      // dispatch(getFolders())

      dispatch({ type: ADD_REPORT_TO_FOLDER, payload: { foldersId, selectedReport } });
    }
  } catch (e) {
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
    console.warn(e);
  } finally {
    dispatch(closeLoader());
  }

  return { success: true };
};

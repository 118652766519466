import {
  CREATE_ORGANIZATION,
  GET_ORGANIZATIONS,
  GET_USERS,
  UPDATE_ORGANIZATION,
  DELETE_ORGANIZATION,
  UPDATE_SELECTED_ORGANIZATION,
  UPDATE_USER,
  GET_ROLES,
  GET_ORGANIZATION_OPTIONS,
  UPDATE_ORGANIZATION_OPTIONS,
  GET_TAGS,
  ADD_GROUP_TAG,
  UPDATE_GROUP_TAG,
  DELETE_GROUP_TAG,
  DELETE_TAG,
  ADD_TAG,
  UPDATE_TAG,
  USER_LOGIN_SUCCESS,
  USER_TWO_FACTOR_SUCCESS,
  GET_ORGANIZATION_LAYERS,
} from '../types';
import { decrypt } from '../utils';

const getUserOrganization = () => {
  const userInfo = JSON.parse(decrypt(localStorage.getItem('user')));
  return (userInfo?.organizations ? userInfo?.organizations[0].id : '1');
};

const initialState = {
  groupTags: [],
  users: [],
  organizations: [],
  roles: [],
  layers: [],
  selectedOrganizationId: localStorage.getItem('user') ? getUserOrganization() : '1',
  organizationOptions: {},
};

const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TAGS:
      return {
        ...state,
        groupTags: action.payload.tags,
      };
    case ADD_GROUP_TAG: {
      const newGroupTag = action.payload;
      newGroupTag.children = [];

      return {
        ...state,
        groupTags: [...state.groupTags, newGroupTag],
      };
    }

    case UPDATE_GROUP_TAG: {
      const updatedGroupTag = action.payload;
      const groupTags = state.groupTags.map((elem) => {
        if (elem.id === updatedGroupTag.id) {
          return updatedGroupTag;
        }
        return elem;
      });

      return {
        ...state,
        groupTags: [...groupTags],
      };
    }

    case DELETE_GROUP_TAG: {
      const groupTagID = action.payload;
      const filteredGroupTags = state.groupTags.filter((elem) => {
        if (elem.id === groupTagID) {
          return false;
        }
        return true;
      });

      return {
        ...state,
        groupTags: [...filteredGroupTags],
      };
    }

    case DELETE_ORGANIZATION:
      return { ...state };

    case ADD_TAG: {
      const { tagData: newTag, groupTagId } = action.payload;

      const newTags = state.groupTags.map((elem) => {
        if (elem.id === groupTagId) {
          return { ...elem, children: [...(elem?.children || []), newTag] };
        }
        return elem;
      });
      return {
        ...state,
        groupTags: [...newTags],
      };
    }

    case DELETE_TAG: {
      const { groupTagId, tagId } = action.payload;
      const selectedGroupTag = state.groupTags.find((elem) => (elem.id === groupTagId));
      selectedGroupTag.children = selectedGroupTag.children.filter((elem) => {
        if (elem.id === tagId) {
          return false;
        }
        return true;
      });
      const updatedGroups = state.groupTags.map((elem) => {
        if (elem.id === groupTagId) {
          return selectedGroupTag;
        }
        return elem;
      });
      return {
        ...state,
        groupTags: [...updatedGroups],
      };
    }

    case UPDATE_TAG: {
      const { groupTagId, tagData } = action.payload;
      const selectedGroupTag = state.groupTags.find((elem) => (elem.id === groupTagId));
      selectedGroupTag.children = selectedGroupTag.children.map((elem) => {
        if (elem.id === tagData.id) {
          return { ...elem, ...tagData };
        }
        return elem;
      });
      const updatedGroups = state.groupTags.map((elem) => {
        if (elem.id === groupTagId) {
          return selectedGroupTag;
        }
        return elem;
      });
      return {
        ...state,
        groupTags: [...updatedGroups],
      };
    }
    case GET_USERS:
      return {
        ...state,
        users: action.payload.users,
      };
    case UPDATE_USER:
      return {
        ...state,
      };

    case GET_ORGANIZATIONS:
      return {
        ...state,
        organizations: [...action.payload.organizations],
      };

    case GET_ORGANIZATION_OPTIONS:
      return {
        ...state,
        organizationOptions: action.payload.organizationOptions,
      };
    case GET_ORGANIZATION_LAYERS:
      return {
        ...state,
        layers: action.payload.layers,
      };
    case GET_ROLES:
      return {
        ...state,
        roles: action.payload.roles,
      };
    case UPDATE_SELECTED_ORGANIZATION:
      return {
        ...state,
        selectedOrganizationId: action.payload.selectedOrganizationId,
        users: [],
      };
    case CREATE_ORGANIZATION:
      return {
        ...state,
        organizations: [...state.organizations, action.payload.organization],
      };
    case UPDATE_ORGANIZATION_OPTIONS:
      return {
        ...state,
        organizationOptions: action.payload.organizationOptions,
      };

    case UPDATE_ORGANIZATION: {
      const updatedOrganization = action.payload.organization;
      const { organizations } = state;
      const filteredOrganization = organizations.map((elem) => {
        if (elem.id === updatedOrganization.id) {
          return updatedOrganization;
        }
        return elem;
      });
      return {
        ...state,
        organizations: [...filteredOrganization],
      };
    }
    case USER_TWO_FACTOR_SUCCESS:
    case USER_LOGIN_SUCCESS:
      if (action.payload.defaultOrganization === null) {
        return state;
      }
      return {
        ...state,
        selectedOrganizationId: action.payload.defaultOrganization?.id,
      };

    default:
      return state;
  }
};

export default commonReducer;

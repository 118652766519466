import '../../../styles/index.scss';

import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import dashboardHoc from '../DashboardHoc/DashboardHoc';
import FullHeader from '../../ui/FullHeader/FullHeader';
import TextInput from "../../shared/TextInput/TextInput";
import SelectBox from "../../ui/SelectBox/SelectBox";
import Button from "../../shared/Button/Button";
import t from "prop-types";
import {connect} from "react-redux";
import mapDispatchToProps from "../../../actions";

function UserContainer(props) {
  const { t } = useTranslation();
  const { userInfo, languages } = props;

  const [firstName, setFirstName] = useState(userInfo?.firstName || '');
  const [lastName, setLastName] = useState(userInfo?.lastName || '');
  const [email, setEmail] = useState(userInfo?.email || '');
  const [defaultLanguage, setDefaultLanguage] = useState(userInfo?.language || '');
  const [mobileOrganization, setMobileOrganization] = useState(null);
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});

  useEffect(() => {
    document.title = 'Timby - Profile';
  }, []);

  const profilePlaceholder = (firstName, lastName, username) => (
    firstName && lastName
      ? `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase()
      : username.substring(0, 2).toUpperCase()
  );

  const mappedLanguages = languages.map((elem) => ({ text: elem.text, value: elem.locale }));

  const editUser = async () => {
    const response = await props.editUser(firstName, lastName, email, password, defaultLanguage);
    if (response.success) {
      setErrors({});
    } else {
      setErrors(response.errors);
    }
  };

  return (
    <>
      <div className="user-container">
        <FullHeader title={t('profile')} />
        <div className="user-container__wrapper whitepagescrollbar">
          <div className="admin_userprofile__box-area whitepagescrollbar">
            <div className="max-body-width">
              <div className="admin_userprofile__container">
                <div className="admin_userprofile__userpic_box">
                  <p className="admin_userprofile__title">{t('mysettings')}</p>
                  <div className="admin_userprofile__userpic">
                    {profilePlaceholder(userInfo?.firstName, userInfo?.lastName, userInfo?.username)}
                  </div>
                </div>
                <div className="admin_userprofile__form_box">
                  <TextInput
                    value={firstName}
                    handleChange={(event) => setFirstName(event.target.value)}
                    placeholder=""
                    title={t('firstname')}
                    hasError={!!errors.firstname}
                    errorDescription={errors.firstname}
                  />
                  <TextInput
                    value={lastName}
                    handleChange={(event) => setLastName(event.target.value)}
                    placeholder=""
                    title={t('lastname')}
                    hasError={!!errors.lastname}
                    errorDescription={errors.lastname}
                  />
                  <TextInput
                    value={email}
                    handleChange={(event) => setEmail(event.target.value)}
                    placeholder={t('enterEmailPlaceholder')}
                    title={t('email')}
                    type="email"
                    hasError={!!errors.email}
                    errorDescription={errors.email}
                  />
                  <TextInput
                    value={userInfo.username}
                    placeholder=""
                    title={t('username')}
                    disabled
                  />
                  <TextInput
                    value={password}
                    handleChange={(event) => setPassword(event.target.value)}
                    placeholder="••••••••••••••••"
                    title={t('password')}
                    type="password"
                    hasError={!!errors.password}
                    errorDescription={errors.password}
                  />
                  <TextInput
                    value={userInfo?.role || ''}
                    placeholder=""
                    title={t('role')}
                    disabled
                  />
                  <SelectBox
                    title={t('defaultLanguage')}
                    placeholder="Select a language..."
                    handleChange={(e, { value }) => setDefaultLanguage(value)}
                    options={mappedLanguages}
                    value={defaultLanguage}
                    hasError={!!errors.language}
                    errorDescription={errors.language}
                  />
                  {(userInfo.organizations.length > 1) && (
                    <SelectBox
                      title={t('mobileOrganizationLabel')}
                      placeholder={t('mobileOrganizationPlaceholder')}
                      handleChange={(e, { value }) => setMobileOrganization(value)}
                      options={userInfo.organizations.map((elem) => (
                        { value: elem.id, text: elem.name }
                      ))}
                      value={mobileOrganization}
                    />
                  )}
                  <div className="admin_userprofile__form_submit">
                    <Button text={t('saveChanges')} primary large handler={() => editUser()} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = ({ user, common }) => ({
  userInfo: user.userInfo,
  languages: common.languages,
});

UserContainer.propTypes = {
  editUser: t.func.isRequired,
  userInfo: t.object.isRequired,
  languages: t.array.isRequired,
};

export default dashboardHoc(connect(mapStateToProps, mapDispatchToProps)(UserContainer));

export const ALERT_SUCCESS = 'ALERT_SUCCESS';
export const ALERT_ERROR = 'ALERT_ERROR';
export const ALERT_INFORMATION = 'ALERT_INFORMATION';
export const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
export const MAPBOX_SECRET_KEY = process.env.REACT_APP_MAPBOX_SECRET_KEY || 'pk.eyJ1Ijoiam9uaHRlc3QiLCJhIjoiY2tucHZvMXQ1MDZ0MTJ3cGZwc3ZoZnpvZSJ9.MP16abfMdNlOpQ_j6OaxhA';
export const MESSAGE_EVERYONE = 'EVERYONE';
export const MESSAGE_SELECT_USERS = 'SELECT_USERS';
export const MESSAGE_SELECT_ROLES = 'SELECT_ROLES';
export const REPORT_EVENT_DATE_PARSER = 'yyyy-LL-dd HH:mm:ss';
export const UTC_PARSER = 'yyyy-LL-ddTHH:mm:ss.SSSZ';
export const DOMAINS_REGEX = /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g;

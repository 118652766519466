import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import t from 'prop-types';
import { getOptions } from './actions/CommonActions';
import MainRouter from './routes/Main';
import AlertsContainer from './components/ui/AlersContainer/AlertsContainer';
import Loading from './components/ui/Loading/Loading';

import './styles/index.scss';
import './translations/i18n';

function App(props) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOptions());
  }, [dispatch]);

  return (
    <div className="App">
      <MainRouter />
      <AlertsContainer />
      { props.loading && (<Loading />)}
    </div>
  );
}

App.propTypes = {
  loading: t.bool,
};

App.defaultProps = {
  loading: false,
};

const mapStateToProps = ({ loading }) => ({ loading: loading.isFetching });

export default withRouter(connect(mapStateToProps, null)(App));

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Toggle from '../../../ui/Toggle/Toggle';

const AdminDashboardSettings = () => {
    const { t } = useTranslation();

    const [dashboardToggles, setDashboardToggles] = useState([
        {
            labelKey: 'analyticsPage', label: t('analyticsPage'), type: 'Toggle', value: false, id: 'analytics-page',
        },
        {
            labelKey: 'notificationsPage', label: t('notificationsPage'), type: 'Toggle', value: false, id: 'notifications-page',
        },
        {
            labelKey: 'storiesPage', label: t('storiesPage'), type: 'Toggle', value: false, id: 'story-page',
        },
    ]);

    const [dashboardUpdate, setDashboardUpdate] = useState(false);

    useEffect(() => {
        document.title = 'Timby - Additional Settings';
    }, []);

    useEffect(() => {
        const newDashboardToggles = dashboardToggles.map((elem) => ({
            ...elem, label: t(elem.labelKey),
        }));

        setDashboardToggles(newDashboardToggles);
    }, []);

    const updateToggleOptions = (list, setFunction, id) => {
        const updatedToggles = list.map((elem) => {
            if (elem.id === id) {
                return { ...elem, value: !elem.value };
            }
            return elem;
        });
        setFunction(updatedToggles);
    };

    return (
        <div className="admin-settings-section">
            <div className="admin-settings-section__header">
                <h1 className="admin-settings-section__header-title">
                    {t('dashboardConfiguration')}
                </h1>
            </div>
            <div className="admin-settings__box">
                <div className="admin-settings__element admin-settings__element-open">
                    <Toggle
                        value={dashboardUpdate}
                        handleChange={() => setDashboardUpdate(!dashboardUpdate)}
                        label={t('reportUpdates')}
                    />
                    {dashboardUpdate && (
                        <div className="admin-settings__element__container">
                            <Toggle
                                label={t('reportAssignment')}
                            />
                            <Toggle
                                label={t('urgency')}
                            />
                            <Toggle label={t('reportLifecycle')} />
                        </div>
                    )}
                </div>
                {dashboardToggles.map((elem) => (
                    <div className="admin-settings__element" key={elem.id}>
                        <Toggle
                            label={elem.label}
                            value={elem.value}
                            handleChange={() => {
                                updateToggleOptions(dashboardToggles, setDashboardToggles, elem.id);
                            }}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

AdminDashboardSettings.displayName = 'AdminDashboardSettings';

export default AdminDashboardSettings;

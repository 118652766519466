import React from 'react';
import t from 'prop-types';
import clsx from 'clsx';
import { Dropdown } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import '../../../styles/index.scss';
import i18n from '../../../translations/i18n';
import { history } from '../../../index';
import mapDispatchToProps from '../../../actions/index';

import {
  ReportsNav, StoriesNav, MessagesNav, AnalyticsNav, SettingsNav, LogoutNav,
} from './icons';
import { isUserAdmin, isUserOrganizationAdmin } from '../../../utils';

function NavigationBar(props) {
  const { t } = useTranslation();

  const dashboardOptions = [
    {
      icon: ReportsNav, path: '/dashboard', highlightedPaths: ['/dashboard', '/new-report', '/report'], title: t('reportsTitle'),
    },
    {
      icon: StoriesNav, path: '/stories', highlightedPaths: ['/stories'], title: t('stories'),
    },
  ];
  if (isUserAdmin(props.userInfo) || isUserOrganizationAdmin(props.userInfo)) {
    dashboardOptions.push({
      icon: MessagesNav, path: '/messages', highlightedPaths: ['/messages'], title: t('messages'),
    });
  }

  dashboardOptions.push({
    icon: AnalyticsNav, path: '/analytics', highlightedPaths: ['/analytics'], title: t('analytics'),
  });
  if (isUserAdmin(props.userInfo) || isUserOrganizationAdmin(props.userInfo)) {
    dashboardOptions.push({
      icon: SettingsNav, path: '/admin', highlightedPaths: ['/admin'], title: t('admin'),
    });
  }

  const handleChange = (e, { value }) => {
    i18n.changeLanguage(value);
    props.updateDefaultLanguage(value);
  };

  const profilePlaceholder = (firstName, lastName, username) => (
    firstName && lastName
      ? `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase()
      : username.substring(0, 2).toUpperCase()
  );

  const languageOptions = (languages) => {
    return languages.map((elem) => (
      { key: elem.locale, text: elem.text, value: elem.code }
    ));
  };

  return (
    <div className="navigation-bar__wrapper">
      <div className="navigation-bar__options">
        <div className="navigation-bar__logo" />
        {dashboardOptions?.length > 0
          && dashboardOptions.map((elem) => {
            const SvgComponent = elem.icon;
            const foundPath = elem.highlightedPaths.find((highlitedPath) => (
              history.location.pathname.startsWith(highlitedPath)
            ));
            return (
              <div
                tabIndex={-999}
                role="button"
                data-title={elem.title}
                className={clsx(
                  'navigation-bar__option',
                  !!foundPath && 'navigation-bar__option--selected',
                )}
                key={`navigationpath-${elem.path}`}
                onKeyDown={(history.location.pathname === elem.path) ? undefined : () => {
                  history.push({ pathname: elem.path, from: history.location.pathname });
                }}
                onClick={(history.location.pathname === elem.path) ? undefined : () => {
                  history.push({ pathname: elem.path, from: history.location.pathname });
                }}
              >
                <SvgComponent className={clsx(
                  'navigation-bar__option-icon',
                  !!foundPath && 'navigation-bar__option-icon--selected',
                )}
                />
              </div>
            );
          })}
      </div>
      <div className="navigation-bar__bottom-section">
        <Dropdown
          options={languageOptions(props.languages || [])}
          icon={null}
          onChange={handleChange}
          className="navigation-bar__language-description"
          trigger={(
            <div className="navigation-bar__language">
              {props.defaultLanguage}
            </div>
          )}
        />
        <div
          tabIndex={-999}
          role="button"
          data-title="Logout"
          className="navigation-bar__option navigation-bar__option-logout"
          onKeyDown={() => props.doLogout()}
          onClick={() => props.doLogout()}
        >
          <LogoutNav className="navigation-bar__option-icon navigation-bar__option-logout-icon" />
        </div>
        <div
          tabIndex={-999}
          role="button"
          className="navigation-bar__profile"
          onClick={() => history.push('/profile')}
          onKeyDown={() => history.push('/profile')}
        >
          {profilePlaceholder(
            props?.userInfo?.firstName, props?.userInfo?.lastName, props?.userInfo?.username
          )}
        </div>
      </div>
    </div>
  );
}

NavigationBar.propTypes = {
  userInfo: t.object.isRequired,
  defaultLanguage: t.string,
  languages: t.array,
  updateDefaultLanguage: t.func.isRequired,
  doLogout: t.func.isRequired,
};

NavigationBar.defaultProps = {
  defaultLanguage: 'en',
  languages: [],
};

export default connect(null, mapDispatchToProps)(NavigationBar);

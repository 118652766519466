import {
  SAVE_USER_DATA,
  UPDATE_DEFAULT_LANGUAGE,
  UPDATE_USER_PROFILE,
  USER_LOGIN_ERROR,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_TWOFACTOR,
  USER_LOGOUT,
  USER_SET_PASSWORD_ERROR,
  USER_TWO_FACTOR_ERROR,
  USER_TWO_FACTOR_SUCCESS,
} from '../types';
import { history } from '../index';
import i18n from '../translations/i18n';
import { endpoints } from './endpoints';
import {
  addNewAlert, closeLoader, logoutUserOn401, openLoader, setUpCookie,
} from './CommonActions';
import { ALERT_ERROR, ALERT_SUCCESS } from '../shared/consts';
import { decrypt } from '../utils';
import { changeLanguage } from '../utils/translationsUtils';
import { convertPasswordErrorsIntoText } from './AdminActions';

const MIN_PASSWORD_LENGTH = 7;
const MAX_PASSWORD_LENGTH = 72;

const loginData = (userData, userLanguage) => ({
  email: userData?.email,
  firstName: userData?.firstname,
  lastName: userData?.lastname,
  language: userLanguage,
  needs2FA: false,
  role: userData?.role?.name || '',
  organizations: userData?.organizations || [],
  capabilities: userData.capabilities.map((elem) => (elem.name)),
});

export const doLogin = (username, password) => async (dispatch, getState) => {
  // Validate Fields
  const usernameRegex = /^[a-zA-z0-9_.-]+$/i;

  if (!usernameRegex.exec(username)) {
    console.warn('Show info modal to user about username being incorrect');
    dispatch(addNewAlert(i18n.t('notifications.usernameAlphabeticRequired'), ALERT_ERROR));
    dispatch({ type: USER_LOGIN_ERROR, payload: '' });
    return false;
  }

  const formData = new FormData();
  formData.append('username', username);
  formData.append('password', password);
  const options = {
    method: 'POST',
    body: formData,
  };
  try {
    dispatch(openLoader());
    const response = await fetch(endpoints.login, options);
    const { data, error, status } = await response.json();
    if (status !== 200 && error && error.description) {
      dispatch(addNewAlert(error.description, ALERT_ERROR));
    } else if (status === 401 && data.startsWith('2fa')) {
      dispatch({
        type: USER_LOGIN_TWOFACTOR,
        payload: {
          userInfo: {
            username,
            needs2FA: true,
            password,
          },
        },
        twoFactor: false,
      });
      history.push({ pathname: '/two-factor-code', from: history.location.pathname });
    } else {
      const { languages } = getState().common;
      const userData = data?.options;
      const userLanguage = userData?.language;
      const selectedLanguage = languages.find((lang) => (lang.locale === userLanguage));
      if (selectedLanguage?.code) changeLanguage(selectedLanguage.code);

      dispatch(setUpCookie(data?.token?.access_token, data?.token?.expires_in));
      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: {
          userInfo: {
            username,
            ...loginData(userData, userLanguage),
          },
          token: data?.token?.access_token,
          ...(selectedLanguage?.code) && { language: selectedLanguage.code },
          defaultOrganization: userData?.organizations.length > 0
            ? userData?.organizations[0]
            : null,
        },
      });
      if (selectedLanguage) {
        dispatch({ type: UPDATE_DEFAULT_LANGUAGE, payload: { language: selectedLanguage.code } });
      }
      dispatch(addNewAlert(i18n.t('notifications.login'), ALERT_SUCCESS));
      history.push({ pathname: '/dashboard', from: history.location.pathname });
    }
  } catch (e) {
    console.warn(e);
    dispatch({ type: USER_LOGIN_ERROR, payload: '' });
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
  } finally {
    dispatch(closeLoader());
  }
};

export const doLogout = () => (dispatch) => {
  dispatch({ type: USER_LOGOUT });
  dispatch(addNewAlert(i18n.t('notifications.logout'), ALERT_SUCCESS));
  history.push('/login');
};

export const twoFactorSend = () => async (dispatch, getState) => {
  // Do request to ask for code?
  const state = getState();
  const { userInfo } = state.user;

  const decodedPassword = decrypt(userInfo.password);
  const formData = new FormData();
  formData.append('username', userInfo.username);
  formData.append('password', decodedPassword);

  const options = {
    method: 'POST',
    body: formData,
  };

  const response = await fetch(endpoints.login, options);
  const { status } = await response.json();
  if (status !== 200 && status !== 401) {
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
    return { success: false };
  }

  dispatch(addNewAlert(i18n.t('notifcations.resendToken'), ALERT_SUCCESS));
  return { success: true };
};

export const twoFactorSubmit = (twoFactorCode, remember2fa) => async (dispatch, getState) => {
  // Validate Code
  if (!twoFactorCode) {
    dispatch({ type: USER_TWO_FACTOR_ERROR, payload: '' });
    return { success: false, errors: { code: i18n.t('validations.required') } };
  }

  if (twoFactorCode && twoFactorCode.length !== 6) {
    dispatch({ type: USER_TWO_FACTOR_ERROR, payload: '' });
    return { success: false, errors: { code: i18n.t('validations.maxCodeLength') } };
  }

  const state = getState();
  const { userInfo } = state.user;

  const decodedPassword = decrypt(userInfo.password);
  const formData = new FormData();
  formData.append('username', userInfo.username);
  formData.append('password', decodedPassword);
  formData.append('code', twoFactorCode);
  if (remember2fa) {
    formData.append('remember2fa', remember2fa);
  }

  const options = {
    method: 'POST',
    body: formData,
  };
  try {
    dispatch(openLoader());
    const response = await fetch(endpoints.twoFactor, options);
    const { data, status } = await response.json();
    if (status !== 200) {
      // dispatch(addNewAlert(error.description, ALERT_ERROR))
      return { success: false, errors: { code: i18n.t('validations.invalidCode') } };
    }
    dispatch(setUpCookie(data?.token?.access_token, data?.token?.expires_in));
    dispatch(addNewAlert(i18n.t('notifications.login'), ALERT_SUCCESS));

    const { languages } = state.common;
    const userData = data?.options;
    const userLanguage = userData?.language;
    const selectedLanguage = languages.find((lang) => (lang.locale === userLanguage));
    if (selectedLanguage?.code) changeLanguage(selectedLanguage.code);

    dispatch({
      type: USER_TWO_FACTOR_SUCCESS,
      payload: {
        twoFactor: true,
        token: data?.token?.access_token,
        userInfo: {
          ...userInfo,
          ...loginData(userData, userLanguage),
        },
        ...selectedLanguage?.code && { language: selectedLanguage.code },
        defaultOrganization: userData?.organizations.length > 0 ? userData?.organizations[0] : null,
      },
    });

    history.push({ pathname: '/dashboard', from: history.location.pathname });
  } catch (e) {
    dispatch({ type: USER_TWO_FACTOR_ERROR, payload: '' });
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
  } finally {
    dispatch(closeLoader());
  }
  return { success: true, errors: [] };
};

export const requestPasswordRecovery = (email) => async (dispatch) => {
  dispatch({
    type: SAVE_USER_DATA,
    payload: {
      tempMail: email,
    },
  });

  const formData = new FormData();
  formData.append('username', email);
  const options = {
    method: 'POST',
    body: formData,
  };
  try {
    dispatch(openLoader());
    const response = await fetch(endpoints.resetPassword, options);
    const { error, status } = await response.json();
    if (status !== 200 && error) {
      if (status !== 200 && error && error.description) {
        dispatch(addNewAlert(error.description, ALERT_ERROR));
      }
    } else {
      dispatch(addNewAlert(i18n.t('notifications.passwordRecoverySuccess'), ALERT_SUCCESS));
      history.push({ pathname: '/confirm-password', from: history.location.pathname });
    }
  } catch {
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
  } finally {
    dispatch(closeLoader());
  }
};

export const checkToken = () => () => {
  const searchParams = new URLSearchParams(history.location.search);
  if (!searchParams.get('token')) {
    return history.replace('/login');
  }
  return { username: searchParams.get('username'), token: searchParams.get('token') };
};

const checkPasswordErrors = (password, regex, error, errors) => {
  if (!regex.exec(unescape(password))) {
    errors.push(error);
  }
  return errors;
};

const checkPasswordRegex = (newPassword) => {
  const regexChecks = [];
  // Regex that checks if a string has at least 1 uppercase string
  const passwordUppercaseRegex = /^(?=.*[A-Z])^/;
  checkPasswordErrors(newPassword, passwordUppercaseRegex, 'upperCaseRequired', regexChecks);
  // Regex that checks if a string has at least 1 downcase string
  const passwordDowncaseRegex = /^(?=.*[a-z])^/;
  checkPasswordErrors(newPassword, passwordDowncaseRegex, 'downCaseRequired', regexChecks);
  // Regex that checks if a string has at least 1 number/digit ASCII
  const passwordNumbersRegex = /^(?=.*\d)^/;
  checkPasswordErrors(newPassword, passwordNumbersRegex, 'numberRequired', regexChecks);
  // Regex that checks if a string has at least 1 non alphanumeric digits
  const passwordNonAlphaNumericAsciiRegex = /^(?=.*[\W\s_])^/;
  checkPasswordErrors(newPassword, passwordNonAlphaNumericAsciiRegex, 'specialCharRequired', regexChecks);
  // Regex that checks if a string has at least 1 non ascii regex
  const passwordAllAsciiRegex = /^[\000-\177]*$/;
  if (passwordAllAsciiRegex.test(unescape(newPassword))) {
    regexChecks.push('NonAsciiRequired');
  }

  return regexChecks;
};

export const getPasswordErrors = (
  newPassword,
  newPasswordConfirmation,
  dispatcher = true,
) => (dispatch) => {
  let errors = [];

  if (newPassword !== newPasswordConfirmation) {
    errors.push('notEqual');
  }

  const regexChecks = checkPasswordRegex(newPassword);

  if (newPassword.length < MIN_PASSWORD_LENGTH) {
    // console.warn('Password does not meet the length requirements')
    errors.push('minLength');
  }

  if (newPassword.length > MAX_PASSWORD_LENGTH) {
    errors.push('maxLength');
  }

  // Must include characters from at least three (3) of the regex validations
  if (regexChecks.length >= 3) {
    errors = errors.concat(regexChecks);
  }

  if (errors.length > 0) {
    if (dispatcher) {
      dispatch({ type: USER_SET_PASSWORD_ERROR, payload: { passwordErrors: errors } });
    }
    return { success: true, errors };
  }

  return { success: false, errors };
};

export const resetPassword = (
  newPassword,
  newPasswordConfirmation,
) => async (dispatch) => {
  if (newPassword !== newPasswordConfirmation) {
    dispatch(addNewAlert(i18n.t('notifications.passwordNotEqual'), ALERT_ERROR));
  }
  if (getPasswordErrors(newPassword, newPasswordConfirmation).success === true) {
    return;
  }

  const { username, token } = dispatch(checkToken());

  const body = {
    password: newPassword,
    username,
    token,
  };

  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };

  try {
    dispatch(openLoader());
    const response = await fetch(endpoints.setPasswordRecovery(username), options);
    const { error, status } = await response.json();
    if (status !== 200 && error) {
      if (status !== 200 && error && error.description) {
        dispatch(addNewAlert(error.description, ALERT_ERROR));
      }
    } else {
      dispatch(addNewAlert(i18n.t('notifications.passwordChangeSuccess'), ALERT_SUCCESS));
      history.push('/login');
    }
  } catch {
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
  } finally {
    dispatch(closeLoader());
  }
};

export const editUser = (
  firstName,
  lastName,
  email,
  password,
  language,
) => async (dispatch, getState) => {
  const formData = {};

  const state = getState();
  const { userInfo, token } = state.user;

  const errors = {};
  const passwordErrors = dispatch(getPasswordErrors(password, password, false));
  if (password && passwordErrors.success) {
    const passwordText = convertPasswordErrorsIntoText(passwordErrors.errors);
    errors.password = passwordText;
  }

  if (!language) {
    errors.language = i18n.t('validations.required');
  }
  if (firstName && firstName.length > 40) errors.firstname = i18n.t('validations.firstNameExceeded');
  if (lastName && lastName.length > 40) errors.lastname = i18n.t('validations.lastNameExceeed');
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (email && !emailRegex.exec(email)) {
    errors.email = i18n.t('validations.invalidEmail');
  }

  if (Object.keys(errors).length > 0) {
    dispatch(addNewAlert(i18n.t('notifications.fieldValidations'), ALERT_ERROR));
    return { success: false, errors };
  }

  if (firstName && firstName !== userInfo.firstName) formData.firstname = firstName;
  if (lastName && lastName !== userInfo.lastName) formData.lastname = lastName;
  if (email && email !== userInfo.email) formData.email = email;
  if (language && language !== userInfo.language) formData.language = language;
  if (password) formData.password = password;

  if (Object.keys(formData).length === 0) {
    return { success: false, errors: {} };
  }

  formData.username = userInfo.username;
  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(formData),
  };

  dispatch(openLoader());
  try {
    const response = await fetch(endpoints.user(userInfo.username), options);
    const { error, status } = await response.json();

    const logoutResult = dispatch(logoutUserOn401(status));
    if (logoutResult) return { success: false };
    if (status !== 200 && error) {
      if (status !== 200 && error && error.description) {
        dispatch(addNewAlert(error.description, ALERT_ERROR));
      }
    } else {
      const { user, common } = getState();
      const { userInfo } = user;
      const { languages } = common;

      const selectedLanguage = languages.find((lang) => (lang.locale === language));
      changeLanguage(selectedLanguage.code);

      dispatch({
        type: UPDATE_USER_PROFILE,
        payload: {
          userInfo: {
            ...userInfo,
            firstName,
            lastName,
            email,
            language,
          },
          language: selectedLanguage.code,
        },
      });
      dispatch(addNewAlert(i18n.t('notifications.userUpdateSuccess'), ALERT_SUCCESS));
    }
    dispatch(closeLoader());
  } catch (e) {
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
    dispatch(closeLoader());
  }
  return { success: true, errors: {} };
};

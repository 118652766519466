export default {
  login: 'Acceso',
  username: 'Nombre de usuario',
  password: 'Contraseña',
  email: 'Correo electrónico',
  firstname: 'Nombre propio',
  lastname: 'Apellido',
  role: 'Papel',
  passwordPlaceholder: 'Introducir la contraseña',
  usernamePlaceholder: 'Introduzca su nombre de usuario',
  signin: 'Registrarse',
  forgotMyPassword: 'Olvidé mi contraseña',
  requirements: 'Requisitos',
  resetPassword: 'Restablecer la contraseña',
  resetPasswordSubtitle: 'Ingrese el nombre de usuario asociado a su cuenta.',
  resetPasswordSubmit: 'Envíame un enlace de restablecimiento de contraseña',
  resetLinkSent: 'Restablecer enlace enviado',
  resetLinkSubtitle: 'Enviamos un vínculo para restablecer su contraseña al correo electrónico asociado con su cuenta.',
  resetLinkSubmit: 'Envíame el enlace de nuevo',
  createNewPassword: 'Crear nueva contraseña',
  passwordReq1: 'Al menos 7 caracteres',
  passwordReq2: 'Contiene 1 letra mayúscula',
  passwordReq3: 'Contiene 1 Número',
  passwordReq4: 'Contiene 1 carácter especial',
  passwordReq5: 'Contiene 1 letra minúscula',
  newPassword: 'Nueva contraseña',
  newPasswordPlaceholder: 'Introduzca una nueva contraseña',
  reEnterNewPassword: 'Vuelve a introducir la nueva contraseña',
  reEnterNewPasswordPlaceholder: 'Re-ingrese nueva contraseña',
  createNewPasswordSubmit: 'Cambiar mi contraseña',
  twoFactorAuth: 'Introduzca la dirección de correo electrónico',
  twoFactorAuthSubtitle: 'Enviamos un código de verificación al correo electrónico asociado con su cuenta.',
  twoFactorAuthSubmit: 'Envíame un código de verificación',
  twoFactorCode: 'Ingrese el código de verificación',
  twoFactorCodeSubtitle: 'Enviamos un código de verificación a la dirección de correo electrónico asociada con esta cuenta.',
  enterCode: 'Introduzca el código',
  enterCodePlaceholder: 'Introducir el código de 6 dígitos',
  twoFactorCodeSubmit: 'Confirmar',
  twoFactorCodeLink: 'Volver a enviar mi código',
  twoFactorCodeCheckbox: 'No me vuelvas a pedir 2FA',
  reportsTitle: 'Informes',
  newReport: 'Nuevo reporte',
  admin: 'Administración',
  tags: 'Etiquetas',
  users: 'Usuarios',
  exportreport: 'Informes de exportación',
  profile: 'Perfil',
  terminology: 'Terminología',
  mysettings: 'Mi configuración',
  edit: 'Editar',
  organizations: 'Organizaciones',
  analytics: 'Analítica',
  analyticsTitle: 'Esta semana enfoques',
  analyticsSubmissions: 'Presentaciones',
  analyticsReportByCat: 'Top informes por categoría',
  analyticsFromLastWeek: 'desde la semana pasada',
  analyticsRepSubmited: 'Informes presentados',
  analyticsRepVerified: 'Informes verificados',
  analyticsIssuesResp: 'Respondieron a cuestiones',
  analyticsStoriesCreated: 'Historias creado',
  analyticsGeographicPrio: 'Prioridades geográficas',
  analyticsPopularKeywords: 'Palabras clave populares',
  analyticsKeyword: 'Palabra Clave',
  analyticsMentions: 'Menciones',
  analyticsUsertypes: 'Los tipos de usuario',
  analyticsTopUsers: 'Top usuarios',
  analyticsUsers: 'Usuarios',
  analyticsActivity: 'Actividad',
  contactUsers: 'usuarios de contacto',
  send: 'Enviar',
  sendToEveryone: 'Enviar a cada uno',
  sendSpecificUsers: 'Enviar mensaje a usuarios específicos',
  selectUsers: 'Seleccione los usuarios',
  enterMessage: 'Introduzca su mensaje aquí',
  search: 'Buscar',
  addNew: 'Añadir nuevo',
  save: 'Ahorrar',
  ok: 'Ok',
  enterFirstName: 'Introduzca el nombre',
  enterLastName: 'Introduzca el apellido',
  enterEmailPlaceholder: 'Introduzca la dirección de correo electrónico',
  createReport: 'Crear reporte',
  reportDetails: 'Detalles de informe',
  reportTitleLabel: 'Título del informe',
  reportTitlePlaceholder: 'Introduzca el título del informe',
  reportDescriptionLabel: 'Descripción del reporte',
  reportDescriptionPlaceholder: 'Introduzca el Informe Descripción',
  reportAuthorLabel: 'Seleccionar Autor',
  reportAuthorPlaceholder: 'Seleccione el Autor',
  reportHour: 'Hora',
  reportMinute: 'Minuto',
  reportMapLabel: 'Seleccionar en el mapa',
  reportAddMedia: 'Añadir media',
  reportUploadMedia: 'Subir fotos, vídeos, audio o PDF',
  reportDragMedia: 'Arrastrar y soltar archivos de medios de comunicación aquí',
  reportSupportMedia: 'Apoyamos fotos, vídeos, audio o archivos PDF',
  reportBrowseMedia: 'Navegar',
  reportUploadFiles: 'Archivos subidos',
  reportPublic: 'Público',
  reportSeeActivity: 'Ver actividad',
  reportUnverified: 'Inconfirmado',
  reportVerified: 'Confirmado',
  reportVerify: 'Verificar',
  reportSearch: 'Los informes de búsqueda',
  reportedBy: 'Reportado por',
  reportWhatHappening: 'Lo que está sucediendo',
  reportLocation: 'Localización',
  reportCoordinates: 'Coordenadas',
  copyDMS: 'Copia DMS',
  copyDMM: 'Copia DD',
  reportImageEvidence: 'Las pruebas de imagen',
  reportImageDescription: 'Descripción de la imagen',
  reportSaveClose: 'Guardar y cerrar',
  reportAudioEvidence: 'Evidencia de audio',
  reportPDFEvidence: 'Cargas PDF',
  reportVideoEvidence: 'Pruebas de vídeo',
  reportVideoDescription: 'Descripción del video',
  reportUpdates: 'Actualizaciones',
  reportActivityUploadMedia: 'Subir medios',
  reportActivityAssignUser: 'Asignar usuarios',
  reportActivitySetUrgency: 'Urgencia conjunto',
  reportActivityPlaceholder: 'Agregue un comentario o medios adicionales a este informe',
  updateAction: 'Actualizar',
  editReport: 'Editar informe',
  goBack: 'Atrás',
  reportCritical: 'Este informe es crítico',
  reportVeryUrgent: 'Este informe es muy urgente',
  reportUrgent: 'Este informe es urgente',
  reportFilter: 'Filtrar',
  reportStatus: 'Status',
  reportFolders: 'Carpetas',
  urgent: 'Urgente',
  reportDateCreated: 'Fecha de creacion',
  reportAssignment: 'Asignación',
  reportAssignee: 'Cesionario',
  reportAuthor: 'Autor',
  reportDateCreatedPlaceholder: 'Seleccione fecha',
  reportDateLabel: '¿Cuándo sucedió el evento?',
  reportDatePlaceholder: 'Ingrese la fecha del informe',
  reportMapTitle: 'Dónde sucedió',
  reportDMSCoordinate: 'Coordenadas DMS',
  reportDMMCoordinate: 'Coordenadas DMM',
  contactRecent: 'Notificaciones recientes',
  storyTitle: 'Crear contenido',
  storyCreate: 'Crear historia',
  storySearch: 'Buscar contenido',
  published: 'Publicado',
  updated: 'Actualizado',
  publishedOn: 'Publicado en',
  storyType: 'Tipo de historia',
  unpublish: 'Despublicar',
  publish: 'Publicar',
  unpublished: 'Inédito',
  readFullReport: 'Lea el informe completo',
  coverImage: 'Imagen de portada',
  storyTextContent: 'Contenido del texto',
  storyTitlePlaceholder: 'Ingrese su título aquí',
  storySubtitlePlaceholder: 'Ingrese su subtítulo aquí',
  closeReport: 'Cerrar informe',
  storyAddNew: 'Agregar nuevo cuadro de texto',
  saveChanges: 'Guardar cambios',
  userOrganizationPlaceholder: 'Ingrese las organizaciones del usuario',
  enable2FA: 'Habilitar 2FA',
  cancel: 'Cancelar',
  deleteWarning: 'No se puede deshacer',
  createTagGroup: 'Crear nuevo grupo de etiquetas',
  createTag: 'Crear nueva etiqueta para el grupo',
  createUser: 'Crear nuevo usuario',
  createOrganization: 'Crear nueva organización',
  createLayer: 'Crear nueva capa',
  deleteTagGroup: 'Eliminar grupo de etiquetas',
  deleteTagGroupDescription: '¿Está seguro de que desea eliminar este grupo de etiquetas?',
  deleteTag: 'Eliminar elemento de etiqueta',
  deleteTagDescription: '¿Está seguro de que desea eliminar este elemento de etiqueta?',
  myTags: 'Mis etiquetas',
  editTagGroup: 'Editar grupo de etiquetas',
  mapParameters: 'Parámetros del mapa',
  latitude: 'Latitud',
  longitude: 'Longitud',
  zoomLevel: 'Nivel del zoom',
  layers: 'Capas',
  vector: 'Vector',
  raster: 'Raster',
  layerName: 'Nombre de capa',
  layerShapeFiles: 'Apoyamos archivos de formas, KML, y GeoJSON',
  dragdropLayers: 'Arrastra y suelta capas aquí',
  color: 'Color',
  translations: 'Traducciones',
  name: 'Nombre',
  defaultLanguage: 'Idioma predeterminado',
  selectReportToExport: 'Seleccione los informes a la exportación',
  exportAll: 'Exportar todo',
  export: 'Exportar',
  enterNameIn: 'Ingrese el nombre en',
  enterLayerNameIn: 'Ingrese el nombre de la capa en',
  enterLayerName: 'Ingrese el nombre de la capa',
  rasterHostedUrl: 'Alojado trama URL',
  enterRasterHostedUrl: 'Ingrese la URL alojada en ráster',
  enterColor: 'Ingrese color',
  enterUserRole: 'Ingrese el rol de usuario',
  deleteSuccess: 'Si eliminar',
  subject: 'Asunto',
  enterSubject: 'Ingrese Asunto',
  recipients: 'Destinatarios',
  deleteUser: 'Eliminar usuario',
  deleteUserDescription: '¿Está seguro de que desea eliminar este usuario?',
  deleteOrganization: 'Eliminar organización',
  deleteOrganizationDescription: '¿Estás seguro de que deseas eliminar esta organización?',
  singleSelect: 'Selección única',
  multiSelect: 'Selección múltiple',
  textField: 'Campo de texto',
  searchInTheMap: 'Buscar mientras muevo el mapa',
  select: 'Seleccione',
  enterReportTag: 'Ingrese el {{tag}} del informe',
  enterZoom: 'Seleccione Zoom',
  basemaps: 'Los mapas base',
  map: 'Mapa',
  satelitte: 'Satélite',
  deleteReport: 'Eliminar informe',
  deleteReportDescription: '¿Está seguro de que desea eliminar este informe?',
  closeAction: 'Cerrar',
  reportLifecycle: 'Ciclo vital',
  languages: 'Idiomas',
  additionalSettings: 'Ajustes adicionales',
  deleteLayer: 'Eliminar capa',
  deleteLayerDescription: '¿Estás seguro de que deseas eliminar esta capa?',
  apply: 'Aplicar',
  reset: 'Reiniciar',
  unverify: 'Rechazar',
  enabledLanguages: 'Idiomas habilitados',
  selectLanguages: 'Seleccionar idiomas',
  updateTerminology: 'Actualizar la terminología',
  currentString: 'Cadena actual',
  resetToDefault: 'Restablecen a los predeterminados',
  dragCsv: 'Arrastre y suelte el archivo CSV de idioma aquí',
  supportCsv: 'Solo admitimos CSV',
  anonymousSignIn: 'Inicios de sesión anónimos en dispositivos móviles',
  mapsOnMobile: 'Mapas en dispositivos móviles',
  lessonPath: 'Ruta de lecciones',
  emergencyAlerts: 'Alertas de emergencia',
  emergencyContactUser: 'Usuario de contacto de emergencia',
  emergencyContactNumber: 'Número de contacto de emergencia',
  contactNumber: 'Número de contacto',
  enterCustomLessonPath: 'Ingresar ruta de lección personalizada',
  urgency: 'Urgencia',
  analyticsPage: 'Página de análisis',
  notificationsPage: 'Página de notificaciones',
  storiesPage: 'Página de historias',
  dashboardConfiguration: 'Configuraciones del dashboard',
  publicSite: 'Site público',
  mapOnPublicSite: 'Mapa en site público',
  grievencePortal: 'Portal de quejas',
  localization: 'Localización',
  timeZone: 'Zona horaria',
  mobileOrganizationLabel: 'Organización para aplicación móvil',
  mobileOrganizationPlaceholder: 'Seleccionar una organización',
  sendByUsers: 'Enviar por los usuarios',
  sendByRoles: 'Enviar por rol',
  private: 'Privado',
  reportState: 'Estado',
  date: 'Fecha',
  startDate: 'Fecha de inicio',
  endDate: 'Fecha final',
  organization: 'Organización',
  seconds: 'Segundos',
  degrees: 'Grados',
  direction: 'Dirección',
  default: 'Defecto',
  custom: 'Personalizado',
  addAdditionalEmergencyNumber: 'Agregar un número de contacto de emergencia adicional',
  stories: 'Cuentos',
  messages: 'Mensajes',
  addTagGroup: 'Agregar grupo de etiquetas',
  addNewUser: 'Agregar usuario',
  addNewOrganization: 'Agregar organización',
  addNewLayer: 'Agregar capa',
  exportAction: 'Exportar',
};

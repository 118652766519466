import React from 'react';
import t from 'prop-types';
import SelectBox from '../../ui/SelectBox/SelectBox';
import TextInput from '../TextInput/TextInput';

const DynamicTagSelect = ({
  tagGroup, onChange, selectedState, defaultLanguage, placeholder, title, alwaysMulti,
}) => {
  const options = tagGroup?.children || [];
  if (tagGroup.type === 'text') {
    let prompt = tagGroup.children[0];
    if (prompt) {
      prompt = prompt[`name_${defaultLanguage}`] || prompt.name_en;
    } else {
      prompt = '';
    }
    return (
      <TextInput
        title={title}
        placeholder={prompt}
        isSelectingPlaceholder
        handleChange={(e) => {
          onChange(tagGroup.id, { tag_id: tagGroup.id, text: e.target.value }); 
        }}
        value={selectedState[tagGroup.id]?.text || ''}
      />
    );
  }

  // Type is either single or multi
  let formattedSelectedState = selectedState[tagGroup.id];
  if (formattedSelectedState === undefined) {
    formattedSelectedState = (tagGroup.type === 'multi' || alwaysMulti) ? [] : null;
  }

  if (Array.isArray(selectedState[tagGroup.id]) && tagGroup.type === 'single' && !alwaysMulti) {
    formattedSelectedState = formattedSelectedState[0];
  }

  return (
    <SelectBox
      title={title}
      placeholder={placeholder}
      handleChange={(e, { value }) => { onChange(tagGroup.id, value); }}
      multiple={(tagGroup.type === 'multi' || alwaysMulti)}
      clearable
      value={(tagGroup.type === 'multi' || alwaysMulti) ? formattedSelectedState || [] : formattedSelectedState}
      options={options.map((elem) => ({ key: elem.id, value: elem.id, text: elem[`name_${defaultLanguage}`] || elem.name_en }))}
    />
  );
};

DynamicTagSelect.propTypes = {
  title: t.string,
  alwaysMulti: t.bool,
  selectedState: t.oneOfType([t.object, t.array]).isRequired,
  tagGroup: t.object.isRequired,
  defaultLanguage: t.string.isRequired,
  placeholder: t.string.isRequired,
  onChange: t.func.isRequired,
};

DynamicTagSelect.defaultProps = {
  title: '',
  alwaysMulti: false,
};

export default DynamicTagSelect;

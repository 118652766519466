import React from 'react';
import t from 'prop-types';
import '../../../styles/index.scss';
import clsx from 'clsx';

function ColorPicker({
                       hasTitle = true,
                       title,
                       placeholder,
                       type = 'color',
                       value = '',
                       handleChange,
                       ref,
                       disabled,
                       customInputClass,
                       customWrapperClass,
                       hasError,
                       errorDescription = '',
                       isSelectingPlaceholder = false,
                     }) {
  return (
    <div className={clsx('color-input__wrapper', {
      [`${customWrapperClass}`]: !!customWrapperClass,
    })}
    >
      {hasTitle && <div className="color-input__label">{title}</div>}
      <input
        className={clsx('color-input__input', {
          [`${customInputClass}`]: !!customInputClass,
          'color-input__input__error': hasError,
          'color-input__input__tagplaceholder': isSelectingPlaceholder,
        })}
        value={value}
        type={type}
        onChange={handleChange}
        disabled={disabled}
        ref={ref}
        autoCorrect="none"
        autoComplete="none"
        spellCheck={false}
        placeholder={placeholder}
      />
      {hasError && errorDescription && (
        <span className="color-input__error" dangerouslySetInnerHTML={{ __html: errorDescription }}/>
      )}
    </div>
  );
}

ColorPicker.propTypes = {
  hasTitle: t.bool,
  title: t.string,
  placeholder: t.string,
  type: t.string,
  value: t.string,
  handleChange: t.func,
  ref: t.object,
  disabled: t.bool,
  customInputClass: t.string,
  hasError: t.bool,
  errorDescription: t.string,
  isSelectingPlaceholder: t.bool,
};

ColorPicker.defaultProps = {
  hasTitle: true,
  title: '',
  placeholder: '',
  type: 'color',
  value: '',
  handleChange: () => {},
  ref: {},
  disabled: false,
  customInputClass: '',
  customWrapperClass: '',
  hasError: false,
  isSelectingPlaceholder: false,
  errorDescription: '',
};

export default ColorPicker;

import {
  ADD_ACTIVITY_REPORT,
  APPLY_FILTERS,
  CREATE_REPORT,
  DELETE_REPORT,
  GET_REPORTS,
  RESET_FILTERS,
  SELECT_REPORT,
  UNSELECT_REPORT,
  UPDATE_ACTIVITY_REPORT,
  RESET_REPORTS,
} from '../types';

const initialState = {
  reports: [],
  offset: 0,
  filters: {},
  selectedReport: null,
  isMapPanel: false,
  hasReachedEnd: false,
  activityFeed: [],
  activityUsersColor: {},
  activityFeedOffset: 0,
  activityFeedReachedEnd: false,
};

const reportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case (GET_REPORTS): {
      const oldReports = state.reports || [];
      const newReports = action.payload.reports;
      return {
        ...state,
        reports: oldReports.concat(...newReports),
        offset: oldReports.concat(...newReports).length,
        hasReachedEnd: action.payload.hasReachedEnd,
        filters: {
          ...(state.filters || {}),
        },
      };
    }

    case (CREATE_REPORT): {
      return {
        ...state,
        hasReachedEnd: false,
      };
    }
    case (UPDATE_ACTIVITY_REPORT): {
      const oldActivity = state.activityFeed || [];
      const { colors, activityFeed: newActivity } = action.payload;
      return {
        ...state,
        activityFeed: oldActivity.concat(...newActivity),
        activityUsersColor: colors,
        activityFeedOffset: oldActivity.concat(...newActivity).length,
        activityFeedReachedEnd: action.payload.hasReachedEnd,
      };
    }

    case (APPLY_FILTERS): {
      return {
        ...state,
        filters: action.payload.filters,
      };
    }

    case (RESET_FILTERS): {
      return {
        ...state,
        filters: {},
      };
    }

    case (RESET_REPORTS): {
      return {
        ...state,
        reports: [],
        offset: 0,
        hasReachedEnd: false,
      };
    }

    case (SELECT_REPORT): {
      const {selectedReport, updatedReports } = action.payload;
      return {
        ...state,
        selectedReport,
        activityFeed: selectedReport.uuid === state.selectedReport?.uuid
          ? state.activityFeed : [],
        activityFeedOffset: selectedReport.uuid === state.selectedReport?.uuid
          ? state.activityFeedOffset : 0,
        activityFeedReachedEnd: selectedReport.uuid === state.selectedReport?.uuid
          ? state.activityFeedReachedEnd : false,
        reports: updatedReports,
      };
    }

    case (ADD_ACTIVITY_REPORT): {
      const oldActivity = state.activityFeed || [];
      const { colors, newActivityFeed: newActivity } = action.payload;
      return {
        ...state,
        activityFeed: [...newActivity, ...oldActivity],
        activityUsersColor: colors,
        activityFeedOffset: [...newActivity, ...oldActivity].length,
        activityFeedReachedEnd: action.payload.hasReachedEnd,
      };
    }

    case (UNSELECT_REPORT): {
      return {
        ...state,
        selectedReport: null,
        activityFeed: [],
        activityFeedOffset: 0,
        activityFeedReachedEnd: false,
      };
    }

    case (DELETE_REPORT): {
      const { reportId } = action.payload;
      const filteredReports = state.reports.filter((elem) => (elem.uuid !== reportId));

      return {
        ...state,
        reports: filteredReports,
        selectedReport: null,
        offset: filteredReports.length,
      };
    }
    default:
      return state;
  }
};

export default reportsReducer;

import '../../../../styles/index.scss';
import React, { useEffect, useRef, useState } from 'react';
import types from 'prop-types';
import ReactMapboxGl, { Marker } from 'react-mapbox-gl';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CopyIcon } from '../../../../assets/images/copy_icon.svg';
import { convertDMS, getDegreesMinutesAndSeconds, parseDMS } from '../../../../utils';
import mapDispatchToProps from '../../../../actions/index';
import TextInput from '../../../shared/TextInput/TextInput';
import SelectBox from '../../../ui/SelectBox/SelectBox';
import { MAPBOX_SECRET_KEY } from '../../../../shared/consts';

const Map = ReactMapboxGl({
  attributionControl: false,
  accessToken:
    MAPBOX_SECRET_KEY,
});

function ReportMap(props) {
  const { t } = useTranslation();
  const editMode = useRef(props.editMode);
  const [latitudeDegrees, setLatitudeDegrees] = useState(0);
  const [latitudeMinutes, setLatitudeMinutes] = useState(0);
  const [latitudeSeconds, setLatitudeSeconds] = useState(0);
  const [latitudeCardinal, setLatitudeCardinal] = useState('N');
  const [longitudeDegrees, setLongitudeDegrees] = useState(0);
  const [longitudeMinutes, setLongitudeMinutes] = useState(0);
  const [longitudeSeconds, setLongitudeSeconds] = useState(0);
  const [longitudeCardinal, setLongitudeCardinal] = useState('W');
  const minuteOptions = Array.from(Array(60).keys()).map((elem) => (
    { key: elem, value: elem, text: elem }
  ));

  const secondsOptions = Array.from(Array(60).keys()).map((elem) => (
    { key: elem, value: elem, text: elem }
  ));

  const copyDMS = () => {
    navigator.clipboard.writeText(`${parseFloat(props.latitude).toFixed(4)}, ${parseFloat(props.longitude).toFixed(4)}`);
    props.addSuccessAlert(t('notifications.CopyDD'));
  };

  useEffect(() => {
    editMode.current = props.editMode;
    if (props.editMode) {
      const DMSLatitude = getDegreesMinutesAndSeconds(props.latitude, 'lat');
      const DMSLongitude = getDegreesMinutesAndSeconds(props.longitude, 'long');
      setLatitudeDegrees(DMSLatitude.degrees);
      setLatitudeMinutes(DMSLatitude.minutes);
      setLatitudeSeconds(DMSLatitude.seconds);
      setLatitudeCardinal(DMSLatitude.cardinal);
      setLongitudeDegrees(DMSLongitude.degrees);
      setLongitudeMinutes(DMSLongitude.minutes);
      setLongitudeSeconds(DMSLongitude.seconds);
      setLongitudeCardinal(DMSLongitude.cardinal);
    }
  }, [props.editMode]);

  const copyDMM = () => {
    navigator.clipboard.writeText(convertDMS(props.latitude, props.longitude));
    props.addSuccessAlert(t('notifications.CopyDMS'));
  };

  const onClickMap = (map, e) => {
    if (!editMode.current) return;

    const { lat } = e.lngLat;
    const { lng } = e.lngLat;
    props.setLatitude(lat);
    props.setLongitude(lng);
    const DMSLatitude = getDegreesMinutesAndSeconds(e.lngLat.lat, 'lat');
    setLatitudeDegrees(DMSLatitude.degrees);
    setLatitudeMinutes(DMSLatitude.minutes);
    setLatitudeSeconds(DMSLatitude.seconds);
    setLatitudeCardinal(DMSLatitude.cardinal);
    const DMSLongitude = getDegreesMinutesAndSeconds(e.lngLat.lng, 'long');
    setLongitudeDegrees(DMSLongitude.degrees);
    setLongitudeMinutes(DMSLongitude.minutes);
    setLongitudeSeconds(DMSLongitude.seconds);
    setLongitudeCardinal(DMSLongitude.cardinal);
  };

  const formatDMS = () => `${parseFloat(props.latitude).toFixed(4)}, ${parseFloat(props.longitude).toFixed(4)}`;

  const formatDMM = () => convertDMS(props.latitude, props.longitude);

  const setDegrees = (value, type = 'lat') => {
    const newErrors = props.errors;
    if (type === 'lat') {
      const parsedValue = parseFloat(value);
      if (parsedValue < 0 || parsedValue > 90) {
        setLatitudeDegrees('');
        props.setLatitude('');
        newErrors.latitude = t('validations.latitudeInvalidNumber');
        return null;
      }
      setLatitudeDegrees(value);
      if (value !== '' && latitudeMinutes !== null
        && latitudeSeconds !== null && latitudeCardinal !== null
      ) {
        if (value === '90') {
          props.setLatitude(parseDMS(value, 0, 0, latitudeCardinal));
          setLatitudeMinutes(0);
          setLatitudeSeconds(0);
        } else {
          props.setLatitude(parseDMS(value, latitudeMinutes, latitudeSeconds, latitudeCardinal));
        }
        delete newErrors.latitude;
      } else {
        newErrors.latitude = t('validations.required');
        props.setLatitude('');
      }
    }

    if (type === 'long') {
      const parsedValue = parseFloat(value);
      if (parsedValue < 0 || parsedValue > 180) {
        setLongitudeDegrees('');
        props.setLongitude('');
        newErrors.longitude = t('validations.longitudeInvalidNumber');
        return null;
      }
      setLongitudeDegrees(value);
      if (value !== '' && longitudeMinutes !== null && longitudeSeconds !== null && longitudeCardinal !== null) {
        if (value === '180') {
          props.setLongitude(parseDMS(value, 0, 0, longitudeCardinal));
          setLongitudeMinutes(0);
          setLongitudeSeconds(0);
        } else {
          props.setLongitude(
            parseDMS(value, longitudeMinutes, longitudeSeconds, longitudeCardinal),
          );
        }
        delete newErrors.longitude;
      } else {
        newErrors.longitude = t('validations.required');
        props.setLongitude('');
      }
    }
    props.setErrors(newErrors);
    return null;
  };

  const setCoordMins = (value, type = 'lat') => {
    const newErrors = props.errors;
    if (type === 'lat') {
      if (latitudeDegrees === '90') {
        setLatitudeMinutes(0);
        props.setLatitude(parseDMS(latitudeDegrees, 0, 0, latitudeCardinal));
        return;
      }
      setLatitudeMinutes(value);
      if (latitudeDegrees !== '' && value !== null && latitudeSeconds !== null && latitudeCardinal !== null) {
        props.setLatitude(parseFloat(
          parseDMS(latitudeDegrees, value, latitudeSeconds, latitudeCardinal)
        ).toFixed(6));
      } else {
        props.setLatitude('');
      }
    }

    if (type === 'long') {
      if (longitudeDegrees === '180') {
        setLongitudeMinutes(0);
        props.setLongitude(parseDMS(longitudeDegrees, 0, 0, longitudeCardinal));
        return;
      }
      setLongitudeMinutes(value);
      if (longitudeDegrees !== null && value !== null
        && longitudeSeconds !== null && longitudeCardinal !== null
      ) {
        props.setLongitude(parseFloat(
          parseDMS(longitudeDegrees, value, longitudeSeconds, longitudeCardinal)
        ).toFixed(6));
      } else {
        props.setLongitude('');
      }
    }
    props.setErrors(newErrors);
  };

  const setCoordSecs = (value, type = 'lat') => {
    const newErrors = props.errors;
    if (type === 'lat') {
      if (latitudeDegrees === '90') {
        setLatitudeSeconds(0);
        props.setLatitude(parseDMS(latitudeDegrees, 0, 0, latitudeCardinal));
        return;
      }
      setLatitudeSeconds(value);
      if (latitudeDegrees !== null && latitudeMinutes !== null
        && value !== null && latitudeCardinal !== null
      ) {
        props.setLatitude(parseFloat(
          parseDMS(latitudeDegrees, latitudeMinutes, value, latitudeCardinal),
        ).toFixed(6));
        delete newErrors.latitude;
      } else {
        props.setLatitude('');
      }
    }

    if (type === 'long') {
      if (longitudeDegrees === '180') {
        setLongitudeSeconds(0);
        props.setLongitude(parseDMS(longitudeDegrees, 0, 0, longitudeCardinal));
        return;
      }
      setLongitudeSeconds(value);
      if (longitudeDegrees !== null && longitudeMinutes !== null
        && value !== null && longitudeCardinal !== null
      ) {
        props.setLongitude(parseFloat(
          parseDMS(longitudeDegrees, latitudeMinutes, value, longitudeCardinal),
        ).toFixed(6));
        delete newErrors.longitude;
      } else {
        props.setLongitude('');
      }
    }
    props.setErrors(newErrors);
  };

  const setCardinal = (value, type = 'lat') => {
    const newErrors = props.errors;
    if (type === 'lat') {
      setLatitudeCardinal(value);
      if (latitudeDegrees !== null && latitudeMinutes !== null
        && latitudeSeconds !== null && value !== null
      ) {
        props.setLatitude(parseFloat(
          parseDMS(latitudeDegrees, latitudeMinutes, latitudeSeconds, value),
        ).toFixed(6));
        delete newErrors.latitude;
      } else {
        props.setLatitude('');
      }
    }

    if (type === 'long') {
      setLongitudeCardinal(value);
      if (longitudeDegrees !== null && longitudeMinutes !== null
        && longitudeSeconds !== null && value !== null
      ) {
        props.setLongitude(parseFloat(
          parseDMS(longitudeDegrees, latitudeMinutes, longitudeSeconds, value),
        ).toFixed(6));
        delete newErrors.longitude;
      } else {
        props.setLongitude('');
      }
    }
    props.setErrors(newErrors);
  };

  const setNewLatitude = (value) => {
    const parsedValue = parseFloat(value);
    const newErrors = props.errors;
    if (parsedValue < -90 || parsedValue > 90) {
      newErrors.latitude = t('validations.latitudeInvalidNumber');
      props.setErrors(newErrors);
      props.setLatitude('');
      setLatitudeDegrees('');
      setLatitudeMinutes(null);
      setLatitudeSeconds(null);
      setLatitudeCardinal(null);
    } else {
      props.setLatitude(value || '');
      if (value === '') {
        newErrors.latitude = t('validations.required');
      } else {
        delete newErrors.latitude;
      }
      props.setErrors(newErrors);
      const DMSLatitude = getDegreesMinutesAndSeconds(value, 'lat');
      setLatitudeDegrees(DMSLatitude.degrees);
      setLatitudeMinutes(DMSLatitude.minutes);
      setLatitudeSeconds(DMSLatitude.seconds);
      setLatitudeCardinal(DMSLatitude.cardinal);
    }
  };

  const setNewLongitude = (value) => {
    const parsedValue = parseFloat(value);
    const newErrors = props.errors;
    if (parsedValue < -180 || parsedValue > 180) {
      newErrors.longitude = t('validations.longitudeInvalidNumber');
      props.setErrors(newErrors);
      props.setLongitude('');
      setLongitudeDegrees('');
      setLongitudeMinutes(null);
      setLongitudeSeconds(null);
      setLongitudeCardinal(null);
    } else {
      props.setLongitude(value || '');
      if (value === '') {
        newErrors.longitude = t('validations.required');
      } else {
        delete newErrors.longitude;
      }
      props.setErrors(newErrors);
      const DMSLongitude = getDegreesMinutesAndSeconds(value, 'long');
      setLongitudeDegrees(DMSLongitude.degrees);
      setLongitudeMinutes(DMSLongitude.minutes);
      setLongitudeSeconds(DMSLongitude.seconds);
      setLongitudeCardinal(DMSLongitude.cardinal);
    }
  };

  return (
    <div>
      {!props.editMode && (
        <>
          <div className="report-view-map-section-header">
            <div className="report-view__container-body-info-section-title">
              {t('reportLocation')}
            </div>
          </div>
          <div className="report-view-map-body">
            <div className="report-view-map-body-coordinates">
              <div className="report-view-map-body-coordinates-title">
                {t('reportCoordinates')}
              </div>
              <div className="report-view-map-body-coordinates-label">
                DD:
                {' '}
                {formatDMS()}
                <div
                  onClick={copyDMS}
                  role="button"
                  tabIndex={-9999}
                  onKeyDown={copyDMS}
                  className="report-view-map-body-coordinates-label-copyclick"
                >
                  <CopyIcon />
                  {' '}
                  {t('copyDMM')}
                </div>
              </div>
              <div className="report-view-map-body-coordinates-label">
                DMS:
                {' '}
                {formatDMM()}
                <div
                  onClick={copyDMM}
                  role="button"
                  tabIndex={-9999}
                  onKeyDown={copyDMS}
                  className="report-view-map-body-coordinates-label-copyclick"
                >
                  <CopyIcon />
                  {' '}
                  {t('copyDMS')}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <Map
        style="mapbox://styles/mapbox/basic-v9"
        containerStyle={{ height: '376px', width: '100%', marginTop: '30px' }}
        center={(!!props.longitude && !!props.latitude) && [props.longitude, props.latitude]}
        onClick={(map, e) => onClickMap(map, e)}
        onStyleLoad={(map) => { map.resize(); }}
      >
        {(!!props.latitude && !!props.longitude) && <Marker className="map__marker" coordinates={[props.longitude, props.latitude]} />}
      </Map>

      {props.editMode && (
        <div className="create-report__map--coordinates">
          <div className="create-report__map--coordinates-container">
            <TextInput
              title={t('latitude')}
              value={props.latitude}
              type="number"
              placeholder={t('reportLatitudePlaceholder')}
              handleChange={(event) => setNewLatitude(event.target.value)}
              hasError={!!props.errors.latitude}
              errorDescription={props.errors.latitude}
            />
            <TextInput
              title={t('longitude')}
              value={props.longitude}
              type="number"
              placeholder={t('reportLongitudePlaceholder')}
              handleChange={(event) => { setNewLongitude(event.target.value); }}
              hasError={!!props.errors.longitude}
              errorDescription={props.errors.longitude}
            />
          </div>
          <span> OR </span>
          <div className="create-report__map--coordinates-containerdms">
            <div className="create-report__map--coordinates-containerdms-subcontainer create-report__map--coordinates-containerdms-subcontainer-edit">
              <TextInput
                hasTitle={false}
                type="number"
                value={latitudeDegrees}
                placeholder="Deg"
                hasError={!!props.errors.latitude}
                handleChange={(event) => setDegrees(event.target.value, 'lat')}
              />
              <SelectBox
                hasError={!!props.errors.latitude}
                value={latitudeMinutes}
                options={minuteOptions}
                placeholder="Min"
                handleChange={(e, { value }) => setCoordMins(value, 'lat')}
              />
              <SelectBox
                type="number"
                hasError={!!props.errors.latitude}
                value={latitudeSeconds}
                options={secondsOptions}
                placeholder="Sec"
                handleChange={(e, { value }) => setCoordSecs(value, 'lat')}
              />
              <SelectBox
                options={[{ text: 'North', value: 'N', key: 'North' }, { text: 'South', value: 'S', key: 'South' }]}
                handleChange={(e, { value }) => setCardinal(value, 'lat')}
                placeholder={t('direction')}
                value={latitudeCardinal}
              />
              <TextInput
                hasTitle={false}
                type="number"
                placeholder="Deg"
                value={longitudeDegrees}
                hasError={!!props.errors.longitude}
                customInputClass="create-report__map--coordinates-containerdms-subcontainer-input"
                handleChange={(event) => setDegrees(event.target.value, 'long')}
              />
              <SelectBox
                options={minuteOptions}
                value={longitudeMinutes}
                placeholder="Min"
                hasError={!!props.errors.longitude}
                handleChange={(e, { value }) => setCoordMins(value, 'long')}
              />
              <SelectBox
                value={longitudeSeconds}
                hasError={!!props.errors.longitude}
                placeholder="Sec"
                options={secondsOptions}
                handleChange={(e, { value }) => setCoordSecs(value, 'long')}
              />
              <SelectBox
                options={[{ text: 'East', value: 'E', key: 'East' }, { text: 'West', value: 'W', key: 'West' }]}
                handleChange={(e, { value }) => setCardinal(value, 'long')}
                value={longitudeCardinal}
                placeholder={t('direction')}
                customInputClass="create-report__map--coordinates-containerdms-subcontainer-select"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
ReportMap.propTypes = {
  longitude: types.string.isRequired,
  latitude: types.string.isRequired,
  errors: types.object.isRequired,
  setErrors: types.func.isRequired,
  setLatitude: types.func.isRequired,
  setLongitude: types.func.isRequired,
  editMode: types.bool.isRequired,
  addSuccessAlert: types.func.isRequired,
};
export default connect(null, mapDispatchToProps)(ReportMap);

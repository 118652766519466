import React from 'react';
import t from 'prop-types';
import clsx from 'clsx';
import '../../../styles/index.scss';
import { ReactComponent as FolderIcon } from '../../../assets/images/folder_icon.svg';
import { ReactComponent as EditIcon } from '../../../assets/images/edit.svg';

function FolderElement({
  title, color, onClick, selected,
}) {
  return (
    <div
      role="button"
      tabIndex={0}
      className={clsx(
        'folder-element__wrapper',
        {
          'folder-element__wrapper-selected': selected,
        },
      )}
      onKeyDown={selected ? () => {} : () => onClick()}
      onClick={selected ? () => {} : () => onClick()}
    >
      <FolderIcon className="folder-element__icon" style={{ fill: `${color}` }} />
      <div className="folder-element__title">
        {title}
      </div>
    </div>
  );
}

FolderElement.propTypes = {
  title: t.string.isRequired,
  color: t.string.isRequired,
  onClick: t.func.isRequired,
  selected: t.bool.isRequired,
};
export default FolderElement;

import { format } from 'date-fns';
import {
  ADD_STORY, DELETE_STORY, GET_STORIES, UPDATE_STORY,
} from '../types';
import i18n from '../translations/i18n';
import { addNewAlert, closeLoader, openLoader } from './CommonActions';
import { ALERT_ERROR, ALERT_SUCCESS } from '../shared/consts';
import { getRandomTint, sleep } from '../utils';
import coverExample from '../assets/images/placeholder-story.png';
import { history } from '../index';

const stories = [
  {
    uuid: 'aaa-bbb-ccc-ddda',
    coverImage: coverExample,
    title: 'Sustainable Farming Activites',
    subtitle: 'TIMBY monitors have been reporting on illegal activity',
    status: 'Published',
    bodyContent: [],
    files: [],
    date: '2020-04-10 22:42:05',
  },
  {
    uuid: 'aaa-bbb-ccc-dddb',
    coverImage: coverExample,
    title: 'Sustainable Fishing Activites',
    subtitle: 'TIMBY monitors have been reporting on illegal activity',
    status: 'Unpublished',
    bodyContent: [],
    files: [],
    date: '2020-04-11 22:42:05',
  },
  {
    uuid: 'aaa-bbb-ccc-dddc',
    coverImage: coverExample,
    title: 'Sustainable Mining Activites',
    subtitle: 'TIMBY monitors have been reporting on illegal activity',
    status: 'Unpublished',
    bodyContent: [],
    files: [],
    date: '2020-04-11 22:42:05',
  },
  {
    uuid: 'aaa-bbb-ccc-dddd',
    coverImage: coverExample,
    title: 'Sustainable Aerial Activites',
    subtitle: 'TIMBY monitors have been reporting on illegal activity',
    status: 'Unpublished',
    bodyContent: [],
    files: [],
    date: '2020-04-11 22:42:05',
  },
];

export const getStories = () => async (dispatch, getState) => {
  const { user } = getState();
  const { token } = user;

  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    dispatch(openLoader());
    const response = sleep(2000);
    // await fetch(`${endpoints.getStories(admin.selectedOrganizationId)}, options)
    /* const { error, data, status } = await response.json() */
    if (false) {
      /* if (status !== 200 && error && error.description) {
        dispatch(addNewAlert(error.description, ALERT_ERROR))
      } */
    } else {
      const storiesWithColor = stories.map((elem) => ({ ...elem, color: getRandomTint() }));
      dispatch({ type: GET_STORIES, payload: { stories: storiesWithColor } });
    }
  } catch (e) {
    console.warn(e);
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
  } finally {
    dispatch(closeLoader());
  }

  return true;
};

export const getSelectedStory = (storyId) => async (dispatch, getState) => {
  const { stories } = getState();
  const storiesList = stories.stories;
  const selectedStory = storiesList.find((elem) => (elem.uuid.toString() === storyId.toString()));

  if (!selectedStory) {
    history.push({ pathname: '/stories', from: history.location.pathname });
  }

  return selectedStory;
};

export const createNewStory = (storyPayload) => async (dispatch, getState) => {
  const { user } = getState();
  const { token } = user;

  const formData = new FormData();

  const errors = [];
  if (storyPayload.title === '') errors.title = i18n.t('validations.required');
  if (storyPayload.title.length > 100) errors.title = i18n.t('validations.storyTitle');
  if (storyPayload.subtitle === '') errors.subtitle = i18n.t('validations.required');
  if (storyPayload.subtitle.length > 100) errors.title = i18n.t('validations.storySubtitle');

  // Optional Fields Errors
  const textFields = storyPayload.bodyContent.filter((elem) => elem.type === 'text');

  textFields.map((elem) => {
    if (elem.textInput === '') errors[elem.id] = i18n.t('validations.required');
    if (elem.textInput.length > 2000) errors[elem.id] = i18n.t('validations.storyTextField');
    return null;
  });

  if (Object.keys(errors).length > 0) {
    //dispatch(addNewAlert('Please fill up the fields', ALERT_ERROR));
    return { success: false, errors };
  }

  formData.append('title', storyPayload.title);
  formData.append('subtitle', storyPayload.subtitle);
  formData.append('status', storyPayload.status);
  formData.append('date', format(new Date(), 'Y-L-dd H:m:s'));
  const { bodyContent } = storyPayload;
  bodyContent.map((content) => formData.append('body_content[]', content));

  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: formData,
  };

  try {
    dispatch(openLoader());
    const response = await sleep(1500);
    // fetch(endpoints.createStory(admin.selectedOrganizationId), options)
    /* const { error, status } = await response.json() */
    if (false) {
      /*
      if (status !== 200 && error && error.description) {
        dispatch(addNewAlert(error.description, ALERT_ERROR))
      }
      */
    } else {
      const responsePayload = storyPayload;
      dispatch(addNewAlert(i18n.t('notifications.storyCreated'), ALERT_SUCCESS));
      responsePayload.date = format(new Date(), 'Y-L-dd H:m:s');
      responsePayload.uuid = (Math.floor(Math.random() * 10000)).toString();
      responsePayload.color = getRandomTint();
      if (responsePayload.coverImage && responsePayload.coverImage?.path) {
        responsePayload.coverImage = URL.createObjectURL(responsePayload.coverImage);
      }
      dispatch({ type: ADD_STORY, payload: responsePayload });
    }
  } catch (e) {
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
    console.warn(e);
  } finally {
    dispatch(closeLoader());
  }

  return { success: true, errors: [] };
};

export const updateStory = (storyPayload) => async (dispatch, getState) => {
  const { user } = getState();
  const { token } = user;

  const errors = [];
  if (storyPayload.title === '') errors.title = i18n.t('validations.required');
  if (storyPayload.title.length > 100) errors.title = i18n.t('validations.storyTitle');
  if (storyPayload.subtitle === '') errors.subtitle = i18n.t('validations.required');
  if (storyPayload.subtitle.length > 100) errors.title = i18n.t('validations.storySubtitle');

  const textFields = storyPayload.bodyContent.filter((elem) => elem.type === 'text');

  textFields.map((elem) => {
    if (elem.textInput === '') errors[elem.id] = i18n.t('validations.required');
    if (elem.textInput.length > 2000) errors[elem.id] = i18n.t('validations.storyTextField');
    return null;
  });
  if (Object.keys(errors).length > 0) {
    //dispatch(addNewAlert('Please fill up the fields', ALERT_ERROR));
    return { success: false, errors };
  }

  const formData = new FormData();

  formData.append('title', storyPayload.title);
  formData.append('subtitle', storyPayload.subtitle);
  formData.append('status', storyPayload.status);
  formData.append('date', format(new Date(), 'Y-L-dd H:m:s'));
  const { bodyContent } = storyPayload;
  bodyContent.map((content) => formData.append('body_content[]', content));

  const options = {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: formData,
  };

  try {
    dispatch(openLoader());
    const response = await sleep(1500);
    // fetch(endpoints.alterStory(admin.selectedOrganizationId), options)
    /* const { error, status } = await response.json() */
    if (false) {
      /*
      if (status !== 200 && error && error.description) {
        dispatch(addNewAlert(error.description, ALERT_ERROR))
      }
      */
    } else {
      const responseStory = storyPayload;
      dispatch(addNewAlert(i18n.t('notifications.storyUpdated'), ALERT_SUCCESS));
      responseStory.date = format(new Date(), 'Y-L-dd H:m:s');
      if (responseStory.coverImage && responseStory.coverImage?.path) {
        responseStory.coverImage = URL.createObjectURL(responseStory.coverImage);
      }
      dispatch({ type: UPDATE_STORY, payload: responseStory });
    }
  } catch (e) {
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
    console.warn(e);
  } finally {
    dispatch(closeLoader());
  }

  return { success: true, errors: [] };
};

export const deleteStory = (storyId) => async (dispatch, getState) => {
  const { user } = getState();
  const { token } = user;

  const formData = new FormData();

  const options = {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: formData,
  };

  try {
    dispatch(openLoader());
    const response = await sleep(1500);
    // fetch(endpoints.alterStory(admin.selectedOrganizationId, storyId), options)
    /* const { error, status } = await response.json() */
    if (false) {
      /*
      if (status !== 200 && error && error.description) {
        dispatch(addNewAlert(error.description, ALERT_ERROR))
      }
      */
    } else {
      dispatch(addNewAlert(i18n.t('notifications.storyDeleted'), ALERT_SUCCESS));
      dispatch({ type: DELETE_STORY, payload: storyId });
      history.push('/stories');
    }
  } catch (e) {
    console.warn(e);
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
    return false;
  } finally {
    dispatch(closeLoader());
  }

  return true;
};

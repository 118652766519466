import '../../../styles/index.scss';

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dashboardHoc from '../DashboardHoc/DashboardHoc';
import FullHeader from '../../ui/FullHeader/FullHeader';
import SelectBox from '../../ui/SelectBox/SelectBox';
import mapDispatchToProps from '../../../actions/index';
import { isUserAdmin, isUserOrganizationAdmin } from '../../../utils';
import AdminTabs from '../AdminTabs/AdminTabs';
import AdminTags from '../AdminTags/AdminTags';
import AdminUsersList from '../AdminUsersList/AdminUsersList';
import AdminOrganizationsList from '../AdminOrganizationsList/AdminOrganizationsList';
import AdminLayers from '../AdminLayers/AdminLayers';
import AdminExportReport from '../AdminExportReport/AdminExportReport';
import AdminSettings from '../AdminSettings/AdminSettings';

function AdminContainer(props) {
  const { t } = useTranslation();
  let location = useLocation();
  const [currentPage, setCurrentPage] = useState(location.pathname);
  const [activeTab, setActiveTab] = useState(null);

  const pages = [
    'tags', 'users', 'organizations', 'layers', 'export-reports', 'settings'
  ];

  const organizationOptions = props?.organizations?.map(
    (elem) => ({
      key: elem.id,
      value: elem.id,
      text: elem.name
    })
  );

  const handleChange = (value) => {
    props.updateSelectedOrganization(value);
  };

  /* Effects */
  useEffect(() => {
    if (isUserAdmin(props.userInfo)) props.getOrganizations();
    if (isUserAdmin(props.userInfo) || isUserOrganizationAdmin(props.userInfo)) props.getRoles();
  }, []);

  useEffect(() => {
    setCurrentPage(location.pathname);
    setActiveTab(pages.find(el => currentPage.indexOf(el) !== -1));
  }, [location]);

  // Displaying Pages
  const getCurrentPanel = () => {
    switch (activeTab) {
      case 'users':
        return (<AdminUsersList/>);
      case 'organizations':
        return (<AdminOrganizationsList/>);
      case 'layers':
        return (<AdminLayers/>);
      case 'export-reports':
        return (<AdminExportReport/>);
      case 'settings':
        return (<AdminSettings currentPage={currentPage}/>);
      case 'tags':
      default:
        return (<AdminTags/>);
    }
  };

  return (
    <>
      <div className="admin-container__wrapper">
        <FullHeader title={t('admin')}>
          {isUserAdmin(props.userInfo) && (
            <SelectBox
              medium
              disabled={props.history.location.pathname.startsWith('/admin/organizations')}
              options={organizationOptions}
              value={props.selectedOrganizationId}
              handleChange={(e, { value }) => handleChange(value)}
            />
          )}
        </FullHeader>

        <AdminTabs activeTab={activeTab} currentPage={currentPage}/>
        {getCurrentPanel()}
      </div>
    </>
  );
}

const mapStateToProps = ({ admin, user }) => ({
  organizations: admin.organizations,
  selectedOrganizationId: admin.selectedOrganizationId,
  userInfo: user.userInfo,
});
export default dashboardHoc(connect(mapStateToProps, mapDispatchToProps)(AdminContainer));

import '../../../styles/index.scss';
import React, { useEffect, useState } from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Icon } from 'semantic-ui-react';
import clsx from 'clsx';
import Collapsible from '../../ui/Collapsible/Collapsible';
import AdminUserListContent from './AdminUserListContent';
import AdminModal from '../AdminModal/AdminModal';
import mapDispatchToProps from '../../../actions/index';
import { ReactComponent as EditIcon } from '../../../assets/images/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/images/delete.svg';
import { ReactComponent as CloseIcon } from '../../../assets/images/close.svg';
import RoundedIcon from '../../shared/RoundedIcon/RoundedIcon';
import DeleteModal from '../DeleteModal/DeleteModal';

const AdminUsersList = (props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  useEffect(() => {
    document.title = 'Timby - Users';
  }, []);

  useEffect(() => {
    props.getUsers();
  }, [props.selectedOrganizationId]);

  const handleDeletePopup = (elem) => {
    setItemToDelete(elem.username);
    setOpenDelete(true);
  };

  const deleteUser = async () => {
    const result = await props.deleteUser(itemToDelete);
    if (result.success) setOpenDelete(false);
  };

  const renderContent = (elem, editMode, editHandler) => (
    <AdminUserListContent edit={editMode} userInfo={elem} roles={props.roles} onClose={editHandler} />
  );

  const renderHeader = (elem, editHandler, editableMode) => (
    <div className={clsx('collapsible__header', {
      'collapsible__header-active': editableMode,
    })}
    >
      <div
        role="button"
        tabIndex={-999}
        onKeyDown={editHandler}
        onClick={editHandler}
        className="collapsible__header__text"
      >
        <Icon name="chevron right" />
        {elem.username}
      </div>
      <div className="collapsible__actions">
        <RoundedIcon
          SvgComponent={editableMode ? CloseIcon : EditIcon}
          hover
          border
          handler={editHandler}
        />
        <RoundedIcon
          SvgComponent={DeleteIcon}
          hover
          border
          disabled={elem?.role?.name === 'Administrator'}
          handler={() => handleDeletePopup(elem)}
        />
      </div>
    </div>
  );

  return (
    <div className="admin-container__content whitepagescrollbar">
      <div className="max-body-width">
        <div className="admin-container-header">
          <h1 className="admin-container-header-title">
            {t('users')}
          </h1>
          <div className="admin-container-header-buttons">
            <AdminModal
              actionName={t('addNewUser')}
              onClose={() => setOpen(false)}
              open={open}
              onOpen={() => setOpen(true)}
              header={t('createUser')}
            >
              <AdminUserListContent
                organizations={props.organizations}
                roles={props.roles}
                edit={false}
                onClose={() => setOpen(false)}
              />
            </AdminModal>
            <DeleteModal
              onClose={() => setOpenDelete(false)}
              open={openDelete}
              onOpen={() => setOpenDelete(true)}
              hasTrigger={false}
              header={t('deleteUser')}
              subtitle={t('deleteUserDescription')}
              deleteAction={() => deleteUser()}
            />
          </div>
        </div>
        <div className="admin-container-body">
          <Collapsible
            bordered
            editableMode
            elements={props?.usersList}
            renderHeader={renderHeader}
            renderContent={renderContent}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ admin }) => ({
  usersList: admin.users,
  organizations: admin.organizations,
  roles: admin.roles,
  selectedOrganizationId: admin.selectedOrganizationId,
});

AdminUsersList.displayName = 'AdminUsersList';

AdminUsersList.propTypes = {
  roles: t.array.isRequired,
  usersList: t.array.isRequired,
  selectedOrganizationId: t.string.isRequired,
  organizations: t.array.isRequired,
  deleteUser: t.func.isRequired,
  getUsers: t.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminUsersList);

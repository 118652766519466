import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Toggle from '../../../ui/Toggle/Toggle';
import clsx from "clsx";
import CustomRadio from "../../../ui/CustomRadio/CustomRadio";
import TextInput from "../../../shared/TextInput/TextInput";
import MessageCustomSelect from "../../MessageForm/MessageCustomSelect/MessageCustomSelect";
import SelectBox from "../../../ui/SelectBox/SelectBox";
import CallingNumbers from "../../../../shared/callingNumbers";
import Button from "../../../shared/Button/Button";
import types from "prop-types";
import {connect} from "react-redux";
import mapDispatchToProps from "../../../../actions";

const AdminAppSettings = (props) => {
    const {t} = useTranslation();

    const [mobileToggles, setMobileToggles] = useState([
        {
            labelKey: 'anonymousSignIn', label: t('anonymousSignIn'), type: 'Toggle', value: false, id: 'anonymous-signins',
        },
        {
            labelKey: 'mapsOnMobile', label: t('mapsOnMobile'), type: 'Toggle', value: false, id: 'maps_mobile',
        },
        {
            labelKey: 'reportUpdates', label: t('reportUpdates'), type: 'Toggle', value: false, id: 'updates',
        },
    ]);

    const [lessonPathType, setLessonPathType] = useState('default');
    const [lessonCustomText, setLessonCustomText] = useState('');
    const [emergencyAlerts, setEmergencyAlerts] = useState(false);
    const [emergencyAlertNumber, setEmergencyAlertNumber] = useState('');
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [selectUsers, setSelectUsers] = useState([]);

    useEffect(() => {
        document.title = 'Timby - Additional Settings';
    }, []);

    useEffect(() => {
        const newMobileToggles = mobileToggles.map((elem) => ({
            ...elem, label: t(elem.labelKey),
        }));

        setMobileToggles(newMobileToggles);
    }, []);

    useEffect(() => {
        props.getUsers();
        props.getOrganizationOptions();
    }, [props.selectedOrganizationId]);

    useEffect(() => {
        const users = props.users.map((elem) => (
            { text: elem.username, id: elem.username, key: elem.username }
        ));
        setSelectUsers(users);
    }, [props.users]);

    const updateToggleOptions = (list, setFunction, id) => {
        const updatedToggles = list.map((elem) => {
            if (elem.id === id) {
                return { ...elem, value: !elem.value };
            }
            return elem;
        });
        setFunction(updatedToggles);
    };

    return (
        <div className="admin-settings-section">
            <div className="admin-settings-section__header">
                <h1 className="admin-settings-section__header-title">
                    {t('appConfiguration')}
                </h1>
            </div>
            <div className="admin-settings__box">
                {mobileToggles.map((elem) => (
                    <div className="admin-settings__element" key={elem.id}>
                        <Toggle
                            value={elem.value}
                            handleChange={() => {
                                updateToggleOptions(mobileToggles, setMobileToggles, elem.id);
                            }}
                            label={elem.label}
                        />
                    </div>
                ))}
                <div
                    className={clsx('admin-settings__element admin-settings-lessons', {
                        'admin-settings__element-open': lessonPathType === 'custom',
                    })}
                >
                    <div className="admin-settings__element-title">{t('lessonPath')}</div>
                    <div className="admin-settings__element-radio-groups">
                        <CustomRadio
                            label={t('default')}
                            checked={lessonPathType === 'default'}
                            onChange={() => setLessonPathType('default')}
                        />
                        <CustomRadio
                            label={t('custom')}
                            checked={lessonPathType === 'custom'}
                            onChange={() => setLessonPathType('custom')}
                        />
                    </div>
                    {lessonPathType === 'custom' && (
                        <TextInput
                            placeholder={t('enterCustomLessonPath')}
                            value={lessonCustomText}
                            hasTitle={false}
                            customInputClass="admin-settings__element-textinput"
                            handleChange={(event) => setLessonCustomText(event.target.value)}
                        />
                    )}
                </div>
                <div
                    className={clsx('admin-settings__element', {
                        'admin-settings__element-open': emergencyAlerts,
                    })}
                >
                    <Toggle
                        value={emergencyAlerts}
                        handleChange={() => setEmergencyAlerts(!emergencyAlerts)}
                        label={t('emergencyAlerts')}
                    />
                    {emergencyAlerts && (
                        <div className="admin-settings-emergency-contact">
                            <div className="admin-settings-emergency-contact__title">
                                {t('emergencyContactUser')}
                            </div>
                            <MessageCustomSelect
                                options={selectUsers}
                                selectedOptions={selectedOptions}
                                changeSelectedOptions={setSelectedOptions}
                            />
                            <div className="admin-settings-emergency-contact-elem">
                                <div className="admin-settings-emergency-contact__title">
                                    {t('emergencyContactNumber')}
                                </div>
                                <div className="admin-settings-emergency-contact__phone">
                                    <SelectBox
                                        placeholder="+44"
                                        value={null}
                                        options={CallingNumbers}
                                        search
                                        handleChange={() => {}}
                                    />
                                    <TextInput
                                        placeholder={t('contactNumber')}
                                        value={emergencyAlertNumber}
                                        hasTitle={false}
                                        handleChange={(event) => setEmergencyAlertNumber(event.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="admin-settings-emergency-contact-elem-copy">
                                <div className="add-link">{t('addAdditionalEmergencyNumber')}</div>
                                <Button className="add-button" primary medium text={t('save')} handler={() => {}} />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ admin, user, }) => ({
    selectedOrganizationId: admin.selectedOrganizationId,
    users: admin.users,
    userInfo: user.userInfo,
    organizationOptions: admin.organizationOptions,
});

AdminAppSettings.displayName = 'AdminAppSettings';

AdminAppSettings.propTypes = {
    selectedOrganizationId: types.string.isRequired,
    users: types.array.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminAppSettings);

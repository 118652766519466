import '../../../../styles/index.scss';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import types from 'prop-types';
import { useTranslation } from 'react-i18next';
import mapDispatchToProps from '../../../../actions/index';
import Button from '../../../shared/Button/Button';
import { ReactComponent as ClipIcon } from '../../../../assets/images/clip.svg';
import { ReactComponent as PersonIcon } from '../../../../assets/images/report_user.svg';
import { ReactComponent as WarningIcon } from '../../../../assets/images/red_warning.svg';
import TextInput from '../../../shared/TextInput/TextInput';
import AdminModal from '../../AdminModal/AdminModal';
import SelectBox from '../../../ui/SelectBox/SelectBox';

function getClassFromType(type) {
  if (type.startsWith('video')) {
    return 'report-activityfeed-info-files-element-video';
  }
  if (type.startsWith('audio')) {
    return 'report-activityfeed-info-files-element-audio';
  }
  if (type.startsWith('image')) {
    return 'report-activityfeed-info-files-element-image';
  }
  if (type === 'application/pdf') {
    return 'report-activityfeed-info-files-element-pdf';
  }
  return '';
}

function ReportActivityFeedSubmit(props) {
  const { t } = useTranslation();
  const URGENCY_STATES = [
    { text: t('urgency_low'), value: 'minor', key: 'minor' },
    { text: t('urgency_medium'), value: 'moderate', key: 'moderate' },
    { text: t('urgency_high'), value: 'critical', key: 'critical' },
  ];
  const [comment, setComment] = useState('');
  const [errors, setErrors] = useState({});
  const [userModal, setUserModal] = useState(false);
  const [closeModal, setCloseModal] = useState(false);
  const [urgencyModal, setUrgencyModal] = useState(false);
  const [assignedUser, setAssignedUser] = useState(null);
  const [newUrgency, setNewUrgency] = useState(null);
  const [files, setFiles] = useState([]);
  const [fileModal, setFileModal] = useState(false);

  useEffect(() => {
    if (props.selectedReport) {
      setNewUrgency(props.selectedReport.urgency);
      setAssignedUser(props.selectedReport.assignee);
    }
  }, [props.selectedReport]);

  const onUpdateAssignee = async () => {
    const reportData = {
      assignee: assignedUser,
    };
    await props.editReport(props.selectedReport.uuid, reportData);
    setUserModal(false);
    setAssignedUser(null);
  };

  const onCloseReport = async () => {
    const reportData = {
      lifecycle: 'closed',
    };
    await props.editReport(props.selectedReport.uuid, reportData);
    setCloseModal(false);
    setAssignedUser(null);
  };

  const onUpdateUrgency = async () => {
    const reportData = {
      urgency: newUrgency,
    };
    await props.editReport(props.selectedReport.uuid, reportData);
    setUrgencyModal(false);
    setNewUrgency(null);
  };

  const onUploadFiles = (event) => {
    const { files } = event.target;
    if (files.length === 0) return;

    setFiles([...files]);
    setFileModal(true);
  };

  const onCommentReport = async () => {
    const reportData = {
      comment,
      media: files,
    };
    const response = await props.addFilesToReport(props.selectedReport.uuid, reportData);
    if (response.success) {
      setErrors({});
      setComment('');
      setFiles([]);
    } else {
      setErrors(response.errors);
    }
  };

  const updateComments = (value) => {
    const newErrors = errors;
    if (value.length > 2000) {
      newErrors.comment = t('validations.reportCommentExceeded');
      setErrors(newErrors);
    } else {
      delete newErrors.comment;
      setErrors(newErrors);
    }
    setComment(value);
  };

  return (
    <>
      <div className="report-activityfeed-submit">
        <div className="report-activityfeed-submit-box">
          <TextInput
            textarea
            placeholder={t('reportActivityPlaceholder')}
            hasTitle={false}
            value={comment}
            hasError={!!errors.comment}
            errorDescription={errors.comment}
            handleChange={(event) => { updateComments(event.target.value); }}
            customInputClass="report-activityfeed-submit-input"
          />
          <div className="report-activityfeed-submit-actions">
            <div className="report-activityfeed-submit-action-group">
              <div
                className="report-activityfeed-submit-action-button"
                role="button"
                tabIndex={-9999}
                onKeyDown={() => {
                  document.getElementById('activityUploadMedia').click();
                }}
                onClick={() => {
                  document.getElementById('activityUploadMedia').click();
                }}
              >
                <input
                  multiple
                  id="activityUploadMedia"
                  type="file"
                  className="report-activityfeed-submit-fileinput"
                  files={files}
                  onChange={(event) => { onUploadFiles(event); }}
                  accept="image/*, video/*, audio/*, .pdf"
                />
                <ClipIcon />
                {' '}
                {t('reportActivityUploadMedia')}
                {files.length > 0 && ` (${files.length})`}
              </div>
              <div
                className="report-activityfeed-submit-action-button"
                role="button"
                tabIndex={-9999}
                onClick={() => setUserModal(true)}
                onKeyDown={() => setUserModal(true)}
              >
                <PersonIcon />
                {' '}
                {t('reportActivityAssignUser')}
              </div>
              <div
                className="report-activityfeed-submit-action-button"
                role="button"
                tabIndex={-9999}
                onClick={() => setUrgencyModal(true)}
                onKeyDown={() => setUrgencyModal(true)}
              >
                <WarningIcon />
                {' '}
                {t('reportActivitySetUrgency')}
              </div>
            </div>
            <div className="report-activityfeed-submit-buttons-container">
              <div className="report-activityfeed-submit-close-button">
                <Button text={t('closeAction')} secondary medium handler={() => setCloseModal(true)} />
              </div>
              <Button text={t('updateAction')} disabled={comment === ''} primary medium handler={() => onCommentReport()} />
            </div>
          </div>
        </div>
      </div>
      <AdminModal
        hasTrigger={false}
        header={t('reportActivityAssignUser')}
        customModal="report-activityfeed-submit-modal report-activityfeed-submit-modal-mini"
        onClose={() => setUserModal(false)}
        open={userModal}
        scrolling={false}
        onOpen={() => setUserModal(true)}
      >
        <div>
          <SelectBox
            value={assignedUser}
            options={props.users.map((elem) => ({
              text: elem.username, key: elem.uuid, value: elem.uuid,
            }))}
            handleChange={(e, { value }) => setAssignedUser(value)}
            placeholder={t('chooseUser')}
          />
          <div className="report-activityfeed-submit-buttons">
            <Button text={t('cancel')} secondary medium handler={() => { setUserModal(false); setAssignedUser(null); }} />
            <Button text={t('save')} disabled={assignedUser === null} primary medium handler={() => onUpdateAssignee()} />
          </div>
        </div>
      </AdminModal>
      <AdminModal
        hasTrigger={false}
        customModal="report-activityfeed-submit-modal report-activityfeed-submit-modal-mini"
        header={t('reportActivitySetUrgency')}
        onClose={() => setUrgencyModal(false)}
        open={urgencyModal}
        scrolling={false}
        onOpen={() => setUrgencyModal(true)}
      >
        <div>
          <SelectBox
            value={newUrgency}
            options={URGENCY_STATES}
            handleChange={(e, { value }) => setNewUrgency(value)}
            placeholder={t('reportActivitySetUrgency')}
          />
          <div className="report-activityfeed-submit-buttons">
            <Button text={t('cancel')} secondary medium handler={() => setUrgencyModal(false)} />
            <Button text={t('save')} primary medium handler={() => onUpdateUrgency()} />
          </div>
        </div>
      </AdminModal>
      <AdminModal
        hasTrigger={false}
        customModal="report-activityfeed-submit-modal report-activityfeed-submit-modal-mini"
        header={t('closeReport')}
        subtitle={t('closeReportMessage')}
        onClose={() => setCloseModal(false)}
        open={closeModal}
        scrolling={false}
        onOpen={() => setCloseModal(true)}
      >
        <div>
          <div className="report-activityfeed-submit-buttons">
            <Button text={t('cancel')} secondary medium handler={() => setCloseModal(false)} />
            <Button text={t('closeAction')} primary medium handler={() => onCloseReport()} />
          </div>
        </div>
      </AdminModal>
      <AdminModal
        hasTrigger={false}
        customModal="report-activityfeed-submit-modal"
        header={t('reportActivityUploadMedia')}
        subtitle={t('reportActivityUploadMediaMessage')}
        onClose={() => setFileModal(false)}
        open={fileModal}
        scrolling={false}
        onOpen={() => setFileModal(true)}
      >
        <div className="report-activityfeed-submit-files-container">
          {files.map((elem) => (
            <div className="report-activityfeed-submit-files-info">
              <div
                style={elem.type.startsWith('image') ? { backgroundImage: `url(${URL.createObjectURL(elem)})`, backgroundSize: 'cover' } : {}}
                className={`report-activityfeed-info-files-element ${getClassFromType(elem.type)}`}
                key={`report-activityfeed-submit-files-info-${elem.uuid}`}
              />
              {elem.name}
            </div>
          ))}
        </div>
        <div className="report-activityfeed-submit-buttons">
          <Button text={t('cancel')} secondary medium handler={() => setFileModal(false)} />
          <Button text={t('ok')} primary medium handler={() => setFileModal(false)} />
        </div>
      </AdminModal>
    </>
  );
}

const mapStateToProps = ({ admin, reports }) => ({
  users: admin.users,
  selectedReport: reports.selectedReport,
});

ReportActivityFeedSubmit.propTypes = {
  users: types.array.isRequired,
  selectedReport: types.object.isRequired,
  addFilesToReport: types.func.isRequired,
  editReport: types.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportActivityFeedSubmit);

import {
  ADD_GROUP_TAG, ADD_TAG,
  UPDATE_GROUP_TAG, UPDATE_TAG,
  DELETE_GROUP_TAG, DELETE_TAG,
  GET_TAGS, APPLY_FILTERS, RESET_FILTERS, RESET_REPORTS,
} from '../types';
import i18n from '../translations/i18n';
import { endpoints } from './endpoints';
import { addNewAlert, closeLoader, logoutUserOn401, openLoader } from './CommonActions';
import { ALERT_ERROR, ALERT_SUCCESS } from '../shared/consts';

const GROUP_TAG_MAX_LENGTH = 20;
const TAG_ELEMENT_MAX_LENGTH = 40;
const TAG_TEXT_PROMPT_ELEMENT_MAX_LENGTH = 100;

export const getTags = (selectedOrganization) => async (dispatch, getState) => {
  const { user } = getState();
  const { token } = user;

  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    dispatch(openLoader());
    const response = await fetch(endpoints.getTaxonomies(selectedOrganization), options);

    const { error, data, status } = await response.json();
    const logoutResult = dispatch(logoutUserOn401(status));
    if (logoutResult) return { success: false };
    if (status !== 200 && error) {
      if (status !== 200 && error && error.description) {
        dispatch(addNewAlert(error.description, ALERT_ERROR));
      }
    } else {
      dispatch({ type: GET_TAGS, payload: { tags: data } });
    }
  } catch {
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
  } finally {
    dispatch(closeLoader());
  }

  return { success: true };
};

export const createTagGroup = (groupData) => async (dispatch, getState) => {
  const { user, admin, common } = getState();
  const { languages } = common;
  const errors = {};

  const languagesCodes = languages.map((elem) => elem.code);

  languagesCodes.map((elem) => {
    if (Object.keys(groupData.name).includes(elem)) return null;

    errors[elem] = i18n.t('validations.required');
    return null;
  });

  Object.keys(groupData.name).map((key) => {
    if (groupData.name[key].length > GROUP_TAG_MAX_LENGTH) {
      errors[key] = i18n.t('validations.taxonomyGroupNameExceeded');
    }
    return null;
  });

  if (Object.keys(errors).length > 0) {
    dispatch(addNewAlert(i18n.t('notifications.fieldValidations'), ALERT_ERROR));
    return { success: false, errors };
  }

  const { token } = user;
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(groupData),
  };

  dispatch(openLoader());
  try {
    const response = await fetch(
      endpoints.createTaxonomyGroup(admin.selectedOrganizationId), options,
    );
    const { error, status, data } = await response.json();

    const logoutResult = dispatch(logoutUserOn401(status));
    if (logoutResult) return { success: false };

    if (status !== 200 && error) {
      if (status !== 200 && error && error.description) {
        dispatch(addNewAlert(error.description, ALERT_ERROR));
      }
    } else {
      dispatch(addNewAlert(i18n.t('notifications.tagGroupCreatedSuccess'), ALERT_SUCCESS));
      groupData.id = data;
      delete groupData.name;
      dispatch({ type: ADD_GROUP_TAG, payload: groupData });
    }
  } catch (e) {
    console.warn(e);
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
    return { success: false, errors: [] };
  } finally {
    dispatch(closeLoader());
  }
  return { success: true, errors: [] };
};

export const editTagGroup = (groupData) => async (dispatch, getState) => {
  const { user, admin, common } = getState();

  const { languages } = common;
  const errors = {};

  const languagesCodes = languages.map((elem) => elem.code);
  // Validations
  languagesCodes.map((elem) => {
    if (Object.keys(groupData.name).includes(elem)) return null;

    errors[elem] = i18n.t('validations.required');
    return null;
  });

  Object.keys(groupData.name).map((key) => {
    if (groupData.name[key].length > GROUP_TAG_MAX_LENGTH) {
      errors[key] = i18n.t('validations.taxonomyGroupNameExceeded');
    }
    return null;
  });

  if (Object.keys(errors).length > 0) {
    dispatch(addNewAlert(i18n.t('notifications.fieldValidations'), ALERT_ERROR));
    return { success: false, errors };
  }
  const { token } = user;

  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(groupData),
  };

  dispatch(openLoader());
  try {
    const response = await fetch(
      endpoints.alterTaxonomyGroupElement(admin.selectedOrganizationId, groupData.id), options,
    );
    const { error, status } = await response.json();

    const logoutResult = dispatch(logoutUserOn401(status));
    if (logoutResult) return { success: false };

    if (status !== 200 && error) {
      if (status !== 200 && error && error.description) {
        dispatch(addNewAlert(error.description, ALERT_ERROR));
      }
    } else {
      dispatch(addNewAlert(i18n.t('notifications.tagGroupUpdateSuccess'), ALERT_SUCCESS));
      delete groupData.name;
      dispatch({ type: UPDATE_GROUP_TAG, payload: groupData });
    }
  } catch (e) {
    console.warn(e);
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
    return { success: false, errors: [] };
  } finally {
    dispatch(closeLoader());
  }
  return { success: true, errors: [] };
};

export const deleteTagGroup = (groupTagId) => async (dispatch, getState) => {
  const { user, admin } = getState();
  const { token } = user;

  const options = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  dispatch(openLoader());
  try {
    const response = await fetch(
      endpoints.alterTaxonomyGroupElement(admin.selectedOrganizationId, groupTagId), options,
    );
    const { error, status } = await response.json();

    const logoutResult = dispatch(logoutUserOn401(status));
    if (logoutResult) return { success: false };

    if (status !== 200 && error) {
      if (status !== 200 && error && error.description) {
        dispatch(addNewAlert(error.description, ALERT_ERROR));
        return { success: false };
      }
    } else {
      dispatch(addNewAlert(i18n.t('notifications.tagGroupDeletedSuccess'), ALERT_SUCCESS));
      dispatch({ type: DELETE_GROUP_TAG, payload: groupTagId });
    }
  } catch (e) {
    console.warn(e);
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
    return { success: false };
  } finally {
    dispatch(closeLoader());
  }
  return { success: true };
};

export const createTag = (groupTagId, tagData, type = 'single') => async (dispatch, getState) => {
  const { admin, user, common } = getState();
  const { token } = user;
  const { languages } = common;
  const errors = {};

  const languagesCodes = languages.map((elem) => elem.code);

  languagesCodes.map((elem) => {
    if (Object.keys(tagData.name).includes(elem)) return null;

    errors[elem] = i18n.t('validations.required');
    return null;
  });

  const elementMaxLength = type === 'text' ? TAG_TEXT_PROMPT_ELEMENT_MAX_LENGTH : TAG_ELEMENT_MAX_LENGTH;
  Object.keys(tagData.name).map((key) => {
    if (tagData.name[key].length > elementMaxLength) {
      errors[key] = i18n.t('validations.taxonomyChildNameExceeded', { elementMaxLength });
    }
    return null;
  });

  if (Object.keys(errors).length > 0) {
    dispatch(addNewAlert(i18n.t('notifications.fieldValidations'), ALERT_ERROR));
    return { success: false, errors };
  }

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(tagData),
  };

  dispatch(openLoader());
  try {
    const response = await fetch(
      endpoints.alterTaxonomyGroupElement(admin.selectedOrganizationId, groupTagId), options,
    );
    const { error, data, status } = await response.json();

    const logoutResult = dispatch(logoutUserOn401(status));
    if (logoutResult) return { success: false };

    if (status !== 200 && error) {
      if (status !== 200 && error && error.description) {
        dispatch(addNewAlert(error.description, ALERT_ERROR));
      }
    } else {
      dispatch(addNewAlert(i18n.t('notifications.tagOptionCreatedSuccess'), ALERT_SUCCESS));
      tagData.id = data;
      delete tagData.name;
      dispatch({ type: ADD_TAG, payload: { groupTagId, tagData } });
    }
  } catch (e) {
    console.warn(e);
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
    return { success: false, errors: [] };
  } finally {
    dispatch(closeLoader());
  }
  return { success: true, errors: [] };
};

export const editTag = (groupTagId, tagData, type = 'single') => async (dispatch, getState) => {
  const { admin, user, common } = getState();
  const { token } = user;

  const { languages } = common;
  const errors = {};

  const languagesCodes = languages.map((elem) => elem.code);

  languagesCodes.map((elem) => {
    if (Object.keys(tagData.name).includes(elem)) return null;

    errors[elem] = i18n.t('validations.required');
    return null;
  });

  const elementMaxLength = type === 'text' ? TAG_TEXT_PROMPT_ELEMENT_MAX_LENGTH : TAG_ELEMENT_MAX_LENGTH;
  Object.keys(tagData.name).map((key) => {
    if (tagData.name[key].length > elementMaxLength) {
      errors[key] = i18n.t('validations.taxonomyChildNameExceeded', { elementMaxLength });
    }
    return null;
  });

  if (Object.keys(errors).length > 0) {
    dispatch(addNewAlert(i18n.t('notifications.fieldValidations'), ALERT_ERROR));
    return { success: false, errors };
  }

  const tagId = tagData.id;

  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(tagData),
  };

  dispatch(openLoader());
  try {
    const response = await fetch(
      endpoints.alterTaxonomyChildElement(admin.selectedOrganizationId, tagId), options,
    );
    const { error, status } = await response.json();
    const logoutResult = dispatch(logoutUserOn401(status));
    if (logoutResult) return { success: false };

    if (status !== 200 && error) {
      if (status !== 200 && error && error.description) {
        dispatch(addNewAlert(error.description, ALERT_ERROR));
      }
    } else {
      dispatch(addNewAlert(i18n.t('notifications.tagOptionUpdatedSuccess'), ALERT_SUCCESS));
      delete tagData.name;
      dispatch({ type: UPDATE_TAG, payload: { groupTagId, tagData } });
    }
  } catch (e) {
    console.warn(e);
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));

    return { success: false, errors: [] };
  } finally {
    dispatch(closeLoader());
  }
  return { success: true, errors: [] };
};

export const deleteTag = (groupTagId, tagId) => async (dispatch, getState) => {
  const { admin, user } = getState();
  const { token } = user;

  const options = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  dispatch(openLoader());
  try {
    const response = await fetch(
      endpoints.alterTaxonomyChildElement(admin.selectedOrganizationId, tagId), options,
    );
    const { error, status } = await response.json();

    const logoutResult = dispatch(logoutUserOn401(status));
    if (logoutResult) return { success: false };

    if (status !== 200 && error) {
      if (status !== 200 && error && error.description) {
        dispatch(addNewAlert(error.description, ALERT_ERROR));
        return { success: false };
      }
    } else {
      dispatch(addNewAlert(i18n.t('notifications.tagOptionDeletedSuccess'), ALERT_SUCCESS));
      dispatch({ type: DELETE_TAG, payload: { groupTagId, tagId } });
    }
  } catch (e) {
    console.warn(e);
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
    return { success: false };
  } finally {
    dispatch(closeLoader());
  }
  return { success: true };
};

export const filterTaxonomies = (
  tagGroupId,
  selectedValue,
) => async (dispatch) => {
  dispatch({
    type: APPLY_FILTERS,
    payload: {
      tagGroup: tagGroupId,
      selectedValue,
    },
  });
  return true;
};

export const applyFilters = () => async (dispatch, getState) => {
  const { tags } = getState();

  try {
    dispatch(openLoader());
    dispatch({ type: RESET_REPORTS });
    dispatch({ type: APPLY_FILTERS, payload: { filters: tags.selectedTags } });
  } catch (e) {
    console.warn(e);
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
  } finally {
    dispatch(closeLoader());
  }

  return { success: true };
}

export const resetFilters = () => async (dispatch) => {

  try {
    dispatch(openLoader());
    dispatch({ type: RESET_REPORTS });
    dispatch({ type: RESET_FILTERS });
  } catch (e) {
    console.warn(e);
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
  } finally {
    dispatch(closeLoader());
  }

  return { success: true };

};
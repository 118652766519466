import React, { useRef } from 'react';
import { connect } from 'react-redux';
import t from 'prop-types';
import { format, parse } from 'date-fns';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { history } from '../../../index';
import ReportInfo from '../ReportInfo/ReportInfo';
import mapDispatchToProps from '../../../actions/index';
import { ReactComponent as LeftArrowIcon } from '../../../assets/images/arrow-left.svg';
import { REPORT_EVENT_DATE_PARSER } from '../../../shared/consts';

function ReportListContainer({
  filters,
  reports,
  selectedFolder,
  selectedReport,
  unselectFolder,
}) {
  const reportList = useRef(null);
  const { t } = useTranslation();

  const reportsToShow = selectedFolder ? selectedFolder.reports : reports;
  const search = filters ? filters.search : null;
  const total = search ? reports.length : 0;

  return (
    <>
      {selectedFolder && (
        <div
          role="button"
          tabIndex={-1}
          onKeyDown={() => { unselectFolder(); }}
          className="reports-container__report-folder"
          onClick={() => { unselectFolder(); }}
        >
          <LeftArrowIcon className="reports-container__report-folder-goback" />
          {' '}
          <div className="reports-container__report-folder-text">{selectedFolder.name}</div>
        </div>
      )}
      {search && (
        <div className="reports-container__search-count">
          <div
            className="reports-container__search-count-text"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={
              {
                __html: t('searchResults', {
                  count: total,
                  interpolation: { escapeValue: false },
                  search: search,
                  total,
                }),
              }
            }
          />
        </div>
      )}
      <div
        className={clsx(
          'reports-container__report-list defaultscrollbar',
          {
            'reports-container__report-list-selectedfolder': selectedFolder,
            'reports-container__report-list-search': !!search,
          },
        )}
        ref={(event) => { reportList.current = event; }}
      >
        {(reportsToShow.map((elem) => {
          const reportDate = parse(elem.event_date, REPORT_EVENT_DATE_PARSER, new Date());
          let formattedDate = format(reportDate, 'MMM dd');
          if (reportDate.getFullYear() < new Date().getFullYear()) {
            formattedDate = format(reportDate, 'yyyy MMM dd');
          }
          return (
            <ReportInfo
              key={`report-${elem.uuid}`}
              verified={!!parseInt(elem.verified, 10)}
              new={typeof elem.new === 'boolean' ? elem.new : true}
              hasSelectedStyle={selectedReport?.uuid === elem.uuid}
              title={elem.title}
              description={elem.content}
              date={formattedDate}
              onClick={() => history.push({ pathname: `/report/${elem.uuid}`, from: history.location.pathname })}
            />
          );
        }))}
      </div>
    </>
  );
}

const mapStateToProps = ({ reports, common, folders }) => ({
  defaultLanguage: common.defaultLanguage,
  filters: reports.filters,
  reports: reports.reports,
  selectedFolder: folders.selectedFolder,
  selectedReport: reports.selectedReport,
});

ReportListContainer.propTypes = {
  filters: t.shape({
    search: t.string,
  }),
  reports: t.array.isRequired,
  selectedFolder: t.object,
  selectedReport: t.object,
  unselectFolder: t.func.isRequired,
};

ReportListContainer.defaultProps = {
  selectedReport: null,
  selectedFolder: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportListContainer);

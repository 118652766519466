import '../../../../styles/index.scss';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import TextInput from '../../../shared/TextInput/TextInput';
import mapDispatchToProps from '../../../../actions/index';
import Button from '../../../shared/Button/Button';

function AdminTagCreateForm({
  onClose, languages, createTag, groupId, groupType
}) {
  const { t } = useTranslation();
  const [errors, setErrors] = useState({});
  const [tagTranslation, setTagTranslation] = useState(
    languages.map((elem) => ({
      value: '', text: elem.text, code: elem.code, key: `name_${elem.code}`,
    })),
  );

  const handleTagTranslation = (value, index) => {
    const updatedTranslation = [...tagTranslation];
    updatedTranslation[index].value = value;
    setTagTranslation(updatedTranslation);
  };

  useEffect(() => {
    setTagTranslation(languages.map((elem) => ({
      value: '', text: elem.text, code: elem.code, key: `name_${elem.code}`,
    })));
  }, [languages]);

  const getFilledTranslations = () => {
    const translationsHash = { name: {} };
    const filledTranslations = tagTranslation.filter((elem) => elem.value !== '');
    filledTranslations.map((elem) => {
      translationsHash[elem.key] = elem.value;
      return (translationsHash.name[elem.code] = elem.value);
    });

    return translationsHash;
  };

  const handleCreateTag = async () => {
    const tagData = getFilledTranslations();
    // Do action
    const result = await createTag(groupId, tagData, groupType);
    if (result.success) {
      setErrors({});
      onClose();
    } else {
      setErrors(result.errors);
    }
  };

  return (
    <div className="admin_tagsgroups-form__wrapper">
      {tagTranslation.map((elem, index) => (
        <TextInput
          title={`${t('name')} - ${elem.text}`}
          placeholder={`${t('enterNameIn')} - ${elem.text}`}
          value={elem.value}
          key={`newtagelement-${elem.code}`}
          hasError={!!errors[elem.code]}
          errorDescription={errors[elem.code]}
          handleChange={(event) => handleTagTranslation(event.target.value, index)}
        />
      ))}
      <div className="admin_tagsgroups-form__buttons">
        <Button
          text={t('cancel')}
          secondary
          medium
          handler={onClose}
        />
        <Button
          text={t('save')}
          primary
          medium
          handler={handleCreateTag}
        />
      </div>
    </div>
  );
}
const mapStateToProps = ({ common }) => ({
  languages: common.languages,
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminTagCreateForm);

export default {
  login: 'Autenticação',
  username: 'Nome do usuário',
  password: 'Senha',
  email: 'E-mail',
  firstname: 'Primeiro nome',
  lastname: 'Sobrenome',
  role: 'Função',
  passwordPlaceholder: 'Digite a senha',
  usernamePlaceholder: 'Enter username',
  signin: 'Entrar',
  forgotMyPassword: 'Esqueci a minha senha',
  requirements: 'Requisitos',
  resetPassword: 'Redefinição de senha',
  resetPasswordSubtitle: 'Vamos enviar-lhe um link de redefinição de senha',
  resetPasswordSubmit: 'Envie-me um link de redefinição de senha',
  resetLinkSent: 'Enviado link de redefinição',
  resetLinkSubtitle: 'Enviamos um link para redefinir sua senha para o e-mail associado à sua conta.',
  resetLinkSubmit: 'Envie-me o link novamente',
  createNewPassword: 'Criar nova senha',
  passwordReq1: 'Pelo menos 7 caractéres',
  passwordReq2: 'Contém 1 letra maiúscula',
  passwordReq3: 'Contém 1 número',
  passwordReq4: 'Contém 1 carcter especial',
  passwordReq5: 'Contém 1 letra minúscula',
  newPassword: 'Nova Senha',
  newPasswordPlaceholder: 'Insira a nova senha',
  reEnterNewPassword: 'Re-digite a nova senha',
  reEnterNewPasswordPlaceholder: 'Re-introduza a nova palavra-passe',
  createNewPasswordSubmit: 'Mudar minha senha',
  twoFactorAuth: 'Insira o endereço de e-mail',
  twoFactorAuthSubtitle: 'Nós usamos autenticação de 2 passos para garantir que a nossa plataforma é segura',
  twoFactorAuthSubmit: 'Envie-me um código de verificação',
  twoFactorCode: 'Digite o código de verificação',
  twoFactorCodeSubtitle: 'Enviamos um código de verificação para o endereço de e-mail associado a esta conta.',
  enterCode: 'Digite o código',
  enterCodePlaceholder: 'Digite o código de 6 dígitos',
  twoFactorCodeSubmit: 'Confirme',
  twoFactorCodeLink: 'Reenviar o meu código',
  twoFactorCodeCheckbox: 'Não me pergunte pelo 2FA novamente',
  reportsTitle: 'Relatórios',
  newReport: 'Novo Relatório',
  admin: 'Administrador',
  tags: 'Tag',
  users: 'Comercial',
  exportreport: 'Relatórios de exportação',
  profile: 'Perfil',
  terminology: 'Terminologia',
  mysettings: 'Minhas configurações',
  edit: 'Editar',
  organizations: 'Organizações',
  analytics: 'Analítica',
  analyticsTitle: 'Foco da semana',
  analyticsSubmissions: 'Submissões',
  analyticsReportByCat: 'Principais relatórios por categoria',
  analyticsFromLastWeek: 'desde a semana passada',
  analyticsRepSubmited: 'Relatórios submetidos',
  analyticsRepVerified: 'Relatórios verificados',
  analyticsIssuesResp: 'Questões respondidas',
  analyticsStoriesCreated: 'Histórias criadas',
  analyticsGeographicPrio: 'Prioridades Geográficas',
  analyticsPopularKeywords: 'Palavras-chave populares',
  analyticsKeyword: 'Palavra-chave',
  analyticsMentions: 'Menções',
  analyticsUsertypes: 'Tipos de usuários',
  analyticsTopUsers: 'Principais Usuários',
  analyticsUsers: 'Usuários',
  analyticsActivity: 'Actividade',
  contactUsers: 'Contactar Utilizadores',
  send: 'Mandar',
  sendToEveryone: 'Enviar a todos',
  sendSpecificUsers: 'Enviar mensagem para usuários específicos',
  selectUsers: 'Selecione usuários',
  enterMessage: 'Digite aqui sua mensagem',
  search: 'Procurar',
  addNew: 'Adicionar novo',
  save: 'Salve',
  ok: 'Ok',
  enterFirstName: 'Introduza o primeiro nome',
  enterLastName: 'Insira o último nome',
  enterEmailPlaceholder: 'Insira o endereço de e-mail',
  createReport: 'Criar relatório',
  reportDetails: 'Detalhes do relatório',
  reportTitleLabel: 'Título do relatório',
  reportTitlePlaceholder: 'Digite o título do relatório',
  reportDescriptionLabel: 'Descrição do relatório',
  reportDescriptionPlaceholder: 'Digite o Relatório Descrição',
  reportAuthorLabel: 'Selecione Autor',
  reportAuthorPlaceholder: 'Selecione o Autor',
  reportHour: 'Hora',
  reportMinute: 'Minuto',
  reportMapLabel: 'Selecione no mapa',
  reportAddMedia: 'Adicionar mídia',
  reportUploadMedia: 'Upload de fotos, vídeos, áudio ou PDF',
  reportDragMedia: 'Arraste e solte arquivos de mídia aqui',
  reportSupportMedia: 'Suportamos fotos, vídeos, áudio ou PDFs',
  reportBrowseMedia: 'Navegar',
  reportUploadFiles: 'Arquivos enviados',
  reportPublic: 'Público',
  reportSeeActivity: 'Ver atividade',
  reportUnverified: 'Não verificado',
  reportVerified: 'Verificado',
  reportVerify: 'Verificar',
  reportSearch: 'Os relatórios de pesquisa',
  reportedBy: 'Reportado por',
  reportWhatHappening: 'O que está acontecendo',
  reportLocation: 'Localização',
  reportCoordinates: 'Coordenadas',
  copyDMS: 'Cópia DMS',
  copyDMM: 'Cópia DD',
  reportImageEvidence: 'Evidência imagem',
  reportImageDescription: 'Descrição da imagem',
  reportSaveClose: 'Salvar e fechar',
  reportAudioEvidence: 'Evidência de áudio',
  reportPDFEvidence: 'Uploads PDF',
  reportVideoEvidence: 'Provas de vídeo',
  reportVideoDescription: 'Descrição do vídeo',
  reportUpdates: 'Atualizações',
  reportActivityUploadMedia: 'Upload de media',
  reportActivityAssignUser: 'Asignar utilizador',
  reportActivitySetUrgency: 'Atribuir Urgência',
  reportActivityPlaceholder: 'Adicione um comentário ou mídia adicional a este relatório',
  updateAction: 'Atualizar',
  editReport: 'Editar relatório',
  goBack: 'Voltar',
  reportCritical: 'Este relatório é crítico',
  reportVeryUrgent: 'Este relatório é muito urgente',
  reportUrgent: 'Este relatório é urgente',
  reportFilter: 'Filtro',
  reportStatus: 'Status',
  reportFolders: 'Pastas',
  urgent: 'Urgente',
  reportDateCreated: 'Data de criação',
  reportAssignment: 'Tarefa',
  reportAssignee: 'Cessionário',
  reportAuthor: 'Autor',
  reportDateCreatedPlaceholder: 'Selecione a data',
  reportDateLabel: 'Quando o evento aconteceu?',
  reportDatePlaceholder: 'Insira a data do relatório',
  reportMapTitle: 'Onde aconteceu',
  reportDMSCoordinate: 'Coordenadas DMS',
  reportDMMCoordinate: 'Coordenadas DMM',
  contactRecent: 'Notificações Recentes',
  storyTitle: 'Criar conteúdo',
  storyCreate: 'Criar história',
  storySearch: 'Pesquisar conteúdo',
  published: 'Publicado',
  updated: 'Atualizado',
  publishedOn: 'Publicado em',
  storyType: 'Tipo de história',
  unpublish: 'Despublicar',
  publish: 'Publicar',
  unpublished: 'Não publicado',
  readFullReport: 'Leia o relatório completo',
  coverImage: 'Imagem de capa',
  storyTextContent: 'Conteúdo de Texto',
  storyTitlePlaceholder: 'Insira o título aqui',
  storySubtitlePlaceholder: 'Insira o subtitulo aqui',
  closeReport: 'Fechar relatório',
  storyAddNew: 'Adicionar nova caixa de texto',
  saveChanges: 'Salvar alterações',
  userOrganizationPlaceholder: 'Introduza as organizações do usuário',
  enable2FA: 'Ativar 2FA',
  cancel: 'Cancelar',
  deleteWarning: 'Não pode ser desfeito',
  createTagGroup: 'Criar novo grupo de tags',
  createTag: 'Crie uma nova tag para o grupo',
  createUser: 'Criar novo usuário',
  createOrganization: 'Crie uma nova organização',
  createLayer: 'Crie uma nova camada',
  deleteTagGroup: 'Eliminar grupo de tags',
  deleteTagGroupDescription: 'Tem certeza de que deseja eliminar este grupo de tags?',
  deleteTag: 'Eliminar elemento de tag',
  deleteTagDescription: 'Tem certeza de que deseja eliminar este elemento de tag?',
  myTags: 'Minhas tags',
  editTagGroup: 'Editar grupo de tags',
  mapParameters: 'Parâmetros do mapa',
  latitude: 'Latitude',
  longitude: 'Longitude',
  zoomLevel: 'Nível de zoom',
  layers: 'Camadas',
  vector: 'Vetor',
  raster: 'Raster',
  layerName: 'Nome camada',
  layerShapeFiles: 'Suportamos shapefiles, KML, e do GeoJSON',
  dragdropLayers: 'Arraste e solte as camadas aqui',
  color: 'Cor',
  translations: 'Traduções',
  name: 'Nome',
  defaultLanguage: 'Idioma padrão',
  selectReportToExport: 'Selecione relatórios para exportação',
  exportAll: 'Exportar tudo',
  export: 'Exportar',
  enterNameIn: 'Digite o nome em',
  enterLayerNameIn: 'Insira o nome da camada em',
  enterLayerName: 'Insira o nome da camada',
  rasterHostedUrl: 'Raster URL Hospedado',
  enterRasterHostedUrl: 'Insira o URL hospedado raster',
  enterColor: 'Insira a cor',
  enterUserRole: 'Insira a função do usuário',
  deleteSuccess: 'Sim eliminar',
  subject: 'Assunto',
  enterSubject: 'Digite o assunto',
  recipients: 'Destinatários',
  deleteUser: 'Eliminar usuário',
  deleteUserDescription: 'Tem certeza de que deseja eliminar este usuário?',
  deleteOrganization: 'Eliminar Organização',
  deleteOrganizationDescription: 'Tem certeza de que deseja eliminar esta organização?',
  singleSelect: 'Seleção única',
  multiSelect: 'Multi-selecionar',
  textField: 'Campo de texto',
  searchInTheMap: 'Pesquise enquanto movo o mapa',
  select: 'Selecione',
  enterReportTag: 'Insira o {{tag}} do relatório',
  enterZoom: 'Selecione Zoom',
  basemaps: 'Basemaps',
  map: 'Mapa',
  satelitte: 'Satélite',
  deleteReport: 'Eliminar Relatório',
  deleteReportDescription: 'Tem certeza de que deseja eliminar este relatório?',
  closeAction: 'Fechar',
  reportLifecycle: 'Ciclo de vida',
  languages: 'Línguas',
  additionalSettings: 'Configurações adicionais',
  deleteLayer: 'Eliminar Camada',
  deleteLayerDescription: 'Tem certeza que deseja eliminar esta camada?',
  apply: 'Aplicar',
  reset: 'Reiniciar',
  unverify: 'Rejeitar',
  enabledLanguages: 'Línguas Ativas',
  selectLanguages: 'Selecione os idiomas',
  updateTerminology: 'Actualizar Terminologia',
  currentString: 'String atual',
  resetToDefault: 'Restaurar ao padrão',
  dragCsv: 'Arraste e solte o arquivo CSV de idioma aqui',
  supportCsv: 'Só suportamos CSV',
  anonymousSignIn: 'Login anônimo no celular',
  mapsOnMobile: 'Mapas no celular',
  lessonPath: 'Caminho das aulas',
  emergencyAlerts: 'Alertas de Emergência',
  emergencyContactUser: 'Usuário de contato de emergência',
  emergencyContactNumber: 'Número de contato de emergência',
  contactNumber: 'Número de contato',
  enterCustomLessonPath: 'Insira o caminho da aula personalizada',
  urgency: 'Urgência',
  analyticsPage: 'Página de análise',
  notificationsPage: 'Página de Notificações',
  storiesPage: 'Página de histórias',
  dashboardConfiguration: 'Configurações do painel',
  publicSite: 'Site Público',
  mapOnPublicSite: 'Mapa em site público',
  grievencePortal: 'Portal de Reclamações',
  localization: 'Localização',
  timeZone: 'Fuso horário',
  mobileOrganizationLabel: 'Organização para aplicativos móveis',
  mobileOrganizationPlaceholder: 'Selecione uma organização',
  sendByUsers: 'Enviar por usuários',
  sendByRoles: 'Enviar por função',
  private: 'Privado',
  reportState: 'Estado',
  date: 'Data',
  startDate: 'Data de início',
  endDate: 'Data final',
  organization: 'Organização',
  seconds: 'Segundos',
  degrees: 'Graus',
  direction: 'Direção',
  default: 'Padrão',
  custom: 'Personalizado',
  addAdditionalEmergencyNumber: 'Adicionar número de contato de emergência adicional',
  stories: 'Histórias',
  messages: 'Mensagens',
  addTagGroup: 'Adicionar grupo de tag',
  addNewUser: 'Adicionar usuário',
  addNewOrganization: 'Adicionar Organização',
  addNewLayer: 'Adicionar Camada',
  exportAction: 'Exportar',
};

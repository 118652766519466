import React from 'react';
import t from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import '../../../../styles/index.scss';
import DynamicTagSelect from '../../../shared/DynamicTagSelect/DynamicTagSelect';
import { getRandomTint, hexToRgb } from '../../../../utils';

function ReportCategories(props) {
  const { t } = useTranslation();
  return (
    <div className={props.editMode ? '' : "report-categories-container" }>
      {!(props.editMode) && (
        <>
          {(props.groupTags.map((elem) => {
            const selectedValues = props.selectedCategories[elem.id];
            let labels = '';
            let result = [];
            if (!selectedValues) {
              labels = t('noValues');
            } else {
              result = elem.children.filter((child) => {
                if (elem.type === 'multi') {
                  return (selectedValues.includes(child.id));
                }
                return (selectedValues.toString() === child.id);
              });
              labels = result.map((child) => (child[`name_${props.defaultLanguage}`] || child.name_en)).join(' ');
            }

            return (
              <div className="report-categories-element" key={`category-${elem.id}`}>
                <div className="report-categories-element-title">
                  {elem[`name_${props.defaultLanguage}`] || elem.name_en}
                </div>
                <div className="report-categories-element-values">
                  {(result.length === 0 && (
                    <div className="report-categories-element-placeholder">
                      {t('noValues')}
                    </div>
                  ))}
                  {(result.map((elem, i) => (
                    <>
                      <div
                        className="report-categories-element-value"
                        key={elem.id}
                        style={{ backgroundColor: getRandomTint() }}
                      >
                        {elem[`name_${props.defaultLanguage}`] || elem.name_en}
                      </div>
                    </>
                  )))}
                </div>
              </div>
            );
          }))}
        </>
      )}
      {(props.editMode) && (
        <>
          {(props.groupTags.map((elem) => {
            let title = `${t('select')} ${elem[`name_${props.defaultLanguage}`] || elem.name_en}`;
            if (elem.type === 'text') {
              title = `${elem[`name_${props.defaultLanguage}`] || elem.name_en}`;
            }
            return (
              <div className="report-categories-element-select" key={`dynamic-element-${elem.id}`}>
                <DynamicTagSelect
                  title={title}
                  tagGroup={elem}
                  onChange={props.updateCategories}
                  selectedState={props.selectedCategories}
                  defaultLanguage={props.defaultLanguage}
                  placeholder={title}
                />
              </div>
            );
          }))}
        </>
      )}
    </div>
  );
}

const mapStateToProps = ({ tags, common }) => ({
  defaultLanguage: common.defaultLanguage,
  groupTags: tags.groupTags,
});

ReportCategories.propTypes = {
  selectedCategories: t.object.isRequired,
  editMode: t.bool.isRequired,
  updateCategories: t.func.isRequired,
  defaultLanguage: t.string.isRequired,
  groupTags: t.array.isRequired,

};
export default connect(mapStateToProps, null)(ReportCategories);

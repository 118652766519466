export default {
  login: 'Login',
  username: 'Username',
  password: 'Password',
  email: 'Email',
  firstname: 'First name',
  lastname: 'Last name',
  role: 'Role',
  passwordPlaceholder: 'Enter password',
  usernamePlaceholder: 'Enter username',
  signin: 'Sign in',
  forgotMyPassword: 'I forgot my password',
  requirements: 'Requirements',
  resetPassword: 'Reset Password',
  resetPasswordSubtitle: 'Enter the username associated with your account.',
  resetPasswordSubmit: 'Send me reset password link',
  resetLinkSent: 'Reset link sent',
  resetLinkSubtitle: 'We’ve sent a link to reset your password to the email associated with your account.',
  resetLinkSubmit: 'Send me the link again',
  createNewPassword: 'Create new password',
  passwordReq1: 'At least 7 characters',
  passwordReq2: 'Contains 1 uppercase letter',
  passwordReq3: 'Contains 1 number',
  passwordReq4: 'Contains 1 special character',
  passwordReq5: 'Contains 1 lowercase letter',
  newPassword: 'New Password',
  newPasswordPlaceholder: 'Enter new password',
  reEnterNewPassword: 'Re-enter your new password',
  reEnterNewPasswordPlaceholder: 'Re-enter new password',
  createNewPasswordSubmit: 'Change my password',
  twoFactorAuth: 'Enter email address',
  twoFactorAuthSubtitle: 'We’ve sent a verification code to the email associated with your account.',
  twoFactorAuthSubmit: 'Send me verification code',
  twoFactorCode: 'Enter Verification Code',
  twoFactorCodeSubtitle: 'We’ve sent a verification code to the email address associated with this account.',
  enterCode: 'Enter code',
  enterCodePlaceholder: 'Enter the 6 digit code',
  twoFactorCodeSubmit: 'Confirm',
  twoFactorCodeLink: 'Resend my code',
  twoFactorCodeCheckbox: 'Don\'t ask me for 2FA again',
  reportsTitle: 'Reports',
  newReport: 'New Report',
  admin: 'Admin',
  tags: 'Tags',
  users: 'Users',
  exportreport: 'Export Reports',
  profile: 'Profile',
  terminology: 'Terminology',
  mysettings: 'My Settings',
  edit: 'Edit',
  organizations: 'Organizations',
  analytics: 'Analytics',
  analyticsTitle: 'This weeks:',
  analyticsSubmissions: 'Submissions',
  analyticsReportByCat: 'Reports by category',
  analyticsFromLastWeek: 'from last week',
  analyticsRepSubmited: 'Reports submitted',
  analyticsRepVerified: 'Reports verified',
  analyticsIssuesResp: 'Issues Responded to',
  analyticsStoriesCreated: 'Stories created',
  analyticsGeographicPrio: 'Geographic Priorities',
  analyticsPopularKeywords: 'Popular Keywords',
  analyticsKeyword: 'Keyword',
  analyticsMentions: 'Mentions',
  analyticsUsertypes: 'User types',
  analyticsTopUsers: 'Top Users',
  analyticsUsers: 'Users',
  analyticsActivity: 'Activity',
  contactUsers: 'Contact Users',
  send: 'Send',
  sendToEveryone: 'Send to everyone',
  sendSpecificUsers: 'Send message to specific users',
  selectUsers: 'Select users',
  enterMessage: 'Enter your message here',
  search: 'Search',
  addNew: 'Add new',
  save: 'Save',
  ok: 'Ok',
  or: 'Or',
  noValues: 'No values',
  enterFirstName: 'Enter first name',
  enterLastName: 'Enter last name',
  enterEmailPlaceholder: 'Enter email address',
  createReport: 'Create report',
  reportDetails: 'Report Details',
  reportTitleLabel: 'Title',
  reportTitlePlaceholder: 'Enter the Report Title',
  reportDescriptionLabel: 'Description',
  reportDescriptionPlaceholder: 'Enter the Report Description',
  reportAuthorLabel: 'Select Author',
  reportAuthorPlaceholder: 'Select the Author',
  reportHour: 'Hour',
  reportMinute: 'Minute',
  reportSecond: 'Second',
  reportMapLabel: 'Select on the map',
  reportAddMedia: 'Add Media',
  reportUploadMedia: 'Upload photos, videos, audio or PDF',
  reportDragMedia: 'Drag and drop media files here',
  reportSupportMedia: 'We support photos, videos, audio or PDFs',
  reportBrowseMedia: 'Browse',
  reportUploadFiles: 'Uploaded Files',
  reportPublic: 'Public',
  reportSeeActivity: 'See activity',
  reportUnverified: 'Unverified',
  reportVerified: 'Verified',
  reportVerify: 'Verify',
  reportEdit: 'Edit',
  reportSearch: 'Search reports',
  reportedBy: 'Reported by',
  reportWhatHappening: 'What\'s Happening',
  reportLocation: 'Location',
  reportCoordinates: 'Coordinates',
  copyDMS: 'Copy DMS',
  copyDMM: 'Copy DD',
  reportImageEvidence: 'Image Evidence',
  reportAudioEvidence: 'Audio Evidence',
  reportVideoEvidence: 'Video Evidence',
  reportMediaDescription: 'Caption',
  reportSaveClose: 'Save and Close',
  reportPDFEvidence: 'PDF Evidence',
  reportUpdates: 'Activity',
  reportActivityUploadMedia: 'Upload media',
  reportActivityUploadMediaMessage: 'Do you wish to submit these files into the report?',
  reportActivityAssignUser: 'Assign user',
  reportActivitySetUrgency: 'Set Urgency',
  reportActivityPlaceholder: 'Add a comment or additional media to this report',
  updateAction: 'Update',
  editReport: 'Edit Report',
  goBack: 'Back',
  reportCritical: 'This report is critical',
  reportVeryUrgent: 'This report is very urgent',
  reportUrgent: 'This report is urgent',
  urgency_low: 'Important',
  urgency_medium: 'Urgent',
  urgency_high: 'Critical',
  lifecycle_new: 'New',
  lifecycle_started: 'In Progress',
  lifecycle_closed: 'Closed',
  visibility_public: 'Public',
  visibility_private: 'Private',
  status_verified: 'Verified',
  status_unverified: 'Not Verified',
  reportFilter: 'Filter',
  reportStatus: 'Status',
  reportFolders: 'Folders',
  urgent: 'Urgent',
  reportDateCreated: 'Date Created',
  reportAssignment: 'Assignment',
  reportAssignee: 'Assignee',
  chooseUser: 'Choose user',
  reportAuthor: 'Author',
  reportDateCreatedPlaceholder: 'Select Date',
  reportDateLabel: 'When did the event happen?',
  reportDatePlaceholder: 'Enter the report date',
  reportMapTitle: 'Where did it happen?',
  reportDMSCoordinate: 'DMS Coordinates',
  reportDMMCoordinate: 'DMM Coordinates',
  contactRecent: 'Recent Notifications',
  storyTitle: 'Create content',
  storyCreate: 'Create story',
  storySearch: 'Search content',
  published: 'Published',
  updated: 'Updated',
  publishedOn: 'Published on',
  storyType: 'Story type',
  unpublish: 'Unpublish',
  publish: 'Publish',
  unpublished: 'Unpublished',
  readFullReport: 'Read full report',
  coverImage: 'Cover image',
  storyTextContent: 'Text content',
  storyTitlePlaceholder: 'Enter your title here',
  storySubtitlePlaceholder: 'Enter your subtitle here',
  closeReport: 'Close report',
  closeReportMessage: 'Are you sure you want to close this report?',
  storyAddNew: 'Add new text box',
  saveChanges: 'Save changes',
  userOrganizationPlaceholder: "Enter User's organizations",
  enable2FA: 'Enable 2FA',
  cancel: 'Cancel',
  deleteWarning: 'It cannot be undone',
  createFolder: 'Add new folder',
  createTagGroup: 'Create new tag group',
  createTag: 'Create new tag to group',
  createUser: 'Create new user',
  createOrganization: 'Create new organization',
  createLayer: 'Create new layer',
  deleteTagGroup: 'Delete Tag Group',
  deleteTagGroupDescription: 'Are you sure you want to delete this tag group?',
  deleteTag: 'Delete Tag Element',
  deleteTagDescription: 'Are you sure you want to delete this tag element?',
  myTags: 'My Tags',
  editTagGroup: 'Edit tag group',
  mapParameters: 'Map parameters',
  latitude: 'Latitude',
  longitude: 'Longitude',
  zoomLevel: 'Zoom Level',
  layers: 'Layers',
  vector: 'Vector',
  raster: 'Raster',
  layerName: 'Layer Name',
  layerShapeFiles: 'We support shapefiles, KML, and GeoJSON',
  dragdropLayers: 'Drag and drop layers here',
  color: 'Color',
  translations: 'Translations',
  name: 'Name',
  defaultLanguage: 'Default Language',
  selectReportToExport: 'Select reports to export',
  exportAll: 'Export All',
  enterNameIn: 'Enter name in',
  enterLayerNameIn: 'Enter layer name in',
  enterLayerName: 'Enter layer name',
  rasterHostedUrl: 'Raster Hosted URL',
  enterRasterHostedUrl: 'Enter Raster Hosted URL',
  enterColor: 'Enter color',
  enterUserRole: 'Enter user role',
  deleteSuccess: 'Yes delete',
  subject: 'Subject',
  enterSubject: 'Enter Subject',
  recipients: 'Recipients',
  deleteUser: 'Delete User',
  deleteUserDescription: 'Are you sure you want to delete this user?',
  deleteOrganization: 'Delete Organization',
  deleteOrganizationDescription: 'Are you sure you want to delete this organization?',
  singleSelect: 'Single-select',
  multiSelect: 'Multi-select',
  textField: 'Text field',
  searchInTheMap: 'Search as I move the map',
  select: 'Select',
  enterReportTag: "Enter the report's {{tag}}",
  enterZoom: 'Select Zoom',
  basemaps: 'Basemaps',
  map: 'Map',
  satelitte: 'Satellite',
  deleteReport: 'Delete Report',
  deleteReportDescription: 'Are you sure you want to delete this report?',
  closeAction: 'Close',
  reportLifecycle: 'Lifecycle',
  languages: 'Languages',
  additionalSettings: 'Additional Settings',
  deleteLayer: 'Delete Layer',
  deleteLayerDescription: 'Are you sure you want to delete this layer?',
  apply: 'Apply',
  reset: 'Reset',
  unverify: 'Unverify',
  enabledLanguages: 'Enabled Languages',
  selectLanguages: 'Select Languages',
  updateTerminology: 'Update Terminology',
  currentString: 'Current String',
  resetToDefault: 'Reset to Default',
  dragCsv: 'Drag and drop language CSV file here',
  supportCsv: 'We only support CSV',
  anonymousSignIn: 'Anonymous Sign-Ins on Mobile',
  mapsOnMobile: 'Maps on Mobile',
  lessonPath: 'Lessons Path',
  emergencyAlerts: 'Emergency Alerts',
  emergencyContactUser: 'Emergency Contact User',
  emergencyContactNumber: 'Emergency Contact Number',
  contactNumber: 'Contact Number',
  enterCustomLessonPath: 'Enter Custom Lesson Path',
  urgency: 'Urgency',
  verificationStatus: 'Verification Status',
  visibility: 'Visibility',
  analyticsPage: 'Analytics Page',
  notificationsPage: 'Notifications Page',
  storiesPage: 'Stories Page',
  dashboardConfiguration: 'Dashboard',
  appConfiguration: 'Mobile Application',
  publicSite: 'Public Site',
  mapOnPublicSite: 'Map on Public Site',
  grievencePortal: 'Grievance Portal',
  localization: 'Localization',
  timeZone: 'Time Zone',
  mobileOrganizationLabel: 'Organization for Mobile Application',
  mobileOrganizationPlaceholder: 'Select an organization',
  sendByUsers: 'Send by users',
  sendByRoles: 'Send by role',
  private: 'Private',
  reportState: 'State',
  date: 'Date',
  startDate: 'Start Date',
  endDate: 'End Date',
  organization: 'Organization',
  seconds: 'Seconds',
  degrees: 'Degrees',
  direction: 'Direction',
  default: 'Default',
  custom: 'Custom',
  addAdditionalEmergencyNumber: 'Add additional emergency contact number',
  stories: 'Stories',
  messages: 'Messages',
  addTagGroup: 'Add Tag Group',
  addNewUser: 'Add User',
  addNewOrganization: 'Add Organization',
  addNewLayer: 'Add Layer',
  exportAction: 'Export',
  // News Translations - Missing
  deleteStory: 'Delete Story',
  deleteStoryDescription: 'Are you sure you want to delete this story?',
  lifecycleAction: 'set the lifecycle as <b>{{x}}</b>.',
  replyAction: 'replied:',
  verifyAction: 'verified this report.',
  unverifyAction: 'unverified this report.',
  visibilityAction: 'set the visibility as <b>{{x}}</b>',
  taxonomyAction: 'updated the tags for this report.',
  assigneeAction: 'assigned the report to <b class="assignee">{{x}}</b>.',
  urgencyAction: 'set the report as <b>{{x}}</b>.',
  contentAction: 'edited this report.',
  positionAction: 'updated the location for this report.',
  titleAction: "updated this report's title.",
  mediaImagesLabel: 'added {{count}} picture',
  mediaImagesLabel_plural: 'added {{count}} pictures',
  mediaVideosLabel: 'added {{count}} video',
  mediaVideosLabel_plural: 'added {{count}} videos',
  mediaPdfsLabel: 'added {{count}} pdf',
  mediaPdfsLabel_plural: 'added {{count}} pdfs',
  mediaAudiosLabel: 'added {{count}} audio recording',
  mediaAudiosLabel_plural: 'added {{count}} audio recordings',
  reportAddFolderTitle: 'Add report to folders',
  reportAddFolderLabel: 'Enter Folders',
  see: 'See',
  selectRole: 'Select Role',
  enterPromptText: 'Enter prompt for textbox',
  contactAuthorText: 'Sent by {{author}} on {{date}}',
  timbyBranding: 'Timby Branding',
  publicSiteLanguage: 'Public Site Language',
  publicSiteLanguagePlaceholder: 'Choose a Language',
  publicSiteTagline: 'Tagline',
  publicSiteTaglinePlaceholder: 'Enter the tagline on your site',
  publicSitePrimaryColor: 'Primary Colour',
  publicSiteAboutUs: 'About us Page Description',
  publicSiteAboutUsPlaceholder: 'Add a description of your organization that will appear on your website',
  publicSiteOrganizationContactName: 'Organization Contact Name',
  publicSiteOrganizationContactNamePlaceholder: 'Enter the name of the organization that will appear on your site',
  publicSiteOrganizationContactEmail: 'Organization Contact Email',
  publicSiteOrganizationContactEmailPlaceholder: 'Enter a contact email to be displayed on your public site',
  publicSiteOrganizationContactPhone: 'Organization Contact Phone Number',
  publicSiteOrganizationContactPhonePlaceholder: 'Enter the phone number that will be displayed on your public site',
  publicSiteAddCoverImage: 'Add Cover Image',
  publicSiteAddAboutUsImage: 'Add About Us Image',
  reportLatitudePlaceholder: 'Latitude in decimal degrees (e.g. 6.298770)',
  reportLongitudePlaceholder: 'Longitude in decimal degrees (e.g. -10.794428)',
  reportUnsavedChangesTitle: 'Close without saving?',
  reportUnsavedChangesSubtitle: 'You have unsaved changes. Are you sure you want to leave this page without saving?',
  leaveAction: 'Leave',
  noResults: 'No results',
  seeAllResults: 'See all results',
  searchResults: '{{total}} Result found for <strong>&quot;{{search}}&quot;</strong>',
  searchResults_plural: '{{total}} Results found for <strong>&quot;{{search}}&quot;</strong>',

  notifications: {
    CopyDMS: 'Copied DMS to Clipboard',
    CopyDD: 'Copied DD to Clipboard',
    usernameAlphabeticRequired: 'Username must be alphanumeric',
    login: 'Login Successful',
    logout: 'User logged out',
    resendToken: 'Verification Code Sent',
    invalidLogin: 'Invalid Login',
    error: 'Something went wrong!',
    passwordRecoverySuccess: 'Password Recovery Email Sent',
    passwordNotEqual: 'Passwords are not equal',
    passwordChangeSuccess: 'Password Changed Successfully',
    passwordRequirements: 'Password does not have the minimum requirements needed',
    fieldValidations: 'Check fields validations',
    emptyFields: 'Fields highlighted as red must be filled',
    userUpdateSuccess: 'User Updated Successfully',
    userDeletedSuccess: 'User Deleted Successfully',
    userCreatedSuccess: 'User created Successfully',
    organisationDelete: 'Organisation Deleted Successfully',
    folderCreated: 'Folder Created',
    reportAddedFolder: 'Report Added to Folder',
    messageSent: 'Message Sent',
    reportCreated: 'Report Created',
    reportUpdated: 'Report Updated',
    reportDeleted: 'Report Deleted',
    storyCreated: 'Story Created',
    storyUpdated: 'Story Updated',
    storyDeleted: 'Story Deleted',
    tagGroupCreatedSuccess: 'Group created Successfully',
    tagGroupUpdatedSuccess: 'Group updated Successfully',
    tagGroupDeletedSuccess: 'Group deleted Successfully',
    tagOptionCreatedSuccess: 'Tag Option created Successfully',
    tagOptionUpdatedSuccess: 'Tag Option updated Successfully',
    tagOptionDeletedSuccess: 'Tag Option deleted Successfully',
    sessionExpired: 'Session expired',
  },

  dateTrans: {
    seconds: '{{count}} second ago',
    seconds_plural: '{{count}} seconds ago',
    minutes: '{{count}} minute ago',
    minutes_plural: '{{count}} minutes ago',
    hours: '{{count}} hour ago',
    hours_plural: '{{count}} hours ago',
    days: '{{count}} day ago',
    days_plural: '{{count}} days ago',
    months: '{{count}} month ago',
    months_plural: '{{count}} months ago',
    years: '{{count}} year ago',
    years_plural: '{{count}} years ago',
  },

  validations: {
    required: 'Required Field',
    firstNameExceeded: 'First Name must not exceed 40 characters',
    lastNameExceeed: 'Last Name must not exceed 40 characters',
    invalidEmail: 'Email is invalid',
    usernameExceeded: 'Username must not exceed 50 characters <br/>',
    usernameAlphaNumber: 'Username must contains only alphanumbers characters <br/>',
    organizationNameExceeded: 'Organization must not exceed 60 characters',
    layerNameExceeded: 'Layer name must not exceed 20 characters',
    rasterUrlHttps: 'Raster Url must use https protocol <br/>',
    rasterUrlInvalid: 'Raster Url is an invalid url <br />',
    rasterUrlUnable: 'Unable to fetch information from url provided',
    messageSubjectExceeded: 'Subject must have 30 characters or less',
    messageTextExceeded: 'Message must have 500 characters or less',
    reportTitleExceeded: 'Title must not exceeded 70 characters',
    reportDescriptionExceeded: 'Description must not exceeded 10000 characters',
    reportCommentExceeded: 'Comment must not exceed 2000 characters',
    storyTitle: 'Title must not exceed 100 characters',
    storySubtitle: 'Subtitle must not exceed 100 characters',
    storyTextField: 'Text Field must not exceed 2000 characters',
    taxonomyGroupNameExceeded: 'Taxonomy name must not exceed 20 characters',
    taxonomyChildNameExceeded: 'Tag name must not exceed {{elementMaxLength}} characters',
    maxCodeLength: 'Code should contain numbers',
    invalidCode: 'Invalid code',
    invalidToken: 'Invalid token',
    latitudeInvalidNumber: 'Latitude Value must be between -90 and 90',
    longitudeInvalidNumber: 'Longitude Value must be between -180 and 180',
  },
};

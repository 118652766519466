import Cookies from 'js-cookie';
import {
  USER_LOGOUT, UPDATE_TIMBY_OPTIONS, ADD_NEW_MESSAGE, REMOVE_MESSAGES,
  HIDE_MESSAGE, FETCHING_START, FETCHING_END, UPDATE_DEFAULT_LANGUAGE,
} from '../types';
import i18n from '../translations/i18n';
import { history } from '../index';
import { endpoints } from './endpoints';
import { ALERT_ERROR, ALERT_SUCCESS } from '../shared/consts';
import { changeLanguage } from '../utils/translationsUtils';

const TOKEN_KEY = 'token';
let cookieInterval;

const convertSecondsToDate = (seconds) => {
  const verifiedSeconds = Number(seconds);
  if (Number.isNaN(verifiedSeconds)) {
    return (1000 * 60) * 5; // 5Minutes
  }

  return Math.floor(seconds / (3600 * 24));
};

export const setUpCookie = (tokenValue, expiration) => (dispatch, getState) => {
  Cookies.set(TOKEN_KEY, tokenValue, { expires: convertSecondsToDate(expiration), path: '/' });
};

export const refreshCookie = () => async (dispatch, getState) => {
  const randomNumber = Math.random(); // Change this to call keepalive endpoint
  if (randomNumber > 0.05) {
    dispatch(setUpCookie('tokentest'));
  } else {
    dispatch(removeCookie());
  }
};

export const checkCookie = () => {
  Cookies.get(TOKEN_KEY);
};

export const removeCookie = () => (dispatch) => {
  Cookies.remove(TOKEN_KEY, { path: '' });
  clearInterval(cookieInterval);
  cookieInterval = undefined;
  history.push('/');
  dispatch(addNewAlert('Invalid Login', ALERT_ERROR));
  dispatch({ type: USER_LOGOUT, payload: { error: '' } });
};

export const getOptions = () => async (dispatch, getState) => {
  const options = {
    method: 'OPTIONS',
    headers: {
      Accept: 'application/json',
    },
  };
  const { userInfo } = getState().user;

  try {
    const response = await fetch(endpoints.timbyOptions, options);
    const { data, status } = await response.json();
    if (status === 200) {
      let selectedLanguage;
      if (userInfo?.language) {
        selectedLanguage = data.languages.find((lang) => (lang.locale === userInfo.language));
      } else {
        selectedLanguage = data.languages.find((lang) => (lang.id === data.default_language));
      }
      changeLanguage(selectedLanguage.code);
      data.appDefaultLanguage = data.languages.find((lang) => (lang.id === data.default_language));
      data.defaultLanguage = selectedLanguage;
      dispatch({ type: UPDATE_TIMBY_OPTIONS, payload: data });
    }
  } catch (e) {
    return false;
  }
};

export const updateDefaultLanguage = (language) => (dispatch) => {
  dispatch({ type: UPDATE_DEFAULT_LANGUAGE, payload: { language } });
};

export const addNewAlert = (message, messageType, actionType = ADD_NEW_MESSAGE) => (dispatch) => {
  dispatch({
    type: actionType,
    payload: { type: messageType, message, timestamp: Date.now() },
  });
};

export const addSuccessAlert = (message) => (dispatch) => {
  dispatch(addNewAlert(message, ALERT_SUCCESS, ADD_NEW_MESSAGE));
};

export const hideAlert = (messageId) => (dispatch) => {
  dispatch({ type: HIDE_MESSAGE, payload: messageId });
};

export const removeAlert = (messageId) => (dispatch) => {
  dispatch({ type: REMOVE_MESSAGES, payload: messageId });
};

export const openLoader = () => (dispatch) => {
  dispatch({ type: FETCHING_START });
};
export const closeLoader = () => (dispatch) => {
  dispatch({ type: FETCHING_END });
};

export const logoutUserOn401 = (status) => (dispatch) => {
  if (status !== 401) return false;

  dispatch({ type: USER_LOGOUT });
  dispatch(addNewAlert(i18n.t('notifications.sessionExpired'), ALERT_ERROR));

  return true;
};

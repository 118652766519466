import '../../../styles/index.scss';
import ColorPicker from "../../shared/ColorPicker/ColorPicker";
import TextInput from '../../shared/TextInput/TextInput';
import { useState } from 'react';
import { connect } from 'react-redux';
import Button from '../../shared/Button/Button';
import mapDispatchToProps from '../../../actions/index';
import { useTranslation } from 'react-i18next';


const CreateFolderContent = (props) => {
  const { t } = useTranslation(); 
  const { onClose } = props;
  const [name, setName] = useState('');
  const [color, setColor] = useState('');

  const handleCreateFolder = async() => {
    const folderData = {
      name: name,
      color: color,
    };
    const response = await props.addNewFolder(folderData);
    if (response){
      setName('');
      onClose();
    }
  };

  const handleCloseModal = () => {
    setName('');
    onClose();
  };

  return (
    <div className={"folder-create-modal-content"}>
      <TextInput
        title={t('name')}
        placeholder="Enter Folder's Name"
        value={name}
        handleChange={(event) => setName(event.target.value)}
      />

      <ColorPicker
        title={t('color')}
        placeholder="Choose a color"
        value={color}
        handleChange={(event) => setColor(event.target.value)}
      />

      <div className="admin-modal__actions">
        <Button text={t('cancel')} secondary medium handler={() => handleCloseModal()} />
        <Button text={t('save')} primary medium handler={() => handleCreateFolder()} />
      </div>
    </div>
  );
};

export default connect(null, mapDispatchToProps)(CreateFolderContent);

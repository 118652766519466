import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import mapDispatchToProps from '../../../actions/index';
import clsx from 'clsx';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../shared/consts';

const AlertMessage = ({ type, message, timeout, id, hideAlert, hidden }) => {
  let timeoutRef = useRef(undefined);
  let hideRef = useRef(undefined);
  let [currentHidden, setCurrentHidden] = useState(false);

  useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      hideAlert(id);
    }, timeout);
    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, []);

  useEffect(() => {
    if (hidden && !hideRef.current) {
      hideRef.current = setTimeout(() => {
        setCurrentHidden(true);
      }, 500);
    }
    return () => {
      if (hideRef.current) clearTimeout(timeoutRef.current);
    };
  }, [hidden]);

  return (
    <div className={clsx('alert-message__wrapper', {
      'alert-message__hidden': currentHidden,
    })}>
      <div className={clsx('alert-message__icon', {
        'alert-message__icon--success': type === ALERT_SUCCESS,
        'alert-message__icon--error': type === ALERT_ERROR,
      })}/>
      <div className="alert-message__message">
        {message}
      </div>
    </div>
  );
};

export default connect(null, mapDispatchToProps)(AlertMessage);

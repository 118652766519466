import { differenceInSeconds, parse } from 'date-fns';
import { REPORT_EVENT_DATE_PARSER } from '../../../shared/consts';
import { getUTCDatenow } from '../../../utils/dateUtils';

/* Why?
const allEvents = [
  'add_image', 'add_pdf', 'add_video',
  'add_audio', 'add_comment', 'edit_lifecycle',
  'edit_verified', 'edit_visibility', 'edit_assignee',
  'edit_urgency', 'edit_content', 'set_folder',
  'set_taxonomy', 'edit_position', 'edit_title',
];
*/

const joinableEvents = ['add_comment', 'add_media', 'set_taxonomy'];
const mediaTypes = ['image', 'pdf', 'video', 'audio'];

const INTERVAL_BETWEEN_EVENTS = 30;
const defaultActivity = {
  data: [],
  event: '',
  date: getUTCDatenow(),
  author: '',
};

const dateParser = (date) => (
  parse(date, REPORT_EVENT_DATE_PARSER, getUTCDatenow())
);

const formatActivityFeedList = (activityFeedList, selectedReport) => {
  let prevActivity = { ...defaultActivity };
  const activityFeedInfo = [];

  // const creationDate = dateParser(selectedReport?.created_date || new Date());

  activityFeedList.map((elem, index) => {

    let activityData = elem.data;

    if (elem.event === 'add_comment') {
      activityData = elem.data[0];
    }

    /* for now, let's just list every event separately
    if (differenceInSeconds(dateParser(elem.event_date), creationDate) < INTERVAL_BETWEEN_EVENTS) {
      return;
    }
    */

    // for now, let's just list every event separately
    if (
      false
      && joinableEvents.includes(elem.event)
      && elem.username === prevActivity.author
      && differenceInSeconds(dateParser(elem.event_date), prevActivity.date,) < INTERVAL_BETWEEN_EVENTS
    ) {
      // ...
    } else {
      //console.log(elem.event);
      if(! joinableEvents.includes(elem.event)) return;
      if (elem.media.length) {
        // Aggregate medias if any
        mediaTypes.forEach(type => {
          let media = elem.media.filter(e => e.type === type);
          activityData[type] = media;
        });
      }

      prevActivity = {
        author: elem.username,
        event: elem.event,
        color: elem.color,
        data: activityData,
        date: dateParser(elem.event_date),
      };

      activityFeedInfo.push({
        ...prevActivity,
      });

      // console.log(elem);
    }

  });

  return activityFeedInfo;
};

export default formatActivityFeedList;

import {
  CREATE_ORGANIZATION, GET_ROLES, GET_ORGANIZATIONS, GET_USERS, UPDATE_ORGANIZATION,
  UPDATE_SELECTED_ORGANIZATION, GET_ORGANIZATION_OPTIONS, UPDATE_ORGANIZATION_OPTIONS,
  DELETE_ORGANIZATION, GET_ORGANIZATION_LAYERS,
} from '../types';
import i18n from '../translations/i18n';
import { endpoints } from './endpoints';
import {
  addNewAlert, closeLoader, logoutUserOn401, openLoader,
} from './CommonActions';
import { getPasswordErrors } from './UserActions';
import {
  ALERT_ERROR, ALERT_SUCCESS, DOMAINS_REGEX,
} from '../shared/consts';

const LAYER_MAX_LENGTH = 20;

export const convertPasswordErrorsIntoText = (errors) => {
  const converter = {
    minLength: i18n.t('passwordReq1'),
    upperCaseRequired: i18n.t('passwordReq2'),
    downCaseRequired: i18n.t('passwordReq5'),
    numberRequired: i18n.t('passwordReq3'),
    specialCharRequired: i18n.t('passwordReq4'),
    maxLength: 'Password exceeded max characters',
  };
  let text = '';
  errors.map((elem) => {
    if (converter[elem]) text += `${converter[elem]} <br />`;
    return null;
  });

  return text;
};

export const getUsers = (organizationId = '') => async (dispatch, getState) => {
  const { token } = getState().user;
  const { selectedOrganizationId } = getState().admin;
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    dispatch(openLoader());
    const response = await fetch(
      endpoints.getUsers(organizationId || selectedOrganizationId), options,
    );
    const { error, data, status } = await response.json();

    const logoutResult = dispatch(logoutUserOn401(status));
    if (logoutResult) return { success: false };

    if (status !== 200 && error) {
      if (status !== 200 && error && error.description) {
        dispatch(addNewAlert(error.description, ALERT_ERROR));
      }
    } else {
      dispatch({ type: GET_USERS, payload: { users: data } });
    }
  } catch {
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
  } finally {
    dispatch(closeLoader());
  }

  return { success: true };
};

export const updateUser = (username, userData) => async (dispatch, getState) => {
  const { token } = getState().user;

  const errors = {};
  if (userData.password) {
    const errorsResult = dispatch(getPasswordErrors(userData.password, userData.password, false));
    if (errorsResult.success) {
      dispatch(addNewAlert(i18n.t('notifications.passwordRequirements'), ALERT_ERROR));
      const passwordText = convertPasswordErrorsIntoText(errorsResult.errors);
      errors.password = passwordText;
    }
  }

  if (!userData.role_id) errors.role = i18n.t('validations.required');

  if (userData.firstname && userData.firstname.length > 40) errors.firstname = i18n.t('validations.firstNameExceeded');
  if (userData.lastname && userData.lastname.length > 40) errors.lastname = i18n.t('validations.lastNameExceeed');

  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (userData.email && !emailRegex.exec(userData.email)) {
    errors.email = i18n.t('validations.invalidEmail');
  }

  if (Object.keys(errors).length > 0) {
    dispatch(addNewAlert(i18n.t('notifications.fieldValidations'), ALERT_ERROR));
    return { success: false, errors };
  }

  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(userData),
  };

  try {
    dispatch(openLoader());
    const response = await fetch(endpoints.user(username), options);
    const { error, status } = await response.json();

    const logoutResult = dispatch(logoutUserOn401(status));
    if (logoutResult) return { success: false };
    if (status !== 200 && error) {
      if (status !== 200 && error && error.description) {
        dispatch(addNewAlert(error.description, ALERT_ERROR));
      }
    } else {
      dispatch(addNewAlert(i18n.t('notifications.userUpdateSuccess'), ALERT_SUCCESS));
      dispatch(getUsers());
    }
  } catch {
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
  } finally {
    dispatch(closeLoader());
  }

  return { success: true };
};

export const deleteUser = (username) => async (dispatch, getState) => {
  const { token } = getState().user;

  const options = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    dispatch(openLoader());
    const response = await fetch(endpoints.user(username), options);
    const { error, status } = await response.json();

    const logoutResult = dispatch(logoutUserOn401(status));
    if (logoutResult) return { success: false };
    if (status !== 200 && error) {
      if (status !== 200 && error && error.description) {
        dispatch(addNewAlert(error.description, ALERT_ERROR));
        return { success: false };
      }
    } else {
      dispatch(addNewAlert(i18n.t('notifications.userDeletedSuccess'), ALERT_SUCCESS));
      dispatch(getUsers());
    }
  } catch {
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
    return { success: false };
  } finally {
    dispatch(closeLoader());
  }

  return { success: true };
};

export const createUser = (userData) => async (dispatch, getState) => {
  const { user, admin } = getState();
  const { token } = user;
  // Validations

  const errors = {};
  const errorsResult = dispatch(getPasswordErrors(userData.password, userData.password, false));
  if (errorsResult.success) {
    const passwordText = convertPasswordErrorsIntoText(errorsResult.errors);
    errors.password = passwordText;
  }

  if (userData.organization_id.length === 0) errors.organizations = i18n.t('validations.required');
  if (!userData.role_id) errors.role = i18n.t('validations.required');
  if (!userData.username) errors.username = i18n.t('validations.required');

  if (userData.username && userData.username.length > 50) {
    errors.username = (errors.username || '') + i18n.t('validations.usernameExceeded');
  }
  const usernameRegex = /^[a-zA-z0-9_.-]+$/i;
  if (userData.username && !usernameRegex.exec(userData.username)) {
    errors.username = (errors.username || '') + i18n.t('validations.usernameAlphaNumber');
  }

  if (userData.firstname && userData.firstname.length > 40) errors.firstname = i18n.t('validations.firstNameExceeded');
  if (userData.lastname && userData.lastname.length > 40) errors.lastname = i18n.t('validations.lastNameExceeed');

  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (userData.email && !emailRegex.exec(userData.email)) {
    errors.email = i18n.t('validations.invalidEmail');
  }

  if (Object.keys(errors).length > 0) {
    dispatch(addNewAlert(i18n.t('notifications.fieldValidations'), ALERT_ERROR));
    return { success: false, errors };
  }
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(userData),
  };

  try {
    dispatch(openLoader());
    const response = await fetch(endpoints.createUser(admin.selectedOrganizationId), options);
    const { error, status } = await response.json();

    const logoutResult = dispatch(logoutUserOn401(status));
    if (logoutResult) return { success: false };
    if (status !== 200 && error) {
      if (status !== 200 && error && error.description) {
        dispatch(addNewAlert(error.description, ALERT_ERROR));
      }
    } else {
      dispatch(addNewAlert(i18n.t('notifications.userCreatedSuccess'), ALERT_SUCCESS));
      dispatch(getUsers());
    }
  } catch (e) {
    console.warn(e);
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
  } finally {
    dispatch(closeLoader());
  }

  return { success: true };
};

export const getOrganizations = () => async (dispatch, getState) => {
  const { token } = getState().user;
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    dispatch(openLoader());
    const response = await fetch(endpoints.getOrganizations, options);
    const { error, data, status } = await response.json();

    const logoutResult = dispatch(logoutUserOn401(status));
    if (logoutResult) return { success: false };
    if (status !== 200 && error) {
      if (status !== 200 && error && error.description) {
        dispatch(addNewAlert(error.description, ALERT_ERROR));
      }
    } else {
      dispatch({ type: GET_ORGANIZATIONS, payload: { organizations: data } });
    }
  } catch {
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
  } finally {
    dispatch(closeLoader());
  }

  return true;
};

export const getRoles = () => async (dispatch, getState) => {
  const { token } = getState().user;
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    dispatch(openLoader());
    const response = await fetch(endpoints.getRoles, options);
    const { error, data, status } = await response.json();

    const logoutResult = dispatch(logoutUserOn401(status));
    if (logoutResult) return { success: false };
    if (status !== 200 && error) {
      if (status !== 200 && error && error.description) {
        dispatch(addNewAlert(error.description, ALERT_ERROR));
      }
    } else {
      dispatch({ type: GET_ROLES, payload: { roles: data } });
    }
  } catch {
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
  } finally {
    dispatch(closeLoader());
  }

  return true;
};

export const createOrganization = (organizationData) => async (dispatch, getState) => {
  const store = getState();
  const { user } = store;
  const { token } = user;

  if (organizationData.name === '') return { success: false, errors: { name: i18n.t('validations.required') } };
  if (organizationData.name.length > 60) return { success: false, errors: { name: i18n.t('validations.organizationNameExceeded') } };
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(organizationData),
  };

  try {
    dispatch(openLoader());
    const response = await fetch(endpoints.createOrganization, options);
    const { error, status } = await response.json();

    const logoutResult = dispatch(logoutUserOn401(status));
    if (logoutResult) return { success: false };
    if (status !== 200 && error) {
      if (status !== 200 && error && error.description) {
        dispatch(addNewAlert(error.description, ALERT_ERROR));
      }
    } else {
      dispatch({ type: CREATE_ORGANIZATION, payload: { organization: organizationData } });
      dispatch(getOrganizations());
    }
  } catch (e) {
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
  } finally {
    dispatch(closeLoader());
  }

  return true;
};

export const updateSelectedOrganization = (organizationId) => (dispatch) => {
  dispatch({
    type: UPDATE_SELECTED_ORGANIZATION,
    payload: { selectedOrganizationId: organizationId },
  });
};

export const updateOrganization = (organizationId, organizationData) => async (dispatch, getState) => {
  const store = getState();
  const { user } = store;

  const { token } = user;

  if (organizationData.name === '') return { success: false, errors: { name: i18n.t('validations.required') } };
  if (organizationData.name.length > 60) return { success: false, errors: { name: i18n.t('validations.organizationNameExceeded') } };

  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(organizationData),
  };

  try {
    dispatch(openLoader());
    const response = await fetch(endpoints.organization(organizationId), options);
    const { error, status } = await response.json();

    const logoutResult = dispatch(logoutUserOn401(status));
    if (logoutResult) return { success: false };
    if (status !== 200 && error) {
      if (status !== 200 && error && error.description) {
        dispatch(addNewAlert(error.description, ALERT_ERROR));
      }
    } else {
      dispatch({ type: UPDATE_ORGANIZATION, payload: { organization: organizationData } });
      dispatch(getOrganizations());
    }
  } catch (e) {
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
  } finally {
    dispatch(closeLoader());
  }

  return { success: true };
};

export const deleteOrganization = (organizationId) => async (dispatch, getState) => {
  const store = getState();
  const { user } = store;
  const { token } = user;

  const options = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    dispatch(openLoader());
    const response = await fetch(endpoints.organization(organizationId), options);
    const { error, status } = await response.json();

    const logoutResult = dispatch(logoutUserOn401(status));
    if (logoutResult) return { success: false };
    if (status !== 200 && error) {
      if (status !== 200 && error && error.description) {
        dispatch(addNewAlert(error.description, ALERT_ERROR));
        return { success: false };
      }
    } else {
      dispatch({ type: DELETE_ORGANIZATION });
      dispatch(addNewAlert(i18n.t('notifications.organisationDelete'), ALERT_SUCCESS));
      dispatch(getOrganizations());
    }
  } catch (e) {
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
    return { success: false };
  } finally {
    dispatch(closeLoader());
  }

  return { success: true };
};

export const getOrganizationOptions = () => async (dispatch, getState) => {
  const store = getState();
  const { user, admin } = store;
  const { token } = user;
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    dispatch(openLoader());
    const response = await fetch(
      endpoints.organizationOptions(admin.selectedOrganizationId), options,
    );
    const { error, data, status } = await response.json();

    const logoutResult = dispatch(logoutUserOn401(status));
    if (logoutResult) return { success: false };
    if (status !== 200 && error) {
      if (status !== 200 && error && error.description) {
        dispatch(addNewAlert(error.description, ALERT_ERROR));
      }
    } else {
      dispatch({ type: GET_ORGANIZATION_OPTIONS, payload: { organizationOptions: data || {} } });
    }
  } catch {
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
  } finally {
    dispatch(closeLoader());
  }

  return true;
};

export const updateOrganizationOptions = (organizationOptionsData) => async (dispatch, getState) => {
  const store = getState();
  const { user, admin } = store;
  const { token } = user;

  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(organizationOptionsData),
  };

  try {
    dispatch(openLoader());
    const response = await fetch(
      endpoints.organizationOptions(admin.selectedOrganizationId), options,
    );
    const { error, status } = await response.json();

    const logoutResult = dispatch(logoutUserOn401(status));
    if (logoutResult) return { success: false };
    if (status !== 200 && error) {
      if (status !== 200 && error && error.description) {
        dispatch(addNewAlert(error.description, ALERT_ERROR));
      }
    } else {
      dispatch({
        type: UPDATE_ORGANIZATION_OPTIONS,
        payload: { organizationOptions: organizationOptionsData },
      });
      dispatch(getOrganizationOptions(admin.selectedOrganizationId));
    }
  } catch (e) {
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
  } finally {
    dispatch(closeLoader());
  }

  return { success: true };
};

export const getOrganizationLayers = () => async (dispatch, getState) => {
  const store = getState();
  const { user, admin } = store;
  const { token } = user;
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    dispatch(openLoader());
    const response = await fetch(endpoints.getLayers(admin.selectedOrganizationId), options);
    const { error, data, status } = await response.json();

    const logoutResult = dispatch(logoutUserOn401(status));
    if (logoutResult) return { success: false };
    if (status !== 200 && error) {
      if (status !== 200 && error && error.description) {
        dispatch(addNewAlert(error.description, ALERT_ERROR));
      }
    } else {
      const formattedData = data.map((elem) => {
        const newElem = elem;
        Object.keys(elem.name).map((key) => {
          const newKey = key;
          newElem[`name_${key}`] = elem.name[newKey];
          return newKey;
        });
        delete newElem.name;
        return newElem;
      });
      dispatch({ type: GET_ORGANIZATION_LAYERS, payload: { layers: formattedData || [] } });
    }
  } catch {
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
  } finally {
    dispatch(closeLoader());
  }

  return true;
};

export const validationsLayer = (layerData, errors) => async (dispatch, getState) => {
  const store = getState();
  const { common } = store;
  const { languages } = common;

  const newErrors = errors;
  Object.keys(layerData.name).map((elem) => {
    if (!layerData.name[elem]) newErrors[elem] = i18n.t('validations.required');
    return null;
  });

  const languagesCodes = languages.map((elem) => elem.code);

  languagesCodes.map((elem) => {
    if (Object.keys(layerData.name).includes(elem)) return null;

    newErrors[elem] = i18n.t('validations.required');
    return null;
  });

  Object.keys(layerData.name).map((key) => {
    if (layerData.name[key].length > LAYER_MAX_LENGTH) {
      newErrors[key] = i18n.t('validations.layerNameExceeded');
    }
    return null;
  });

  return newErrors;
};

export const validationsRasterLayer = async (layerData, errors) => {
  const newErrors = errors;
  if (layerData.type === 'raster' && !layerData.url) newErrors.raster = i18n.t('validations.required');

  if (layerData.type === 'raster' && layerData.url) {
    if (!layerData.url.startsWith('https://')) newErrors.raster = (newErrors.raster || '') + i18n.t('validations.rasterUrlHttps');
    const domainCheck = layerData.url.match(DOMAINS_REGEX) == null;
    if (domainCheck || !layerData.url.includes('/{z}/{x}/{y}')) {
      newErrors.raster = (newErrors.raster || '') + i18n.t('validations.rasterUrlInvalid');
    }

    if (!newErrors.raster) {
      const testUrl = layerData.url.replace('{y}', 0).replace('{z}', 0).replace('{x}', 0);
      try {
        const testResponse = await fetch(testUrl, { method: 'GET' });
        const { status: urlStatus } = testResponse;
        if (urlStatus !== 200) newErrors.raster = (newErrors.raster || '') + i18n.t('validations.rasterUrlUnable');
      } catch (e) {
        newErrors.raster = (newErrors.raster || '') + i18n.t('validations.rasterUrlUnable');
      }
    }
  }

  return newErrors;
};

export const createLayer = (layerData) => async (dispatch, getState) => {
  const store = getState();
  const { user, admin, common } = store;
  const { token } = user;
  dispatch(openLoader());
  const formData = new FormData();

  let errors = {};
  errors = await dispatch(validationsLayer(layerData, errors));

  if (layerData.type === 'vector' && layerData.vector.length === 0) errors.files = i18n.t('validations.required');

  errors = await validationsRasterLayer(layerData, errors);

  if (Object.keys(errors).length > 0) {
    dispatch(closeLoader());
    return { success: false, errors };
  }

  Object.keys(layerData.name).map((elem) => (
    formData.append(`name[${elem}]`, layerData.name[elem])
  ));
  formData.append('type', layerData.type);

  if (layerData.type === 'vector') {
    if (layerData.vector.length > 0) formData.append('vector', layerData.vector[0]);
    formData.append('color', layerData.color);
  }
  if (layerData.type === 'raster') {
    formData.append('url', layerData.url);
  }

  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: formData,
  };

  try {
    dispatch(openLoader());
    const response = await fetch(endpoints.createLayer(admin.selectedOrganizationId), options);
    const { error, status } = await response.json();

    const logoutResult = dispatch(logoutUserOn401(status));
    if (logoutResult) return { success: false };
    if (status !== 200 && error) {
      if (status !== 200 && error && error.description) {
        dispatch(addNewAlert(error.description, ALERT_ERROR));
      }
    } else {
      dispatch(getOrganizationLayers(admin.selectedOrganizationId));
    }
  } catch (e) {
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
    return { success: false, errors: {} };
  } finally {
    dispatch(closeLoader());
  }

  return { success: true };
};

export const updateLayer = (layerId, layerData) => async (dispatch, getState) => {
  const store = getState();
  const { user, admin } = store;
  const { token } = user;
  dispatch(openLoader());

  let errors = {};
  errors = await dispatch(validationsLayer(layerData, errors));
  errors = await validationsRasterLayer(layerData, errors);

  if (Object.keys(errors).length > 0) {
    dispatch(closeLoader());
    return { success: false, errors };
  }

  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(layerData),
  };

  try {
    dispatch(openLoader());
    const response = await fetch(
      endpoints.alterLayer(admin.selectedOrganizationId, layerId), options,
    );
    const { error, status } = await response.json();

    const logoutResult = dispatch(logoutUserOn401(status));
    if (logoutResult) return { success: false };
    if (status !== 200 && error) {
      if (status !== 200 && error && error.description) {
        dispatch(addNewAlert(error.description, ALERT_ERROR));
      }
    } else {
      dispatch(getOrganizationLayers(admin.selectedOrganizationId));
    }
  } catch (e) {
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
    return { success: false, errors: {} };
  } finally {
    dispatch(closeLoader());
  }

  return { success: true };
};

export const deleteLayer = (layerId) => async (dispatch, getState) => {
  const store = getState();
  const { user, admin } = store;
  const { token } = user;

  const options = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    dispatch(openLoader());
    const response = await fetch(
      endpoints.alterLayer(admin.selectedOrganizationId, layerId), options,
    );
    const { error, status } = await response.json();
    const logoutResult = dispatch(logoutUserOn401(status));
    if (logoutResult) return { success: false };

    if (status !== 200 && error) {
      if (status !== 200 && error && error.description) {
        dispatch(addNewAlert(error.description, ALERT_ERROR));
        return false;
      }
    } else {
      dispatch(getOrganizationLayers(admin.selectedOrganizationId));
    }
  } catch (e) {
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
    return false;
  } finally {
    dispatch(closeLoader());
  }

  return true;
};

import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import clsx from 'clsx';

const SelectBox = ({
  placeholder,
  title,
  value,
  options,
  handleChange,
  medium,
  search,
  small,
  multiple,
  disabled=false,
  clearable,
  errorDescription = '',
  hasError=false,
}) => (
  <div className={'select-box'}>
    {title && <div className="text-input__label">{title}</div>}
    <Dropdown
      className={clsx('select-box__wrapper', {
        'select-box__wrapper--medium': medium,
        'select-box__wrapper--small': small,
        'select-box__wrapper--errors': hasError,
      })}
      fluid
      selection
      disabled={disabled}
      search={search}
      multiple={multiple}
      placeholder={placeholder}
      selectOnNavigation={false}
      selectOnBlur={false}
      onChange={handleChange}
      options={options || []}
      value={value}
      clearable={clearable}
      icon='chevron down'
    />
    {hasError && errorDescription && (
      <span className="text-input__error" dangerouslySetInnerHTML={{ __html: errorDescription }} />
    )}
  </div>
);

export default SelectBox;

import React from 'react';
import clsx from 'clsx';

import '../../../styles/index.scss';
import { ReactComponent as SVGVerified } from '../../../assets/images/icon_verified.svg';
import { ReactComponent as SVGUrgent } from '../../../assets/images/urgent_icon.svg';


function ReportInfo(props) {
  const selectedStyle = props.hasSelectedStyle;
  return (
    <div
      className={clsx(
        'report-info__wrapper',
        {
          'report-info__wrapper--selected': selectedStyle,
        },
      )}
      onClick={props.onClick ? () => {props.onClick(); } : undefined}
      onKeyPress={props.onClick ? () => {props.onClick(); } : undefined}
      role="button"
      tabIndex="0"
    >
      <div className="report-info__main__wrapper">
        <div className="report-info__status">
        {<div className="report-info__new" style={{opacity: props.new ? 1 : 0}} />}
          <SVGVerified className={clsx("report-info__verified", {
            "report-info__verified--check": props.verified,
          })}/>
        </div>
        <div className="report-info__info">
          <div className="report-info__title">{props.title}</div>
          <div className="report-info__description">{props.description}</div>
        </div>
      </div>
      <div className="report-info__date-box">
        <div className="report-info__date">{props.date}</div>
        {props.urgent && <SVGUrgent className="report-info__urgent"/>}
      </div>
    </div>
  );
}

export default ReportInfo;

import {
  CREATE_REPORT, DELETE_REPORT,
  GET_REPORTS, SELECT_REPORT, UNSELECT_REPORT,
  UPDATE_ACTIVITY_REPORT, ADD_ACTIVITY_REPORT,
} from '../types';

import { endpoints } from './endpoints';
import i18n from '../translations/i18n';
import {
  addNewAlert, closeLoader, logoutUserOn401, openLoader,
} from './CommonActions';
import { ALERT_ERROR, ALERT_SUCCESS } from '../shared/consts';
import { history } from '..';
import { getRandomColor, hexToRgb, sleep } from '../utils';


export const buildQueryFilters = (object) => {
  let querystring = '';
  if (object && Object.keys(object).length) {
    for (const [key, value] of Object.entries(object)) {
      if(Array.isArray(value)) {
        for (const [k, v] of Object.entries(value)) {
          querystring += `&filters[${key}][${k}]=${v}`;
        }
      } else {
        querystring += `&filters[${key}]=${value}`;
      }
    }
    querystring = querystring.replace('&', '?');
  }

  return querystring;
};

export const getReports = () => async (dispatch, getState) => {
  const { user, admin, reports } = getState();
  const { token } = user;

  try {
    dispatch(openLoader());

    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      }
    };

    let querystring = buildQueryFilters(reports.filters);
    const response = await fetch(`${endpoints.getReports()}${querystring}`, options);

    const { error, data, status } = await response.json();

    const logoutResult = dispatch(logoutUserOn401(status));
    if (logoutResult) return { success: false };

    if (status !== 200 && error) {
      if (status !== 200 && error && error.description) {
        dispatch(addNewAlert(error.description, ALERT_ERROR));
      }
    } else {
      const { reports } = data;
      const fetchedReports = reports || [];
      dispatch({
        type: GET_REPORTS,
        payload: {
          reports: fetchedReports
        },
      });
    }
  } catch (e) {
    console.warn(e);
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
  } finally {
    dispatch(closeLoader());
  }

  return { success: true };
};

export const getMapSelectedReportInfo = (reportId) => async (dispatch, getState) => {
  const { reports } = getState();
  dispatch(openLoader());
  const reportsList = reports.reports;

  const selectedReportInfo = reportsList.find((elem) => elem.uuid === reportId);

  await sleep(500);
  dispatch(closeLoader());

  return selectedReportInfo;
};

export const getSelectedReport = (reportId) => async (dispatch, getState) => {
  const { user, admin, reports } = getState();
  const { token } = user;
  const { selectedReport: currentReport, reports: currentReports } = reports;

  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    dispatch(openLoader());
    const response = await fetch(`${endpoints.getReport(reportId)}`, options);
    const { error, data, status } = await response.json();
    const logoutResult = dispatch(logoutUserOn401(status));
    if (logoutResult) return { success: false };

    if (status !== 200 && error) {
      if (status !== 200 && error && error.description) {
        dispatch(addNewAlert(error.description, ALERT_ERROR));
        history.push({ pathname: '/dashboard', from: history.location.pathname });
      }
    } else {
      const selectedReport = data;

      const updatedReports = currentReports.map((report) => {
        if (report.uuid === reportId) {
          return { ...report, new: false };
        }
        return { ...report };
      });
      if (!selectedReport) return history.push({ pathname: '/dashboard', from: history.location.pathname });

      dispatch({ type: SELECT_REPORT, payload: { selectedReport, updatedReports } });

      if (currentReport?.uuid !== reportId) dispatch(getReportActivity(selectedReport.uuid));
    }
  } catch (e) {
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
    console.warn(e);
    history.push({ pathname: '/dashboard', from: history.location.pathname });
  } finally {
    dispatch(closeLoader());
  }

  return { success: true };
};

export const getReportActivity = (reportId) => async (dispatch, getState) => {
  const { user, admin, reports } = getState();
  const { token } = user;

  const { selectedOrganizationId, users } = admin;

  if (reports.activityFeedReachedEnd) return;

  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
  const LIMIT = 20;

  try {
    dispatch(openLoader());
    const response = await fetch(`${endpoints.getReportActivity(selectedOrganizationId, reportId)}?limit=20&offset=${reports.activityFeedOffset}`, options); // this can be changed for a get single element
    const { error, data, status } = await response.json();

    const logoutResult = dispatch(logoutUserOn401(status));
    if (logoutResult) return { success: false };

    if (status !== 200 && error) {
      if (status !== 200 && error && error.description) {
        dispatch(addNewAlert(error.description, ALERT_ERROR));
      }
    } else {
      const {
        total, activity,
      } = data;
      const fetchedActivity = activity || [];

      const newActivityUsersColors = reports.activityUsersColor;

      const activityWithMedia = fetchedActivity.map((elem) => {
        if (!newActivityUsersColors[elem.username]) {
          const randomColor = hexToRgb(getRandomColor(), 0.3);
          newActivityUsersColors[elem.username] = randomColor;
        }

        const updatedInfo = {
          media: elem.media,
          color: newActivityUsersColors[elem.username],
        };

        if (elem.event === 'edit_assignee') {
          const username = users.find((user) => (user.uuid === elem.data))?.username;
          updatedInfo.data = username;
        }

        return ({
          ...elem,
          ...updatedInfo,
        });
      });

      dispatch({
        type: UPDATE_ACTIVITY_REPORT,
        payload: {
          activityFeed: activityWithMedia,
          colors: newActivityUsersColors,
          hasReachedEnd: parseInt(reports.activityFeed.length, 10)
              + parseInt(LIMIT, 10) >= parseInt(total, 10),
        },
      });
    }
  } catch (e) {
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
    console.warn(e);
  } finally {
    dispatch(closeLoader());
  }

  return { success: true };
};

export const createNewReport = (reportPayload) => async (dispatch, getState) => {
  const { user, admin } = getState();
  const { token } = user;

  const formData = new FormData();

  // Validations
  const errors = {};
  if (reportPayload.title === '') errors.title = i18n.t('validations.required');
  if (reportPayload.title.length > 70) errors.title = i18n.t('validations.reportTitleExceeded');

  if (reportPayload.content === '') errors.description = i18n.t('validations.required');
  if (reportPayload.content.length > 10000) errors.description = i18n.t('validations.reportDescriptionExceeded');

  if (reportPayload.latitude === '' || reportPayload.latitude === null) errors.latitude = i18n.t('validations.required');
  if (reportPayload.longitude === '' || reportPayload.longitude === null) errors.longitude = i18n.t('validations.required');
  if (reportPayload.event_date === null) errors.date = i18n.t('validations.required');

  if (Object.keys(errors).length > 0) {
    dispatch(addNewAlert(i18n.t('notifications.fieldValidations'), ALERT_ERROR));
    return { success: false, errors };
  }

  formData.append('title', reportPayload.title);
  formData.append('content', reportPayload.content);
  formData.append('event_date', reportPayload.event_date);
  formData.append('latitude', reportPayload.latitude);
  formData.append('longitude', reportPayload.longitude);

  const { taxonomies } = reportPayload;
  taxonomies.map((elem) => {
    if (typeof elem === 'object' && elem !== null) {
      formData.append('taxonomies[]', JSON.stringify(elem));
    } else {
      formData.append('taxonomies[]', elem);
    }
    return elem;
  });

  const { files } = reportPayload;
  files.map((file) => (
      formData.append('media[]', file)
  ));

  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: formData,
  };

  try {
    dispatch(openLoader());
    const response = await fetch(
        endpoints.createReport(admin.selectedOrganizationId), options,
    ); // this can be changed for a get single element
    const { error, status } = await response.json();

    const logoutResult = dispatch(logoutUserOn401(status));
    if (logoutResult) return { success: false };

    if (status !== 200 && error) {
      if (status !== 200 && error && error.description) {
        dispatch(addNewAlert(error.description, ALERT_ERROR));
      }
    } else {
      dispatch(addNewAlert(i18n.t('notifications.reportCreated'), ALERT_SUCCESS));
      dispatch({ type: CREATE_REPORT });
      history.push({ pathname: '/dashboard', from: history.location.pathname });
    }
  } catch (e) {
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
    console.warn(e);
  } finally {
    dispatch(closeLoader());
  }

  return { success: true };
};

export const editReport = (reportId, reportPayload) => async (dispatch, getState) => {
  const { user, admin } = getState();
  const { token } = user;

  const errors = {};
  if (Object.keys(reportPayload).includes('content') && reportPayload.content === '') errors.description = i18n.t('validations.required');
  if (Object.keys(reportPayload).includes('content') && reportPayload.content.length > 10000) errors.description = i18n.t('validations.reportDescriptionExceeded');
  if (Object.keys(reportPayload).includes('title') && reportPayload.title === '') errors.title = i18n.t('validations.required');
  if (Object.keys(reportPayload).includes('title') && reportPayload.title.length > 70) errors.title = i18n.t('validations.reportTitleExceeded');

  if (Object.keys(errors).length > 0) {
    dispatch(addNewAlert(i18n.t('notifications.fieldValidations'), ALERT_ERROR));
    return { success: false, errors };
  }

  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(reportPayload),
  };

  try {
    dispatch(openLoader());
    const response = await fetch(
        endpoints.alterReport(admin.selectedOrganizationId, reportId), options,
    );
    const { error, status } = await response.json();
    const logoutResult = dispatch(logoutUserOn401(status));
    if (logoutResult) return { success: false };

    if (status !== 200 && error) {
      if (status !== 200 && error && error.description) {
        dispatch(addNewAlert(error.description, ALERT_ERROR));
      }
    } else {
      dispatch(addNewAlert(i18n.t('notifications.reportUpdated'), ALERT_SUCCESS));
      dispatch(getSelectedReport(reportId));
      dispatch(getActivityFeedCreated(reportId));
    }
  } catch (e) {
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
    console.warn(e);
    return { success: false, errors: {} };
  } finally {
    dispatch(closeLoader());
  }

  return { success: true };
};

export const unselectedReport = () => (dispatch) => {
  dispatch({ type: UNSELECT_REPORT });
};

export const addFilesToReport = (reportId, reportPayload) => async (dispatch, getState) => {
  const { user, admin } = getState();
  const { token } = user;
  const formData = new FormData();
  const errors = {};
  const reportPayloadKeys = Object.keys(reportPayload).filter((elem) => (elem !== 'media' && elem !== 'taxonomies'));

  // Validations Comment
  if (reportPayloadKeys.includes('comment') && reportPayload.comment === '') errors.comment = i18n.t('validations.required');
  if (reportPayloadKeys.includes('comment') && reportPayload.comment.length > 2000) errors.comment = i18n.t('validations.reportCommentExceeded');

  if (Object.keys(errors).length > 0) {
    return { success: false, errors };
  }

  reportPayloadKeys.map((elem) => {
    formData.append(elem, reportPayload[elem]);
  });

  const files = reportPayload.media;
  if (files) {
    files.map((elem) => (
        formData.append('media[]', elem)
    ));
  }

  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: formData,
  };

  try {
    dispatch(openLoader());
    const response = await fetch(
        endpoints.updateReportActivity(admin.selectedOrganizationId, reportId), options,
    );
    const { error, status } = await response.json();
    const logoutResult = dispatch(logoutUserOn401(status));
    if (logoutResult) return { success: false };

    if (status !== 200 && error) {
      if (status !== 200 && error && error.description) {
        dispatch(addNewAlert(error.description, ALERT_ERROR));
      }
    } else {
      dispatch(addNewAlert(i18n.t('notifications.reportUpdated'), ALERT_SUCCESS));
      dispatch(getActivityFeedCreated(reportId));
    }
  } catch (e) {
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
    console.warn(e);
    return { success: false, errors: {} };
  } finally {
    dispatch(closeLoader());
  }

  return { success: true };
};

export const getActivityFeedCreated = (reportId) => async (dispatch, getState) => {
  const { user, admin, reports } = getState();
  const { token } = user;
  const { users, selectedOrganizationId } = admin;

  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
  const LIMIT = 30;

  try {
    dispatch(openLoader());
    const response = await fetch(`${endpoints.getReportActivity(selectedOrganizationId, reportId)}?limit=${LIMIT}&offset=0`, options);
    const { error, data, status } = await response.json();

    const logoutResult = dispatch(logoutUserOn401(status));
    if (logoutResult) return { success: false };

    if (status !== 200 && error) {
      if (status !== 200 && error && error.description) {
        dispatch(addNewAlert(error.description, ALERT_ERROR));
      }
    } else {
      const {
        total, activity,
      } = data;
      const fetchedActivity = activity || [];
      let latestIndex = -1;
      let newActivity = fetchedActivity;
      if (reports.activityFeed.length > 0) {
        const latestActivityFeed = reports.activityFeed[0];
        if (!latestActivityFeed) return { success: true };

        latestIndex = fetchedActivity.findIndex((elem) => (
            elem.uuid === latestActivityFeed.uuid
        ));
        if (latestIndex === -1 && reports.activityFeed.length > 0) return { success: true };

        newActivity = fetchedActivity.slice(0, latestIndex);
      }

      const newActivityUsersColors = reports.activityUsersColor;

      const activityWithMedia = newActivity.map((elem) => {
        if (!newActivityUsersColors[elem.author]) {
          const randomColor = hexToRgb(getRandomColor(), 0.3);
          newActivityUsersColors[elem.author] = randomColor;
        }
        const updatedInfo = {
          media: elem.media,
          color: newActivityUsersColors[elem.author],
        };

        if (elem.event === 'edit_assignee') {
          const username = users.find((user) => (user.uuid === elem.data))?.username;
          updatedInfo.data = username;
        }

        return ({
          ...elem,
          ...updatedInfo,
        });
      });

      dispatch({
        type: ADD_ACTIVITY_REPORT,
        payload: {
          newActivityFeed: activityWithMedia,
          colors: newActivityUsersColors,
          hasReachedEnd:
              parseInt(activityWithMedia.length, 10)
              + parseInt(reports.activityFeed.length, 10)
              + parseInt(20, 10) >= parseInt(total, 10),
        },
      });
    }
  } catch (e) {
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
    console.warn(e);
  } finally {
    dispatch(closeLoader());
  }

  return { success: true };
};

export const deleteReport = (reportId) => async (dispatch, getState) => {
  const { user, admin } = getState();
  const { token } = user;

  const options = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    dispatch(openLoader());
    const response = await fetch(
        endpoints.alterReport(admin.selectedOrganizationId, reportId), options,
    );
    const { error, status } = await response.json();
    const logoutResult = dispatch(logoutUserOn401(status));
    if (logoutResult) return { success: false };

    if (status !== 200 && error) {
      if (status !== 200 && error && error.description) {
        dispatch(addNewAlert(error.description, ALERT_ERROR));
        return { success: false };
      }
    } else {
      dispatch(addNewAlert(i18n.t('notifications.reportDeleted'), ALERT_SUCCESS));
      dispatch({ type: DELETE_REPORT, payload: { reportId } });
      history.push({ pathname: '/dashboard', from: history.location.pathname });
    }
  } catch (e) {
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
    console.warn(e);
    return { success: false };
  } finally {
    dispatch(closeLoader());
  }

  return { success: true };
};

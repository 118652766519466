import '../../../../styles/index.scss';
import React, { useEffect, useState } from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import TextInput from '../../../shared/TextInput/TextInput';
import mapDispatchToProps from '../../../../actions/index';
import Button from '../../../shared/Button/Button';
import CustomRadio from '../../../ui/CustomRadio/CustomRadio';

const SINGLE_SELECT = 'single';
const MULTI_SELECT = 'multi';
const TEXT_FIELD = 'text';

function AdminTagGroupForm({
  groupTag, edit = false, onClose, languages, createTagGroup, editTagGroup,
}) {
  const { t } = useTranslation();
  const [groupType, setGroupType] = useState(groupTag?.group_type || SINGLE_SELECT);
  const [errors, setErrors] = useState({});
  const [tagTranslation, setTagTranslation] = useState(
    languages.map((elem) => ({
      value: groupTag ? groupTag[`name_${elem.code}`] || '' : '', text: elem.text, code: elem.code, key: `name_${elem.code}`,
    })),
  );

  const handleTagTranslation = (value, index) => {
    const updatedTranslation = [...tagTranslation];
    updatedTranslation[index].value = value;
    setTagTranslation(updatedTranslation);
  };

  useEffect(() => {
    setTagTranslation(languages.map((elem) => ({
      value: groupTag ? groupTag[`name_${elem.code}`] || '' : '', text: elem.text, code: elem.code, key: `name_${elem.code}`,
    })));
  }, [languages, groupTag]);

  const getFilledTranslations = () => {
    const translationsHash = { name: {} };
    const filledTranslations = tagTranslation.filter((elem) => {
      if (groupTag && groupTag[`name_${elem.code}`]) {
        return elem;
      }
      return elem.value !== '';
    });

    filledTranslations.map((elem) => {
      translationsHash[elem.key] = elem.value;
      translationsHash.name[elem.code] = elem.value;
      return null;
    });

    return translationsHash;
  };

  const prepareFormData = () => ({
    ...groupTag && groupTag,
    ...getFilledTranslations(),
    type: groupType,
  });

  const handleUpdateGroup = async () => {
    const groupData = prepareFormData();
    // Do action

    const result = await editTagGroup(groupData);
    if (result.success) {
      setErrors({});
      onClose();
    } else {
      setErrors(result.errors);
    }
  };

  const handleCreateGroup = async () => {
    const groupData = prepareFormData();

    const result = await createTagGroup(groupData);
    if (result.success) {
      setErrors({});
      onClose();
    } else {
      setErrors(result.errors);
    }
  };

  const onSaveGroup = () => {
    if (groupTag && edit) {
      handleUpdateGroup();
    } else {
      handleCreateGroup();
    }
  };

  return (
    <div className="admin_tagsgroups-form__wrapper">
      <div className="admin_tagsgroups-form__inputfields">
        {tagTranslation.map((elem, index) => (
          <TextInput
            title={`${t('name')} - ${elem.text}`}
            placeholder={`${t('enterNameIn')} - ${elem.text}`}
            value={elem.value}
            hasError={!!errors[elem.code]}
            errorDescription={errors[elem.code]}
            handleChange={(event) => handleTagTranslation(event.target.value, index)}
            key={edit ? `${groupTag.id}-${elem.code}` : `newelem-${elem.code}`}
          />
        ))}
      </div>
      {!edit && (
        <div className="admin_tagsgroups-form__radiogroup">
          <CustomRadio label={t('singleSelect')} value={SINGLE_SELECT} name="group_type" onChange={(e, { value }) => setGroupType(value)} checked={groupType === SINGLE_SELECT} />
          <CustomRadio label={t('multiSelect')} value={MULTI_SELECT} name="group_type" onChange={(e, { value }) => setGroupType(value)} checked={groupType === MULTI_SELECT} />
          <CustomRadio label={t('textField')} value={TEXT_FIELD} name="group_type" onChange={(e, { value }) => setGroupType(value)} checked={groupType === TEXT_FIELD} />
        </div>
      )}
      {
        <div className="admin_tagsgroups-form__buttons">
          <Button text={t('cancel')} secondary medium handler={onClose} />
          <Button text={t('save')} primary medium handler={onSaveGroup} />
        </div>
      }
    </div>
  );
}

const mapStateToProps = ({ common }) => ({
  languages: common.languages,
  defaultLanguage: common.defaultLanguage,
});

AdminTagGroupForm.propTypes = {
  groupTag: t.object.isRequired,
  edit: t.bool,
  onClose: t.func.isRequired,
  languages: t.array.isRequired,
  createTagGroup: t.func.isRequired,
  editTagGroup: t.func.isRequired,
};

AdminTagGroupForm.defaultProps = {
  edit: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminTagGroupForm);

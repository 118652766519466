import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Toggle from '../../../ui/Toggle/Toggle';
import clsx from "clsx";
import SelectBox from "../../../ui/SelectBox/SelectBox";
import TextInput from "../../../shared/TextInput/TextInput";
import CustomTextArea from "../../../shared/CustomTextArea/CustomTextArea";
import CallingNumbers from "../../../../shared/callingNumbers";
import MediaUpload from "../../../shared/MediaUpload/MediaUpload";
import types from "prop-types";
import {connect} from "react-redux";
import mapDispatchToProps from "../../../../actions";

const AdminPublicSiteSettings = (props) => {
    const {t} = useTranslation();

    const [publicSite, setPublicSite] = useState(false);
    const [availableLanguages, setAvailableLanguages] = useState([]);

    useEffect(() => {
        if (props.languages) {
            const formattedLanguages = props.languages.map((lang) => ({
                value: lang.code, text: lang.text, key: lang.code, id: lang.code,
            }));
            setAvailableLanguages(formattedLanguages);
        }
    }, [props.languages]);

    return (
        <div className="admin-settings-section">
            <div className="admin-settings-section__header">
                <h1 className="admin-settings-section__header-title">
                    {t('publicSite')}
                </h1>
            </div>
            <div className="admin-settings__box">
                <div
                    className={clsx('admin-settings__element', {
                        'admin-settings__element-open': publicSite,
                    })}
                >
                    <Toggle
                        value={publicSite}
                        handleChange={() => setPublicSite(!publicSite)}
                        label={t('publicSite')}
                    />
                    {publicSite && (
                        <div className="admin-settings__element__container">
                            <Toggle
                                label={t('mapOnPublicSite')}
                            />
                            <Toggle
                                label={t('grievencePortal')}
                            />
                            <Toggle
                                label={t('timbyBranding')}
                            />
                            <div className="admin-settings__element-publicinputs">
                                <SelectBox
                                    placeholder={t('publicSiteLanguagePlaceholder')}
                                    title={t('publicSiteLanguage')}
                                    options={availableLanguages}
                                />
                                <TextInput
                                    title={t('publicSiteTagline')}
                                    hasTitle
                                    placeholder={t('publicSiteTaglinePlaceholder')}
                                    customInputClass="admin-settings__element-textinput"
                                />
                                <TextInput
                                    title={t('publicSitePrimaryColor')}
                                    hasTitle
                                    placeholder={t('enterColor')}
                                    type="color"
                                    customInputClass=""
                                />
                                <CustomTextArea
                                    title={t('publicSiteAboutUs')}
                                    hasTitle
                                    minRows={3}
                                    placeholder={t('publicSiteAboutUsPlaceholder')}
                                />
                                <TextInput
                                    title={t('publicSiteOrganizationContactName')}
                                    hasTitle
                                    placeholder={t('publicSiteOrganizationContactNamePlaceholder')}
                                    type="text"
                                    customInputClass="admin-settings__element-textinput"
                                />
                                <TextInput
                                    title={t('publicSiteOrganizationContactEmail')}
                                    hasTitle
                                    placeholder={t('publicSiteOrganizationContactEmailPlaceholder')}
                                    type="email"
                                    customInputClass="admin-settings__element-textinput"
                                />
                                <div className="admin-settings__element-emergency-users-title">
                                    {t('publicSiteOrganizationContactPhone')}
                                </div>
                                <div className="admin-settings__element-emergency-contact-input">
                                    <SelectBox
                                        placeholder="+44"
                                        value={null}
                                        options={CallingNumbers}
                                        search
                                        handleChange={() => {}}
                                    />
                                    <TextInput
                                        placeholder={t('publicSiteOrganizationContactPhonePlaceholder')}
                                        value={null}
                                        hasTitle={false}
                                        customInputClass="admin-settings__element-textinput"
                                        handleChange={() => {}}
                                    />
                                </div>
                                <div className="admin-settings__element-public-uploads">
                                    <div className="admin-settings__element-public-uploads-container">
                                        <div className="admin-settings__element-public-uploads-title">
                                            {t('publicSiteAddCoverImage')}
                                        </div>
                                        <MediaUpload
                                            addFile={() => {}}
                                            removeFile={() => {}}
                                            files={[]}
                                            title={t('reportDragMedia')}
                                            subtitle={t('reportSupportMedia')}
                                        />
                                    </div>
                                    <div className="admin-settings__element-public-uploads-container">
                                        <div className="admin-settings__element-public-uploads-title">
                                            {t('publicSiteAddAboutUsImage')}
                                        </div>
                                        <MediaUpload
                                            addFile={() => {}}
                                            removeFile={() => {}}
                                            files={[]}
                                            title={t('reportDragMedia')}
                                            subtitle={t('reportSupportMedia')}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ common }) => ({
    languages: common.languages,
    defaultLanguage: common.defaultLanguage,
});

AdminPublicSiteSettings.displayName = 'AdminPublicSiteSettings';

AdminPublicSiteSettings.propTypes = {
    defaultLanguage: types.string.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminPublicSiteSettings);
import '../../../styles/index.scss';
import { TextArea } from 'semantic-ui-react';
import React, { useEffect, useState } from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import mapDispatchToProps from '../../../actions/index';
import CustomRadio from '../../ui/CustomRadio/CustomRadio';
import Button from '../../shared/Button/Button';
import MessageCustomSelect from './MessageCustomSelect/MessageCustomSelect';
import TextInput from '../../shared/TextInput/TextInput';
import SelectBox from '../../ui/SelectBox/SelectBox';
import { MESSAGE_EVERYONE, MESSAGE_SELECT_USERS, MESSAGE_SELECT_ROLES } from '../../../shared/consts';
import clsx from 'clsx';

function MesageForm(props) {
  const [messageSubject, setMessageSubject] = useState('');
  const [message, setMessage] = useState('');
  const [messageDestiny, setMessageDestiny] = useState(MESSAGE_EVERYONE);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const [errors, setErrors] = useState([]);
  const { t } = useTranslation();
  const [selectUsers, setSelectUsers] = useState([]);
  const [selectRoles, setSelectRoles] = useState([]);

  useEffect(() => {
    if (props.users.length === 0) props.getUsers();
    if (props.roles.length === 0) props.getRoles();
  }, []);

  useEffect(() => {
    const users = props.users.map((elem) => (
      {
        text: elem.username, id: elem.uuid, value: elem.uuid, key: elem.uuid,
      }
    ));
    setSelectUsers(users);
  }, [props.users]);

  useEffect(() => {
    const roles = props.roles.map((elem) => (
      {
        text: elem.name, id: elem.id, value: elem.id, key: elem.id,
      }
    ));
    setSelectRoles(roles);
  }, [props.roles]);

  const getChecksForSubmission = () => {
    if (messageDestiny === MESSAGE_SELECT_USERS) {
      return (selectedUsers.length === 0 || message === '' || messageSubject === '');
    }
    if (messageDestiny === MESSAGE_SELECT_ROLES) {
      return (selectedRole === null || message === '' || messageSubject === '');
    }
    return message === '' || messageSubject === '';
  };

  const createNewMessage = async () => {
    let recipients = selectedUsers;
    if (messageDestiny === MESSAGE_SELECT_ROLES) {
      recipients = selectedRole;
    }

    const messageData = {
      subject: messageSubject,
      message,
      type: messageDestiny,
      sent_date: format(new Date(), 'Y-L-dd H:m:s'),
      recipients,
    };

    const result = await props.createNewMessage(messageData);
    if (result.success) {
      setMessageSubject('');
      setMessage('');
      setMessageDestiny(MESSAGE_EVERYONE);
      setSelectedUsers([]);
      setErrors([]);
      setSelectedRole(null);
    } else {
      setErrors(result.errors);
    }
  };

  const updateSubjectField = (value) => {
    const newErrors = errors;
    if (value.length > 30) {
      errors.subject = t('validations.messageSubjectExceeded');
    } else {
      delete errors.subject;
    }
    setErrors(newErrors);
    setMessageSubject(value);
  };

  const updateContentField = (value) => {
    const newErrors = errors;
    if (value.length > 500) {
      errors.message = t('validations.messageTextExceeded');
    } else {
      delete errors.message;
    }
    setErrors(newErrors);
    setMessage(value);
  };

  return (
    <div className="message-form">
      <p className="messages-container__title">{t('send')}</p>
      <TextInput
        title={t('subject')}
        placeholder={t('enterSubject')}
        value={messageSubject}
        hasError={!!errors.subject}
        errorDescription={errors.subject}
        handleChange={(event) => updateSubjectField(event.target.value)}
      />
      <div className="message-form-textarea-box">
        <TextArea
          value={message}
          className={clsx(
            'message-form-textarea',
            {
              'message-form-textarea-error': !!errors.message,
            },
          )}
          placeholder={t('enterMessage')}
          onChange={((e, { value }) => { updateContentField(value); })}
        />
        {!!errors.message && errors.message && (
          <span className="text-input__error" dangerouslySetInnerHTML={{ __html: errors.message }} />
        )}
      </div>
      <div className="message-form-radiogroup">
        <CustomRadio
          label={t('sendToEveryone')}
          value={MESSAGE_EVERYONE}
          name="group_type"
          checked={messageDestiny === MESSAGE_EVERYONE}
          onChange={(e, { value }) => setMessageDestiny(value)}
        />
        <CustomRadio
          label={t('sendByUsers')}
          value={MESSAGE_SELECT_USERS}
          name="group_type"
          checked={messageDestiny === MESSAGE_SELECT_USERS}
          onChange={(e, { value }) => setMessageDestiny(value)}
        />
        <CustomRadio
          label={t('sendByRoles')}
          value={MESSAGE_SELECT_ROLES}
          name="group_type"
          checked={messageDestiny === MESSAGE_SELECT_ROLES}
          onChange={(e, { value }) => setMessageDestiny(value)}
        />
      </div>
      {messageDestiny === MESSAGE_SELECT_USERS && (
        <MessageCustomSelect
          options={selectUsers}
          selectedOptions={selectedUsers}
          changeSelectedOptions={setSelectedUsers}
          placeholder={t('selectUsers')}
        />
      )}
      {messageDestiny === MESSAGE_SELECT_ROLES && (
        <div className={'message-form-userselect'}>
          <SelectBox
            placeholder={t('selectRole')}
            value={selectedRole}
            options={selectRoles}
            handleChange={(e, { value }) => setSelectedRole(value)}
          />
        </div>
      )}
      <div className="message-form-button">
        <Button text={t('send')} disabled={getChecksForSubmission()} primary medium handler={() => createNewMessage()} />
      </div>
    </div>
  );
}

const mapStateToProps = ({
  admin,
}) => ({
  users: admin.users,
  roles: admin.roles,
});

MesageForm.propTypes = {
  createNewMessage: t.func.isRequired,
  users: t.array.isRequired,
  roles: t.array.isRequired,
  getUsers: t.func.isRequired,
  getRoles: t.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(MesageForm);

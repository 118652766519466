import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import type from 'prop-types';
import '../../../styles/index.scss';
import { useTranslation } from 'react-i18next';
import mapDispatchToProps from '../../../actions/index';
import AdminTagsGroupContainer from './AdminTagGroupContainer';
import AdminModal from '../AdminModal/AdminModal';
import AdminTagGroupForm from './forms/AdminTagGroupForm';

const AdminTags = (props) => {
  const { t } = useTranslation();
  const { groupTags } = props;
  const [open, setOpen] = useState(false);
  const [selectedGroupTag, setSelectedGroupTag] = useState(null);

  useEffect(() => {
    document.title = 'Timby - Tags';
  }, []);

  useEffect(() => {
    props.getTags(props.selectedOrganization);
  }, [props.selectedOrganization]);

  const onCloseModal = () => {
    setSelectedGroupTag(null);
    setOpen(false);
  };

  const onEditGroupTag = (groupTag) => {
    setSelectedGroupTag(groupTag);
    setOpen(true);
  };

  const GROUP_TAG_TYPES = {
    single: 'singleSelect',
    multi: 'multiSelect',
    text: 'textField',
  };

  return (
    <div className="admin-container__content whitepagescrollbar">
      {/* Title */}
      <div className="max-body-width">

        <div className="admin-container-header">
          <h1 className="admin-container-header-title">
            {t('myTags')}
          </h1>
          {/* Tag Group Form */}
          <div className="admin-container-header-buttons">
            <AdminModal
              actionName={t('addTagGroup')}
              onClose={() => onCloseModal()}
              open={open}
              onOpen={() => setOpen(true)}
              header={selectedGroupTag ? t('editTagGroup') : t('createTagGroup')}
            >
              <AdminTagGroupForm
                edit={!!selectedGroupTag}
                onClose={onCloseModal}
                groupTag={selectedGroupTag}
              />
            </AdminModal>
          </div>
        </div>
        {/* Tags Groups */}
        {groupTags.map((elem) => {
          const groupTagName = (elem[`name_${props.defaultLanguage}`] || elem.name_en || '');
          return (
            <AdminTagsGroupContainer
              key={elem.id}
              onEdit={() => onEditGroupTag(
                elem,
              )}
              id={elem.id}
              formTitle={groupTagName}
              type={elem.type}
              title={`${groupTagName} - ${t(GROUP_TAG_TYPES[elem.type])}`}
              tags={elem?.children || []}
            />
          );
        })}
      </div>
    </div>
  );
};

const mapStateToProps = ({ common, admin }) => ({
  groupTags: admin.groupTags,
  selectedOrganization: admin.selectedOrganizationId,
  defaultLanguage: common.defaultLanguage,
});

AdminTags.propTypes = {
  selectedOrganization: type.string.isRequired,
  defaultLanguage: type.string.isRequired,
  groupTags: type.array.isRequired,
  getTags: type.func.isRequired,
};

AdminTags.displayName = 'AdminTagsContainer';

export default connect(mapStateToProps, mapDispatchToProps)(AdminTags);

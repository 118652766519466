import '../../../styles/index.scss';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import mapDispatchToProps from '../../../actions/index';
import {isUserAdmin, isUserOrganizationAdmin} from '../../../utils';
import {NavLink} from "react-router-dom";
import { Tab } from 'semantic-ui-react';
import AdminLanguages from './AdminLanguages/AdminLanguages';
import AdminMap from './AdminMap/AdminMap';
import AdminDashboardSettings from "./AdminDashboardSettings/AdminDashboardSettings";
import AdminAppSettings from "./AdminAppSettings/AdminAppSettings";
import AdminPublicSiteSettings from "./AdminPublicSiteSettings/AdminPublicSiteSettings";

const AdminSettings = (props) => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = 'Timby - Additional Settings';
  }, []);

  const getPanes = () => [
    (isUserAdmin(props.userInfo) || isUserOrganizationAdmin(props.userInfo)) && {
      menuItem: {
        as: NavLink,
        content: t('languages'),
        to: '/admin/settings/languages',
        exact: true,
        key: 'languages',
      },
      className: 'admin_subnav_item',
      render: () => <AdminLanguages />,
    },
    (isUserAdmin(props.userInfo) || isUserOrganizationAdmin(props.userInfo)) && {
      menuItem: {
        as: NavLink,
        content: t('map'),
        to: '/admin/settings/map',
        exact: true,
        key: 'map',
      },
      className: 'admin_subnav_item',
      render: () => <AdminMap />,
    },
    (isUserAdmin(props.userInfo) || isUserOrganizationAdmin(props.userInfo)) && {
      menuItem: {
        as: NavLink,
        content: t('dashboardConfiguration'),
        to: '/admin/settings/dashboard',
        exact: true,
        key: 'dashboard',
      },
      className: 'admin_subnav_item',
      render: () => <AdminDashboardSettings />,
    },
    (isUserAdmin(props.userInfo) || isUserOrganizationAdmin(props.userInfo)) && {
      menuItem: {
        as: NavLink,
        content: t('appConfiguration'),
        to: '/admin/settings/app',
        exact: true,
        key: 'app',
      },
      className: 'admin_subnav_item',
      render: () => <AdminAppSettings />,
    },
    (isUserAdmin(props.userInfo) || isUserOrganizationAdmin(props.userInfo)) && {
      menuItem: {
        as: NavLink,
        content: t('publicSite'),
        to: '/admin/settings/public-site',
        exact: true,
        key: 'publicSite',
      },
      className: 'admin_subnav_item',
      render: () => <AdminPublicSiteSettings />,
    },
  ];
  const panes = getPanes();

  const panesList = [
    '/admin/settings/languages',
    '/admin/settings/map',
    '/admin/settings/dashboard',
    '/admin/settings/app',
    '/admin/settings/public-site',
  ];

  let currentPanel = panesList.indexOf(props.currentPage);
  if (currentPanel === -1) currentPanel = 0;

  return (
      <div className="admin-container__content whitepagescrollbar">
          <Tab className="admin-settings" menu={{ className: 'admin-settings-subnav', vertical: true }} defaultActiveIndex={currentPanel} panes={panes} />
      </div>
  );
};

const mapStateToProps = ({ user, }) => ({
  userInfo: user.userInfo,
});

AdminSettings.displayName = 'AdminSettings';

export default connect(mapStateToProps, mapDispatchToProps)(AdminSettings);

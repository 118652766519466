import '../../../../styles/index.scss';
import { useState } from 'react';

import { ReactComponent as DownloadIcon } from '../../../../assets/images/download.svg'
import { ReactComponent as FolderIcon } from '../../../../assets/images/folder_icon.svg'
import { ReactComponent as DeleteIcon } from '../../../../assets/images/delete.svg'
import { ReactComponent as CloseIcon } from '../../../../assets/images/close.svg'

import RoundedIcon from '../../../shared/RoundedIcon/RoundedIcon';
import Button from '../../../shared/Button/Button';
import { useTranslation } from 'react-i18next';
import AdminModal from '../../AdminModal/AdminModal';
import FolderModalContent from '../FolderModal/FolderModalContent';

function ReportViewHeader(props) {
  const { t } = useTranslation();
  const [folderModal, setFolderModal] = useState(false);
  return (
    <>
      <div className='report-view__container-header'>
        <div className='report-view__container-header-icons'>
          <RoundedIcon hover border handler={() => setFolderModal(true)} SvgComponent={FolderIcon}  />
          {props.editable && (<RoundedIcon hover border handler={() => props.onDelete()} SvgComponent={DeleteIcon}  />)}
          <RoundedIcon hover border handler={() => {}} SvgComponent={DownloadIcon} />
        </div>
        <div className='report-view__container-header-close'>
          {!props.editMode && (
            <Button text={t('reportSeeActivity')} secondary medium handler={props.goToActivityFeed}/>
          )}
          <RoundedIcon hover border handler={props.goBackToDashboard} SvgComponent={CloseIcon} />
        </div>
      </div>
      <AdminModal
        hasTrigger={false}
        header={'Add report to folders'}
        customModal={'folder-create-modal report-activityfeed-submit-modal-mini'}
        onClose={() => setFolderModal(false)}
        open={folderModal}
        scrolling={false}
        onOpen={() => setFolderModal(true)}
      >
        <FolderModalContent  onClose={() => setFolderModal(false)}/>
      </AdminModal>
    </>
  );
}

export default ReportViewHeader;

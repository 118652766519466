import '../../../styles/index.scss';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import t from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import TextInput from '../../shared/TextInput/TextInput';
import Button from '../../shared/Button/Button';
import Toggle from '../../ui/Toggle/Toggle';
import mapDispatchToProps from '../../../actions/index';
import SelectBox from '../../ui/SelectBox/SelectBox';
import { isUserAdmin } from '../../../utils';

const AdminUserListContent = (props) => {
  const { t } = useTranslation();
  const { userInfo, edit = true, onClose } = props;
  const [username, setUsername] = useState(userInfo?.username || '');
  const [firstName, setFirstName] = useState(userInfo?.firstname || '');
  const [lastName, setLastName] = useState(userInfo?.lastname || '');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [email, setEmail] = useState(userInfo?.email || '');
  const [role, setRole] = useState(userInfo?.role || '');
  const [enable2FA, setEnable2FA] = useState(userInfo?.secondfactor === 'email' || false);

  useEffect(() => {
    setUsername(userInfo?.username || '');
    setFirstName(userInfo?.firstname || '');
    setLastName(userInfo?.lastname || '');
    setPassword('');
    setErrors([]);
    setOrganizations(userInfo?.organizations || []);
    setEmail(userInfo?.email || '');
    setRole(userInfo?.role?.id || '');
    setEnable2FA(userInfo?.secondfactor === 'email' || false);
  }, [userInfo]);

  const handleUpdateUser = async () => {
    const userData = {
      firstname: firstName || undefined,
      lastName: lastName || undefined,
      password: password || undefined,
      username: username || undefined,
      email: email || undefined,
      role_id: role || 1,
      secondfactor: enable2FA ? 'email' : 'none',
    };
    const result = await props.updateUser(username, userData);
    if (result.errors) setErrors(result.errors);
    if (result.success) onClose();
  };

  const organizationOptions = props?.organizations?.map((elem) => (
    { key: elem.id, value: elem.id, text: elem.name }
  ));

  const rolesOptions = props.roles.filter((elem) => (elem.name !== 'Administrator')).map((elem) => (
    { key: elem.id, value: elem.id, text: elem.name }
  ));

  const handleCreateUser = async () => {
    const userData = {
      firstname: firstName || '',
      lastname: lastName || '',
      password: password || '',
      email: email || '',
      username: username || '',
      role_id: role || '',
      secondfactor: enable2FA ? 'email' : 'none',
    };

    userData.organization_id = isUserAdmin(props.userLoggedIn)
      ? organizations || undefined
      : [props.selectedOrganization];
    const result = await props.createUser(userData);

    if (result.errors) setErrors(result.errors);
    if (result.success) onClose();
  };

  return (
    <div className={clsx('user-list-content__wrapper', {
      'user-list-content__wrapper--create': !edit,
    })}
    >
      <TextInput
        title={t('firstname')}
        placeholder={t('enterFirstName')}
        value={firstName}
        hasError={!!errors.firstname}
        errorDescription={errors.firstname}
        handleChange={(event) => setFirstName(event.target.value)}
      />
      <TextInput
        title={t('lastname')}
        placeholder={t('enterLastName')}
        value={lastName}
        hasError={!!errors.lastname}
        errorDescription={errors.lastname}
        handleChange={(event) => setLastName(event.target.value)}
      />
      <TextInput
        title={t('username')}
        disabled={edit}
        placeholder={t('usernamePlaceholder')}
        value={username}
        hasError={!!errors.username}
        errorDescription={errors.username}
        handleChange={(event) => setUsername(event.target.value)}
      />
      <TextInput
        title={t('password')}
        placeholder={!edit ? t('passwordPlaceholder') : '••••••••••••••••' }
        type="password"
        hasError={!!errors.password}
        errorDescription={errors.password}
        value={password}
        handleChange={(event) => setPassword(event.target.value)}
      />
      <SelectBox
        title={t('role')}
        placeholder={t('enterUserRole')}
        value={role}
        hasError={!!errors.role}
        errorDescription={errors.role}
        handleChange={(e, { value }) => setRole(value)}
        options={rolesOptions}
      />
      {!edit && isUserAdmin(props.userLoggedIn)
        && (
        <SelectBox
          title={t('organizations')}
          multiple
          placeholder={t('userOrganizationPlaceholder')}
          value={organizations}
          hasError={!!errors.organizations}
          errorDescription={errors.organizations}
          handleChange={(e, { value }) => setOrganizations(value)}
          options={organizationOptions}
        />
        )}
      <TextInput
        title={t('email')}
        placeholder={t('enterEmailPlaceholder')}
        type="email"
        value={email}
        hasError={!!errors.email}
        errorDescription={errors.email}
        handleChange={(event) => setEmail(event.target.value)}
      />
      <div className={clsx('user-list-content__actions', {
        'user-list-content__actions--create': !edit,
      })}
      >
        {edit && (
          <div className="user-list-content__actions-createbutton">
            <Button
              text={t('save')}
              primary
              medium
              handler={() => handleUpdateUser()}
            />
          </div>
        )}

        <Toggle
          label={t('enable2FA')}
          handleChange={() => setEnable2FA(!enable2FA)}
          value={enable2FA}
        />
      </div>
      {!edit
        && (
        <div className="admin-modal__actions">
          <Button text={t('cancel')} secondary medium handler={onClose} />
          <Button text={t('save')} primary medium handler={() => handleCreateUser()} />
        </div>
        )}
    </div>
  );
};

const mapStateToProps = ({ user, admin }) => ({
  userLoggedIn: user.userInfo,
  selectedOrganization: admin.selectedOrganizationId,
});

AdminUserListContent.propTypes = {
  userInfo: t.object.isRequired,
  edit: t.bool,
  selectedOrganization: t.string.isRequired,
  onClose: t.func.isRequired,
  updateUser: t.func.isRequired,
  createUser: t.func.isRequired,
  userLoggedIn: t.object.isRequired,
  organizations: t.array.isRequired,
  roles: t.array,

};

AdminUserListContent.defaultProps = {
  edit: true,
  roles: [],
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminUserListContent);

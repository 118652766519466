import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import t from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Icon } from 'semantic-ui-react';
import '../../../styles/index.scss';
import mapDispatchToProps from '../../../actions/index';
import Collapsible from '../../ui/Collapsible/Collapsible';
import SelectBox from '../../ui/SelectBox/SelectBox';
import { history } from '../../../index';
import Button from '../../shared/Button/Button';
import FolderElement from '../FolderElement/FolderElement';
import CustomDatePicker from '../../shared/CustomDatePicker/CustomDatePicker';
import DynamicTagSelect from '../../shared/DynamicTagSelect/DynamicTagSelect';
import { ReactComponent as CreateFolder } from '../../../assets/images/create_folder.svg';
import { isUserReader } from '../../../utils';
import { formatDate } from '../../../utils/dateUtils';
import AdminModal from '../AdminModal/AdminModal';
import CreateFolderContent from './CreateFolderContent';

function FiltersPanel({
  showPanel, handleShow, createButton,
  selectedOrganization, userInfo, users, defaultLanguage,
  groupTags, selectedTags, getTags, getUsers, filterTaxonomies,
  folders, getFolders, selectFolder, selectedFolder,
  getReports, applyFilters, resetFilters,
}) {
  const { t } = useTranslation();

  const [showFolders, setShowFolders] = useState(true);
  const [showFolderMenu, setShowFolderMenu] = useState(false);
  const [createFolder, setCreateFolder] = useState(false);
  const [dateStart, setDateStart] = useState(null);
  const [dateEnd, setDateEnd] = useState(null);
  const renderContent = (elem) => elem.content;
  const renderHeader = (elem) => elem.header;

  // filters count (excluding kwd search, but should we?)
  const nbFilters = Object.keys(selectedTags).filter(k => k !== 'search').length;

  const selectUsers = users.map((user) => ({
    text: user.username,
    key: user.username,
    value: user.username,
  }));

  const selectGroupTags = groupTags;

  const LIFECYCLES = [
    { text: t('lifecycle_new'), key: 'new', value: 'new' },
    { text: t('lifecycle_started'), key: 'inprogress', value: 'inprogress' },
    { text: t('lifecycle_closed'), key: 'closed', value: 'closed' },
  ];

  const URGENCY_STATES = [
    { text: t('urgency_low'), value: 'minor', key: 'minor' },
    { text: t('urgency_medium'), value: 'moderate', key: 'moderate' },
    { text: t('urgency_high'), value: 'critical', key: 'critical' },
  ];

  const VISIBILITY = [
    { text: t('visibility_public'), value: 'public', key: 'public' },
    { text: t('visibility_private'), value: 'private', key: 'private' },
  ];

  const VERIFIED = [
    { text: t('status_verified'), value: '1', key: 'verified' },
    { text: t('status_notverified'), value: '0', key: 'unverified' },
  ];

  const handleApplyFilters = () => {
    applyFilters();
    console.log(selectedTags);
    getReports();
  };

  const handleResetFilters = () => {
    resetFilters();
    getReports();
  };

  const handleChangeDateStart = (value) => {
    setDateStart(value);
    let date = formatDate(value);
    filterTaxonomies('dateStart', date.toString());
  };

  const handleChangeDateEnd = (value) => {
    setDateEnd(value);
    let date = formatDate(value);
    filterTaxonomies('dateEnd', date.toString());
  };

  useEffect(() => {
    getTags(selectedOrganization);
    if (users.length === 0) getUsers();
    if (folders.length === 0) getFolders();
  }, []);

  const elements = [
    {
      header:
        <>
          <Icon name="chevron right" />
          {t('date')}
        </>,
      content:
        <div className="filters-panel__filters-date">
          <div className="filters-panel__filters-startdate">
            <CustomDatePicker
              value={dateStart}
              clearable
              handleChange={handleChangeDateStart}
              placeholder={t('startDate')}
              selectsStart
              startDate={dateStart}
              maxDate={dateEnd || new Date()}
              endDate={dateEnd || null }
            />
          </div>
          <div className="filters-panel__filters-enddate">
            <CustomDatePicker
              value={dateEnd}
              clearable
              handleChange={handleChangeDateEnd}
              placeholder={t('endDate')}
              minDate={dateStart}
              selectsEnd
              startDate={dateStart}
              maxDate={dateEnd || new Date()}
              endDate={dateEnd || null }
            />
          </div>
        </div>,
    },
    {
      header:
  <>
    <Icon name="chevron right" />
    {t('reportStatus')}
  </>,
      content:
  <>
    <SelectBox
      title={t('reportLifecycle')}
      placeholder={t('reportLifecycle')}
      options={LIFECYCLES}
      clearable
      multiple
      value={selectedTags.lifecycle || []}
      handleChange={(e, { value }) => filterTaxonomies('lifecycle', value)}
    />
    <SelectBox
      title={t('urgency')}
      placeholder={t('urgency')}
      options={URGENCY_STATES}
      clearable
      multiple
      value={selectedTags.urgency || []}
      handleChange={(e, { value }) => filterTaxonomies('urgency', value)}
    />
    <SelectBox
      title={t('verificationStatus')}
      placeholder={t('verificationStatus')}
      options={VERIFIED}
      clearable
      multiple
      value={selectedTags.verified || []}
      handleChange={(e, { value }) => filterTaxonomies('verified', value)}
    />
    <SelectBox
        title={t('visibility')}
        placeholder={t('visibility')}
        options={VISIBILITY}
        clearable
        multiple
        value={selectedTags.visibility || []}
        handleChange={(e, { value }) => filterTaxonomies('visibility', value)}
    />
  </>,
    },
    {
      header:
  <>
    <Icon name="chevron right" />
    {t('reportAssignment')}
  </>,
      content:
  <>
    <SelectBox
      handleChange={(e, { value }) => filterTaxonomies('author', value)}
      title={t('reportAuthor')}
      placeholder={t('reportAuthor')}
      clearable
      multiple
      value={selectedTags.author || []}
      options={selectUsers}
    />
    <SelectBox
      title={t('reportAssignee')}
      placeholder={t('reportAssignee')}
      options={selectUsers}
      clearable
      multiple
      value={selectedTags.assignee || []}
      handleChange={(e, { value }) => filterTaxonomies('assignee', value)}
    />
    <SelectBox
      title={t('organization')}
      placeholder={t('organization')}
      options={userInfo.organizations.map((org) => ({
        text: org.name,
        value: org.id,
        key: org.id,
      }))}
      clearable
      multiple
      value={selectedTags.organization || []}
      handleChange={(e, { value }) => filterTaxonomies('organization', value)}
    />
  </>,
    },
    selectGroupTags.length > 1 ? {
      header:
        <>
          <Icon name="chevron right" />
          {t('myTags')}
        </>,
      content:
        <>
          {
            selectGroupTags.map((taxonomy,i) => (
              <DynamicTagSelect
                key={i}
                tagGroup={taxonomy}
                alwaysMulti
                onChange={filterTaxonomies}
                selectedState={selectedTags}
                defaultLanguage={defaultLanguage}
                title={(taxonomy[`name_${defaultLanguage}`] || taxonomy.name_en)}
                placeholder={(taxonomy[`name_${defaultLanguage}`] || taxonomy.name_en)}
              />
            ))
          }
        </>,
    } : null,
  ];

  const filledElements = elements.filter((elem) => elem !== null);
  return (
    <div className={clsx('filters-panel__wrapper', {
      'filters-panel__wrapper--hidden': !showPanel,
    })}
    >
      <div className="filters-panel__container">
        <div className="filters-panel__navigation">
          <div className="filters-panel__title">
            {t('reportsTitle')}
          </div>
          <div
            className="filters-panel__toggle"
            onClick={handleShow}
            role="button"
            onKeyDown={() => handleShow}
            tabIndex={-1}
          >
            <Icon name="caret left" />
          </div>
        </div>
        <div className="filters-panel__filters-list defaultscrollbar">
          <div className="filters-panel__filters-list__header">
            <div className='filters-panel__filters-list__header--iconcontainer'>
              <div className="filters-panel__filters-list__header--icon" />
              {t('reportFilter')} ({nbFilters})
            </div>
            <div className='filters-panel__filters-list__header--buttoncontainer'>
              <Button
                primary
                small
                text={t('apply')}
                handler={handleApplyFilters}
              />
              <Button
                ghost
                small
                text={t('reset')}
                handler={handleResetFilters}
                className="filters-panel__filters-list__header--resetlink"
              />
            </div>
          </div>
          <Collapsible
            elements={filledElements}
            renderHeader={renderHeader}
            renderContent={renderContent}
          />
          <div className="folders__container">
            <div className="folders__header">
              {t('reportFolders')}
              <div className="folders__header__icons">

                <Icon
                  className="folders__header__ellipsis"
                  name="ellipsis horizontal"
                  onClick={() => setShowFolderMenu(!showFolderMenu)}
                />

                <Icon
                  className="folders__header__arrow"
                  name={showFolders ? 'chevron down' : 'chevron right'}
                  onClick={() => setShowFolders(!showFolders)}
                />

              </div>
              {showFolderMenu && (
                <div className="folders__menu">
                  <div
                    role="button"
                    className="folders__menu__add-folder"
                    onClick={() => setCreateFolder(true)}
                    onKeyDown={() => setCreateFolder(true)}
                    tabIndex={-1}
                  >
                    <>
                      <span>{t('createFolder')}</span>
                      <CreateFolder />
                    </>
                  </div>
                </div>
              )}
            </div>
            {showFolders && (
              <div className={clsx('folders__body', {
                'folders__body__menu-open': showFolderMenu,
              })}
              >
                {folders.map((folder) => (
                  <FolderElement
                    title={folder.name}
                    color={folder.color}
                    selected={selectedFolder ? folder.uuid === selectedFolder?.uuid : false}
                    key={`folders-${folder.uuid}`}
                    onClick={() => { selectFolder(folder.uuid); }}
                  />
                ))}
              </div>
            )}
          </div>
        </div>

        <div className="filters-panel__folders-list" />
      </div>
      {createButton && !isUserReader(userInfo) && (
        <div className="filters-panel__filter-options">
          <Button large primary text={t('newReport')} handler={() => history.push({ pathname: '/new-report', from: history.location.pathname })} />
        </div>
      )}
      <AdminModal
        hasTrigger={false}
        header="Create folder"
        customModal="folder-create-modal"
        onClose={() => setCreateFolder(false)}
        open={createFolder}
        scrolling={false}
        onOpen={() => setCreateFolder(true)}
      >
        <CreateFolderContent
          onClose={() => { setCreateFolder(false); }}
        />
      </AdminModal>
    </div>
  );
}

FiltersPanel.propTypes = {
  showPanel: t.bool.isRequired,
  handleShow: t.func.isRequired,
  createButton: t.bool,
  selectedOrganization: t.string.isRequired,
  userInfo: t.object.isRequired,
  users: t.array.isRequired,
  defaultLanguage: t.string.isRequired,
  groupTags: t.array.isRequired,
  selectedTags: t.object.isRequired,
  getTags: t.func.isRequired,
  getUsers: t.func.isRequired,
  filterTaxonomies: t.func.isRequired,
  folders: t.array.isRequired,
  getFolders: t.func.isRequired,
  selectFolder: t.func.isRequired,
  selectedFolder: t.object,
};

FiltersPanel.defaultProps = {
  createButton: true,
};

const mapStateToProps = ({
  common, admin, user, tags, folders,
}) => ({
  defaultLanguage: common.defaultLanguage,
  selectedTags: tags.selectedTags,
  groupTags: tags.groupTags,
  selectedOrganization: admin.selectedOrganizationId,
  selectedFolder: folders.selectedFolder,
  folders: folders.folders,
  users: admin.users,
  userInfo: user.userInfo,
});

export default connect(mapStateToProps, mapDispatchToProps)(FiltersPanel);

import '../../../../styles/index.scss';
import React, { useEffect, useState } from 'react';
import Autosuggest from 'react-autosuggest';
import { connect } from 'react-redux';
import t from 'prop-types';
import { useTranslation } from 'react-i18next';

import mapDispatchToProps from '../../../../actions';
import { history } from '../../../../index';
import { ReactComponent as CloseIcon } from '../../../../assets/images/close.svg';

function SearchReports({
  applyFilters,
  filterTaxonomies,
  reports,
  getReports,
  selectedTags,
}) {
  const [value, setValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    setValue(selectedTags.search || '');
  }, [selectedTags]);

  const { t } = useTranslation();

  const getSuggestionValue = (suggestion) => suggestion.title;

  const renderSuggestion = (suggestion) => (
    <div>
      <div className="suggestion__title">{suggestion.title}</div>
      <div className="suggestion__description">{suggestion.content}</div>
    </div>
  );

  const renderSuggestionsContainer = ({ containerProps, children }) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div {...containerProps}>
      {children}
      <div role="button" className="footer" onClick={handleViewAll} onKeyPress={handleViewAll} tabIndex="0">
        {t('seeAllResults')}
      </div>
    </div>
  );

  const handleChange = (event, { newValue }) => {
    setValue(newValue);
  };

  const handleViewAll = () => {
    applyChange(value);
    getReports();
    onSuggestionsClearRequested();
  };

  const applyChange = (value) => {
    filterTaxonomies('search', value.trim().toLowerCase(), total);
    applyFilters();
  };

  // Clicking on a suggestion
  const onSelectSuggestion = (event, { suggestion }) => {
    applyChange(suggestion.title);
    history.push({ pathname: `/report/${suggestion.uuid}`, from: history.location.pathname });
  };

  // Submit form
  const onFormSubmit = (event) => {
    event.preventDefault();
    applyChange(value);
    getReports();
  };

  // Clear field icon
  const onClear = () => {
    setValue('');

    // @todo: think about it (clearing the field versus the form, reverting value, etc.)
    if (selectedTags.search) {
      applyChange('');
      getReports();
    }
  };

  const onSuggestionsFetchRequested = async ({ value }) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    let searchResults = inputLength < 2 ? reports : reports.filter(el =>
        el.title.toLowerCase().indexOf(inputValue) !== -1
        || el.content.toLowerCase().indexOf(inputValue) !== -1
    );
    searchResults = searchResults.slice(0, 5);
    setSuggestions([...searchResults]);
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
    setTotal(0);
  };

  const inputProps = {
    onChange: handleChange,
    value,
    placeholder: t('reportSearch'),
  };

  const theme = {
    container: 'autosuggest__container',
    containerOpen: 'autosuggest__container--open',
    input: 'autosuggest__input',
    inputOpen: 'autosuggest__input--open',
    inputFocused: 'autosuggest__input--focused',
    suggestionsContainer: 'suggestions-container',
    suggestionsContainerOpen: 'suggestions-container--open',
    suggestionsList: 'suggestions-list',
    suggestion: 'suggestion',
    suggestionFirst: 'suggestion--first',
    suggestionHighlighted: 'suggestion--highlighted',
    sectionContainer: 'suggestions-list__section-container',
    sectionContainerFirst: 'suggestions-list__section-container--first',
    sectionTitle: 'suggestions-list__section-title',
  };

  return (
    <form className="autosuggest-wrapper" onSubmit={onFormSubmit}>
      <Autosuggest
        getSuggestionValue={getSuggestionValue}
        inputProps={inputProps}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        onSuggestionSelected={onSelectSuggestion}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        renderSuggestion={renderSuggestion}
        renderSuggestionsContainer={renderSuggestionsContainer}
        suggestions={suggestions}
        theme={theme}
      />
      {value && (
        <CloseIcon className="icon clear" onClick={onClear} />
      )}
    </form>
  );
}

SearchReports.propTypes = {
  selectedTags: t.shape({
    search: t.string,
  }).isRequired,
  reports: t.array.isRequired,
  applyFilters: t.func.isRequired,
  filterTaxonomies: t.func.isRequired,
  getReports: t.func.isRequired,
};

const mapStateToProps = ({ tags, reports }) => ({
  selectedTags: tags.selectedTags,
  reports: reports.reports,
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchReports);

import '../../../../styles/index.scss';
import StoryReport from '../../StoriesContainer/StoryReport/StoryReport';


function StoryInfo(props) {  
  const story = props.selectedStory;
  return (
    <div className='story-info-container-body-main'>
      <div className='story-info-title'>
       {story.title} {/* Story Title */}
      </div>
      <div className='story-info-subtitle'>
        {story.subtitle} {/* Subtitle */}
      </div>
      <div className='story-info-image' style={{backgroundImage:`url(${story?.coverImage})`}} />
      {/* Optional fields */}
      {story.bodyContent.map((content) => {
        if (content.type === 'text'){
          return(
            <div className='story-info-titlebox'>
              {content.textInput}
            </div>
          );
        } else if (content.type === 'report'){
          return (<StoryReport 
            report={content}
            isEditable={false} 
          />);
        }
      })}
      {/*<div className='story-info-titlebox'>
        For more than seventy percent of the population, the agricultural sector is the primary source of income  and provides jobs for most of the workforce. 
        Embracing technology is one of the ways to improve productivity in the agriculture sector.
      </div>
      <StoryReport 
        report={{
          id: 0,
          verfied: true,
          new: true,
          title: 'Local farming in the area',
          description:'Local residents resort to farming in order to make a livelihood for their families',
          date: 'Nov 20',
        }}
        isEditable={false} 
      />
      <div className='story-info-titlebox'>
        Aenean nec lorem egestas, fringilla magna nec, consequat est. 
        Fusce non mollis tortor. Aenean sed convallis lectus, vitae fermentum elit. 
        Vestibulum pretium dignissim tellus eu pellentesque. 
        Pellentesque sit amet ultrices libero. 
        Phasellus in urna quis neque maximus convallis eget porttitor tellus. 
        Aenean elementum, lacus in laoreet congue, mauris nulla luctus tortor, in tempus arcu tortor pellentesque massa.
      </div> */}
    </div>
  );
}

export default StoryInfo;

import React, { useState } from 'react';
import t from 'prop-types';
import { useTranslation } from 'react-i18next';
import '../../../styles/index.scss';
import AdminModal from '../AdminModal/AdminModal';

function MessageElement(props) {
  const [modal, setModal] = useState(false);
  const [expandedElement, setExpandedElement] = useState(false);
  const { t } = useTranslation();

  const openModal = () => {
    setModal(true);
  };

  const shortenText = props.text.substring(0, 150);

  const canBeExpanded = shortenText.length < props.text.length;

  const textToShow = canBeExpanded && !expandedElement ? `${shortenText}...` : props.text;
  return (
    <div className="message-element-container">
      <div className="message-element">
        <div className="message-element-header">
          <span className="message-element-header-title-container">
            <span className="message-element-header-title">{props.title}</span>
          </span>
          <div>
            <div
              className="message-element-link"
              tabIndex={0}
              onClick={openModal}
              role="button"
              onKeyDown={openModal}
            >
              {t('see')}
              {' '}
              {props.recipients.length}
              {' '}
              {t('recipients')}
            </div>
          </div>
        </div>
        <div className="message-element-body">
          <span>
            {textToShow}
            {canBeExpanded && (
              <>
                &nbsp;&nbsp;
                <span
                  className="message-element-link message-element-link-seemore"
                  tabIndex={-999}
                  onClick={() => { setExpandedElement(!expandedElement); }}
                  role="button"
                  onKeyDown={() => { setExpandedElement(!expandedElement); }}
                >
                  {expandedElement ? 'See less' : 'See more'}
                </span>
              </>
            )}
          </span>
        </div>
        <div className="message-element-footer">
          <span className="message-element-header-date">
            {t('contactAuthorText', { author: props.author, date: props.date })}
          </span>
        </div>
        <AdminModal
          hasTrigger={false}
          customModal="message-element-modal"
          header={t('recipients')}
          actionName=""
          onClose={() => setModal(false)}
          open={modal}
          onOpen={() => setModal(true)}
        >
          <div>
            {props.recipients.map((recipient) => (
              <div className="message-element-recipient" key={`recipients-${props.id}-${recipient}`}>
                {recipient}
              </div>
            ))}
          </div>
        </AdminModal>
      </div>
    </div>
  );
}
MessageElement.propTypes = {
  id: t.string.isRequired,
  recipients: t.array.isRequired,
  date: t.string.isRequired,
  title: t.string.isRequired,
  text: t.string.isRequired,
};

export default MessageElement;

/* eslint-disable jsx-a11y/no-static-element-interactions */
import '../../../../styles/index.scss';
import { connect } from 'react-redux';
import types from 'prop-types';
import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import mapDispatchToProps from '../../../../actions/index';
import { getRandomColor, hexToRgb } from '../../../../utils';
import ImageModal from '../../ImageModal/ImageModal';

function ReportActivityFeedInfo(props) {
  const [selectedBody, setSelectedBody] = useState(<></>);
  const [modalOpen, setModalOpen] = useState(false);
  const [isImage, setIsImage] = useState(false);

  const images = props.imageFiles || [];
  const audios = props.audioFiles || [];
  const videos = props.videoFiles || [];
  const pdfs = props.pdfFiles || [];
  const author = props.author || '';
  const comment = props.comment || '';

  const profilePlaceholder = () => (
    author.substring(0, 2).toUpperCase()
    //`${userFirstname.charAt(0)}${userLastName.charAt(0)}`.toUpperCase()
  );

  const onClickImage = (elem) => {
    setSelectedBody(
      <div
        style={elem.sizes.medium.src ? {
          backgroundImage: `url(${elem.sizes.medium.src})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', width: '100%', height: '100%',
        } : {}}
        className=""
      />,
    );
    setModalOpen(true);
    setIsImage(true);
  };

  const onClickVideo = (elem) => {
    setSelectedBody(
      <div className="report-activityfeed-info-files-video-highlight">
        {elem.sizes.original.src && (
          <ReactPlayer
            url={elem.sizes.original.src}
            className="report-video-player"
            controls
            fullscreen={false}
            style={{ width: '100%', height: '100%' }}
          />
        )}
      </div>,
    );
    setModalOpen(true);
  };

  const onClickAudio = (elem) => {
    setSelectedBody(
      <div className="report-activityfeed-info-files-video-highlight">
        {elem.sizes.original.src && (
          <ReactPlayer
            url={elem.sizes.original.src}
            className="report"
            controls
            fullscreen={false}
            height="70px"
            width="100%"
          />
        )}
      </div>,
    );
    setModalOpen(true);
  };

  const onClickPdf = (elem) => {
    if (elem.sizes[""].src) window.open(elem.sizes[""].src, 'download');
  };

  const onCloseModal = () => {
    setSelectedBody(<></>);
    setModalOpen(false);
    setIsImage(false);
  };

  const fileClass = 'report-activityfeed-info-files-element';
  return (
    <>
      <div className="report-activityfeed-info">
        <div className="report-activityfeed-info-profile-section">
          <div className="report-activityfeed-info-profile-icon" style={{ backgroundColor: props.color }}>
            {profilePlaceholder()}
          </div>
          <hr className="report-activityfeed-info-profile-line" />
        </div>
        <div className="report-activityfeed-info-content-section">
          <div className="report-activityfeed-info-status">
            <p style={{ textAlign: 'left' }}>
              <span className="report-activityfeed-info-status-title">{`${author}`}</span>
              <span
                className="report-activityfeed-info-status-text"
                dangerouslySetInnerHTML={{ __html: props.actionText }}
              />
              <span className="report-activityfeed-info-status-date">{props.actionTime}</span>
            </p>
          </div>
          <div className="report-activityfeed-info-subsection">
            {comment !== '' && (
              <div className="report-activityfeed-info-comment">
                {comment}
              </div>
            )}
            <div className="report-activityfeed-info-files">
              {images.map((elem) => (
                <div
                  onKeyDown={() => onClickImage(elem)}
                  onClick={() => onClickImage(elem)}
                  style={elem?.sizes.thumbnail?.src ? { backgroundImage: `url(${elem.sizes.thumbnail.src})`, backgroundSize: '100% 100%' } : {}}
                  className={`${fileClass} ${fileClass}-image ${fileClass}-pointer`}
                  key={elem.sizes.thumbnail?.uuid}
                />
              ))}
              {audios.map((elem) => (
                <div
                  onKeyDown={() => onClickAudio(elem)}
                  onClick={() => onClickAudio(elem)}
                  className={`${fileClass} ${fileClass}-audio ${fileClass}-pointer`}
                  key={elem?.sizes.original?.uuid}
                />
              ))}
              {videos.map((elem) => (
                <div
                  onKeyDown={() => onClickVideo(elem)}
                  onClick={() => onClickVideo(elem)}
                  className={`${fileClass} ${fileClass}-video ${fileClass}-pointer`}
                  key={elem?.sizes.original?.uuid}
                />
              ))}
              {pdfs.map((elem) => (
                <div
                  onKeyDown={() => onClickPdf(elem)}
                  onClick={() => onClickPdf(elem)}
                  className={`${fileClass} ${fileClass}-pdf ${fileClass}-pointer`}
                  key={elem?.sizes.original?.uuid}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <ImageModal
        open={modalOpen}
        onClose={() => onCloseModal()}
        onOpen={() => setModalOpen(true)}
        isImageOnly={isImage}
      >
        {selectedBody}
      </ImageModal>
    </>
  );
}

ReportActivityFeedInfo.propTypes = {
  comment: types.string,
  author: types.string,
  audioFiles: types.array,
  imageFiles: types.array,
  videoFiles: types.array,
  pdfFiles: types.array,
};

ReportActivityFeedInfo.defaultProps = {
  comment: '',
  author: '',
  audioFiles: [],
  imageFiles: [],
  videoFiles: [],
  pdfFiles: [],
};

export default connect(null, mapDispatchToProps)(ReportActivityFeedInfo);

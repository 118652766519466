import {
  UPDATE_TIMBY_OPTIONS, UPDATE_DEFAULT_LANGUAGE, UPDATE_USER_PROFILE, USER_TWO_FACTOR_SUCCESS,
} from '../types';

const initialState = {
  backgroundImage: '',
  quote: '',
  quote_author: '',
  languages: [],
  defaultLanguage: 'en',
  appLanguage: 'en',
};

const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_TIMBY_OPTIONS: {
      return {
        ...state,
        backgroundImage: action.payload.backgroundImage,
        quote: action.payload.quote,
        quote_author: action.payload.quote_author,
        languages: action.payload.languages,
        appLanguage: action.payload.appDefaultLanguage.code,
        defaultLanguage: action.payload.defaultLanguage.code,
      };
    }
    case USER_TWO_FACTOR_SUCCESS:
    case UPDATE_USER_PROFILE:
    case UPDATE_DEFAULT_LANGUAGE:
      return {
        ...state,
        defaultLanguage: action.payload.language,
      };
    default:
      return state;
  }
};

export default commonReducer;

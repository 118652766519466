import '../../../styles/index.scss';
import React, {
  useState, useLayoutEffect, useEffect, useRef,
} from 'react';
import clsx from 'clsx';
import t from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Prompt, useParams } from 'react-router-dom';
import { format, parse, isEqual } from 'date-fns';
import FiltersPanel from '../FiltersPanel/FiltersPanel';
import { ReactComponent as LeftArrowIcon } from '../../../assets/images/arrow-left.svg';
import ReportHeader from '../ReportHeader/ReportHeader';
import ReportListContainer from '../ReportListContainer/ReportListContainer';
import dashboardHoc from '../DashboardHoc/DashboardHoc';
import mapDispatchToProps from '../../../actions/index';
import ReportMap from './ReportMap/ReportMap';
import ReportUrgencyBar from './ReportUrgencyBar/ReportUrgencyBar';
import ReportCategories from './ReportCategories/ReportCategories';
import ReportImageSlider from './ReportImageSlider/ReportImageSlider';
import ReportViewHeader from './ReportViewHeader/ReportViewHeader';
import ReportBasicInfo from './ReportBasicInfo/ReportBasicInfo';
import ReportMedia from './ReportMedia/ReportMedia';
import ReportActivityFeed from '../ReportActivityFeed/ReportActivityFeed';
import Button from '../../shared/Button/Button';
import { isUserReader, deepEqual, addParagraphs } from '../../../utils';
import DeleteModal from '../DeleteModal/DeleteModal';
import CustomTextArea from '../../shared/CustomTextArea/CustomTextArea';
import { REPORT_EVENT_DATE_PARSER } from '../../../shared/consts';
import TextInput from '../../shared/TextInput/TextInput';
import CustomDatePicker from '../../shared/CustomDatePicker/CustomDatePicker';
import SelectBox from '../../ui/SelectBox/SelectBox';
import AdminModal from '../AdminModal/AdminModal';

const PUBLIC = 'public';
const PRIVATE = 'private';

const formatReportTaxonomies = (report) => {
  const formattedTaxonomies = {};
  if (report?.taxonomies) {
    Object.keys(report?.taxonomies).map((k) => {
      formattedTaxonomies[k] = report?.taxonomies[k].map((elem) => (Object.values(elem))).flat(1);
      return null;
    });
  }
  return formattedTaxonomies;
};

function ReportView(props) {
  const { t } = useTranslation();
  const activityFeedRef = useRef(null);
  const [showFiltersPanel, setShowFiltersPanel] = useState(false);
  const [editable, setEditable] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [categories, setCategories] = useState({});
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [author, setAuthor] = useState('');
  const [urgency, setUrgency] = useState('');
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [pdfFiles, setPdfFiles] = useState([]);
  const [date, setDate] = useState(null);
  const [hour, setHour] = useState(0);
  const [minute, setMinute] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [audios, setAudios] = useState([]);
  const [errors, setErrors] = useState({});
  const [reportPublic, setPublic] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [unsavedChangesVisibleModal, setUnsavedChangesVisibleModal] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const [onCloseEditMode, setOnCloseEditMode] = useState(false);
  const { id } = useParams();

  const hourOptions = Array.from({ length: 24 }, (_, i) => i).map((elem) => (
    { key: elem, value: elem, text: elem }
  ));
  const minuteOptions = Array.from(Array(60).keys()).map((elem) => (
    { key: elem, value: elem, text: elem }
  ));

  const secondsOptions = Array.from(Array(60).keys()).map((elem) => (
    { key: elem, value: elem, text: elem }
  ));

  useEffect(() => {
    const checkSelectReport = async () => {
      if (id === undefined) {
        props.history.push({ pathname: '/dashboard', from: props.history.location.pathname });
      } if (id === props.selectedReport) {
        // Skip
      } else {
        if (props.reports.length === 0) await props.getReports();
        if (props.users.length === 0) props.getUsers();
        await props.getSelectedReport(id);
        setEditMode(false);
      }
    };

    checkSelectReport();
  }, [id]);

  useEffect(() => {
    if (props.selectedReport) {
      fillReportData(props.selectedReport);
    }
  }, [props.selectedReport]);

  useEffect(() => {
    if (!isUserReader(props.userInfo)) {
      setEditable(true);
    }
  }, [props.userInfo]);

  useEffect(() => {
    document.title = 'Timby - View Report';
  }, []);

  useLayoutEffect(() => {
    setTimeout(() => { window.dispatchEvent(new Event('resize')); }, 200,);
  }, [showFiltersPanel]);

  const fillReportData = (selectedReport) => {
    if(selectedReport) {
      setTitle(selectedReport?.title);
      setDescription(selectedReport?.content);
      const reportDate = parse(
        selectedReport.event_date, REPORT_EVENT_DATE_PARSER, new Date(),
      );
      setDate(reportDate);
      setHour(reportDate.getHours());
      setMinute(reportDate.getMinutes());
      setSeconds(reportDate.getSeconds());
      setLatitude(selectedReport?.latitude);
      setLongitude(selectedReport?.longitude);
      setPublic(selectedReport?.visibility === PUBLIC);
      setAuthor(selectedReport.author);
      setUrgency(selectedReport.urgency);

      // Separate media per type
      const { media } = selectedReport;
      const imageLength = media?.image?.length || 0;
      let images = [];
      if (imageLength > 0) {

        images = media.image.map((elem) => ({
          original: elem?.original?.src,
          description: elem?.original?.caption,
          thumbnail: elem?.small?.src,
          large: elem?.original?.src,
        }));
      }
      // const videos = media.filter((elem) => (elem.type === 'video'))
      const pdfs = media?.pdf || [];
      const audios = media?.audio || [];
      const videos = media?.video || [];
      const formattedTaxonomies = formatReportTaxonomies(selectedReport);

      setCategories(formattedTaxonomies || {});
      setImages(images);
      setAudios(audios);
      setVideos(videos);
      setPdfFiles(pdfs);
    }
  };

  const updateCategories = (groupTagId, selectedTagChilds) => {
    categories[groupTagId] = selectedTagChilds;
    if (!selectedTagChilds) {
      delete categories[groupTagId];
    }
    setCategories({ ...categories });
  };

  const onChangeImageDescription = (value, index) => {
    images[index].description = value;
    setImages([...images]);
  };

  const cancelEditMode = () => {
    fillReportData(props.selectedReport);
    setEditMode(false);
    setOnCloseEditMode(false);
  };

  const openEditMode = () => {
    setEditMode(true);
  };

  const goToActivityFeed = () => {
    if (activityFeedRef.current) {
      activityFeedRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  };
  let formattedDate = '';
  if (props.selectedReport) {
    formattedDate = format(parse(props.selectedReport.created_date, REPORT_EVENT_DATE_PARSER, new Date()), 'EEEE, MMM dd yyyy, hh:mm aa OOO');
  }

  const verifyReport = () => {
    const reportPayload = {
      verified: '1',
    };
    props.editReport(props.selectedReport.uuid, reportPayload);
  };

  const unverifyReport = () => {
    const reportPayload = {
      verified: '0',
    };
    props.editReport(props.selectedReport.uuid, reportPayload);
  };

  const setPublicReport = async () => {
    const reportPayload = {
      visibility: PUBLIC,
    };
    await props.editReport(props.selectedReport.uuid, reportPayload);
    setPublic(true);
  };

  const setPrivateReport = async () => {
    const reportPayload = {
      visibility: PRIVATE,
    };
    await props.editReport(props.selectedReport.uuid, reportPayload);
    setPublic(false);
  };

  const editReportInfo = async () => {
    const reportPayload = {};

    // Taxonomies
    const selectedTaxonomies = categories;
    const taxonomiesValues = Object.values(selectedTaxonomies).flat(1);

    const oldTaxonomies = formatReportTaxonomies(props.selectedReport);
    if (!deepEqual(selectedTaxonomies, oldTaxonomies)) reportPayload.taxonomies = taxonomiesValues;

    // Medias
    reportPayload.media = {
      pdf: pdfFiles,
      image: images,
      audio: audios,
      video: videos,
    };

    // Other report infos
    if (title !== props.selectedReport.title) reportPayload.title = title;
    const oldDate = parse(props.selectedReport.event_date, REPORT_EVENT_DATE_PARSER, new Date(),);
    if (!isEqual(date, oldDate)) reportPayload.event_date = date ? format(date, 'Y-L-dd H:m:s') : null;
    if (description !== props.selectedReport.content) reportPayload.content = description;
    if (latitude !== props.selectedReport.latitude || longitude !== props.selectedReport.longitude) {
      reportPayload.latitude = parseFloat(latitude);
      reportPayload.longitude = parseFloat(longitude);
    }

    // Update report
    const response = await props.editReport(props.selectedReport.uuid, reportPayload);
    if (response.success) {
      setEditMode(false);
    } else {
      setErrors(response.errors);
    }
  };

  const deleteReport = async () => {
    const result = await props.deleteReport(props.selectedReport.uuid);
    if (result) {
      setDeleteModal(false);
    }
  };

  const updateTitle = (value) => {
    const newErrors = errors;
    if (value.length > 70) {
      newErrors.title = t('validations.reportTitleExceeded');
      setErrors(newErrors);
    } else {
      delete newErrors.title;
      setErrors(newErrors);
    }
    setTitle(value);
  };

  const updateDescription = (value) => {
    const newErrors = errors;
    if (value.length > 10000) {
      newErrors.description = t('validations.reportDescriptionExceeded');
      setErrors(newErrors);
    } else {
      delete newErrors.description;
      setErrors(newErrors);
    }
    setDescription(value);
  };

  const updateDate = (value) => {
    setDate(value);
    const newErrors = errors;
    delete newErrors.date;
    setErrors(newErrors);
  };

  const closeReport = () => {
    props.unselectedReport();
    props.history.push({ pathname: '/dashboard', from: props.history.location.pathname });
  };

  const checkFields = () => {
    const { selectedReport } = props;
    if (selectedReport === null) {
      return false;
    }
    const selectedTaxonomies = categories;
    const oldTaxonomies = formatReportTaxonomies(selectedReport);
    const oldDate = parse(
      selectedReport.event_date, REPORT_EVENT_DATE_PARSER, new Date(),
    );

    return ((latitude !== selectedReport.latitude || longitude !== selectedReport.longitude
        || description !== selectedReport.content || title !== selectedReport.title
        || !isEqual(date, oldDate) || !deepEqual(selectedTaxonomies, oldTaxonomies))
    );
  };

  const handleBlockedNavigation = (nextLocation) => {
    if (!confirmedNavigation) {
      setUnsavedChangesVisibleModal(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  const closeModal = () => {
    setUnsavedChangesVisibleModal(false);
    setOnCloseEditMode(false);
    setConfirmedNavigation(false);
  };

  const handleConfirmNavigationClick = () => {
    setUnsavedChangesVisibleModal(false);
    setConfirmedNavigation(true);
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      props.history.push({ pathname: lastLocation.pathname, from: props.history.location.pathname });
    }
  }, [confirmedNavigation, lastLocation]);

  const unsavedFlag = checkFields();

  return (
    <>
      <Prompt
        when={unsavedFlag}
        message={handleBlockedNavigation}
      />
      <AdminModal
        onClose={() => closeModal()}
        open={unsavedChangesVisibleModal || onCloseEditMode}
        hasTrigger={false}
        header={t('reportUnsavedChangesTitle')}
        subtitle={t('reportUnsavedChangesSubtitle')}
        customModal="report-activityfeed-submit-modal report-activityfeed-submit-modal-mini"
      >
        <div className="report-activityfeed-submit-buttons">
          <Button text={t('goBack')} secondary medium handler={() => closeModal()} />
          <Button
            text={onCloseEditMode ? t('closeAction') : t('leaveAction')}
            primary
            medium
            handler={onCloseEditMode
              ? () => cancelEditMode()
              : () => handleConfirmNavigationClick()}
          />
        </div>
      </AdminModal>
      <div className={clsx('reports-container__small-panel', {
        'reports-container__small-panel--hidden': !showFiltersPanel,
      })}
      >
        <FiltersPanel showPanel={showFiltersPanel} handleShow={() => setShowFiltersPanel(false)} />
      </div>
      <div className="reports-container__mid-panel">
        <ReportHeader
          filtersHandler={() => setShowFiltersPanel(true)}
          showFilters={showFiltersPanel}
          noButtons
        />
        <ReportListContainer />
      </div>
      <div className="reports-container__large-panel report-view__container grayscrollbar">
        <ReportViewHeader
          editable={editable}
          goBackToDashboard={closeReport}
          goToActivityFeed={goToActivityFeed}
          onDelete={() => setDeleteModal(true)}
          editMode={editMode}
        />
        <div className="report-view__container-body">
          <div className={clsx('report-view__container-box', {
            'report-view__container-box-edit': editMode,
          })}
          >
            <ReportUrgencyBar urgency={urgency} />
            {editMode && (
              <div
                tabIndex={-999}
                role="button"
                className="report-view__container-body-goback"
                onKeyDown={() => { checkFields() ? setOnCloseEditMode(true) : cancelEditMode(); }}
                onClick={() => { checkFields() ? setOnCloseEditMode(true) : cancelEditMode(); }}
              >
                <LeftArrowIcon />
                <span className="report-view__container-body-goback-text">{t('goBack')}</span>
              </div>
            )}
            <div className="report-view__container-body-box">
              {props.selectedReport && (
                <ReportBasicInfo
                  editMode={editMode}
                  reportPublic={reportPublic}
                  editable={editable}
                  verified={!!parseInt(props.selectedReport?.verified, 10)}
                  onChangePublic={(props.selectedReport?.visibility === PRIVATE
                      ? setPublicReport
                      : setPrivateReport
                  )}
                  title={props.selectedReport?.title || ''}
                  date={formattedDate}
                  author={author}
                />
              )}
              {/* Report Actions */}
              {editable && !editMode && (
                <div className="report-view__container-body-buttons">
                  {!parseInt(props.selectedReport?.verified, 10) ? (<Button text={t('reportVerify')} primary medium handler={() => verifyReport()} />) : <></>}
                  {parseInt(props.selectedReport?.verified, 10) ? (<Button text={t('unverify')} warning medium handler={() => unverifyReport()} />) : <></>}
                  <Button text={t('edit')} secondary medium handler={() => openEditMode(true)} />
                </div>
              )}
              <div className="report-view__container-padding-top" />
              {(editMode && (
                <TextInput
                  title={t('reportTitleLabel')}
                  value={title}
                  handleChange={(event) => updateTitle(event.target.value)}
                  placeholder={t('reportTitlePlaceholder')}
                  hasError={!!errors.title}
                  errorDescription={errors.title}
                />
              ))}
              <ReportCategories
                editMode={editMode}
                selectedCategories={categories}
                updateCategories={updateCategories}
              />
              {editMode && (
                <>
                  <CustomTextArea
                    title={t('reportWhatHappening')}
                    value={description}
                    onChange={(value) => updateDescription(value)}
                    placeholder={t('reportDescriptionPlaceholder')}
                    hasError={!!errors.description}
                    errorDescription={errors.description}
                  />
                  <div className="create-report__time">
                    <CustomDatePicker
                      title={t('reportDateLabel')}
                      value={date}
                      hasErrors={!!errors.date}
                      handleChange={(date) => updateDate(date)}
                      maxDate={Date.now()}
                      placeholder={t('reportDatePlaceholder')}
                    />
                    <SelectBox
                      value={hour}
                      options={hourOptions}
                      handleChange={(e, { value }) => setHour(value)}
                      placeholder={t('reportHour')}
                    />
                    <SelectBox
                      options={minuteOptions}
                      value={minute}
                      handleChange={(e, { value }) => setMinute(value)}
                      placeholder={t('reportMinute')}
                    />
                    <SelectBox
                      options={secondsOptions}
                      value={seconds}
                      handleChange={(e, { value }) => setSeconds(value)}
                      placeholder={t('reportSecond')}
                    />
                  </div>
                </>
              )}
              {!editMode && (
                <>
                  <div className="report-view__container-body-info-section-title">
                    {t('reportWhatHappening')}
                  </div>
                  <div className="report-view__container-body-info-description">
                    {addParagraphs(description)}
                  </div>
                </>
              )}
              {(latitude !== null && longitude !== null) && (
                <ReportMap
                  editMode={editMode}
                  setLatitude={setLatitude}
                  setLongitude={setLongitude}
                  setErrors={setErrors}
                  errors={errors}
                  latitude={latitude}
                  longitude={longitude}
                />
              )}
              {images.length > 0 && (
                <ReportImageSlider
                  editMode={editMode}
                  images={images}
                  onChangeDescription={onChangeImageDescription}
                />
              )}
              {audios.length > 0 && (
                <>
                  <div className="report-view__container-body-info-section-title">
                    {t('reportAudioEvidence')}
                  </div>
                  <ReportMedia
                    type="audio"
                    medias={audios}
                    editMode={editMode}
                    onChangeCaption={(elem) => handleChangeCaption(elem)}
                  />
                </>
              )}
              {pdfFiles.length > 0 && (
                <>
                  <div className="report-view__container-body-info-section-title">
                    {t('reportPDFEvidence')}
                  </div>
                  <ReportMedia
                    type="pdf"
                    medias={pdfFiles}
                    editMode={editMode}
                    onChangeCaption={(elem) => handleChangeCaption(elem)}
                  />
                </>
              )}
              {videos.length > 0 && (
                <>
                  <div className="report-view__container-body-info-section-title">
                    {t('reportVideoEvidence')}
                  </div>
                  <ReportMedia
                    type="video"
                    medias={videos}
                    editMode={editMode}
                    onChangeCaption={(elem) => handleChangeCaption(elem)}
                  />
                </>
              )}
              {editMode && (
                <div className="report-view__container-body-savebutton">
                  <Button text={t('save')} primary medium handler={() => { editReportInfo(); }} />
                </div>
              )}
            </div>
          </div>
        </div>
        <div ref={(node) => { activityFeedRef.current = node; }}>
          {!editMode && (
            <ReportActivityFeed
              editable={editable}
              activityFeed={props.activityFeed}
              selectedReport={props.selectedReport}
              userInfo={props.userInfo}
            />
          )}
        </div>
      </div>
      <DeleteModal
        onClose={() => setDeleteModal(false)}
        open={deleteModal}
        onOpen={() => setDeleteModal(true)}
        hasTrigger={false}
        header={t('deleteReport')}
        subtitle={t('deleteReportDescription')}
        deleteAction={() => deleteReport()}
      />
    </>
  );
}

const mapStateToProps = ({
  user, reports, folders, admin,
}) => ({
  userInfo: user.userInfo,
  reports: reports.reports,
  selectedReport: reports.selectedReport,
  selectedFolder: folders.selectedFolder,
  activityFeed: reports.activityFeed,
  users: admin.users,
});

ReportView.propTypes = {
  activityFeed: t.array.isRequired,
  editReport: t.func.isRequired,
  deleteReport: t.func.isRequired,
  unselectedReport: t.func.isRequired,
  getReports: t.func.isRequired,
  getSelectedReport: t.func.isRequired,
  history: t.object.isRequired,
  selectedReport: t.object.isRequired,
  reports: t.array.isRequired,
  userInfo: t.object.isRequired,
  users: t.array.isRequired,
  getUsers: t.func.isRequired,
};

export default dashboardHoc(connect(mapStateToProps, mapDispatchToProps)(ReportView));

export default {
  login: '登录',
  username: '用户名',
  password: '密码',
  email: '电子邮件',
  firstname: '名',
  lastname: '姓',
  role: '角色',
  passwordPlaceholder: '输入密码',
  usernamePlaceholder: '输入用户名',
  signin: '登入',
  forgotMyPassword: '我忘记了我的密码',
  requirements: '要求',
  resetPassword: '重设密码',
  resetPasswordSubtitle: '输入与您的帐户关联的用户名。',
  resetPasswordSubmit: '给我一个重置密码链接',
  resetLinkSent: '重置链路发送',
  resetLinkSubtitle: '我们已将重置密码的链接发送到与您的帐户相关联的电子邮件中。',
  resetLinkSubmit: '再次向我发送链接',
  createNewPassword: '创建新密码',
  passwordReq1: '至少 7 个字符',
  passwordReq2: '包含 1 个大写字母',
  passwordReq3: '包含1号',
  passwordReq4: '包含1个特殊字符',
  passwordReq5: '包含 1 个小写字母',
  newPassword: '新密码',
  newPasswordPlaceholder: '输入新密码',
  reEnterNewPassword: '重新输入新密码',
  reEnterNewPasswordPlaceholder: '重新输入新的密码',
  createNewPasswordSubmit: '修改我的密码',
  twoFactorAuth: '请输入电邮地址',
  twoFactorAuthSubtitle: '我们已将验证码发送到与您的帐户相关联的电子邮件中。',
  twoFactorAuthSubmit: '给我发送一个验证码',
  twoFactorCode: '输入验证码',
  twoFactorCodeSubtitle: '我们已向与此帐户关联的电子邮件地址发送了验证码。',
  enterCode: '输入验证码',
  enterCodePlaceholder: '请输入6位数密码',
  twoFactorCodeSubmit: '确认',
  twoFactorCodeLink: '重新发送我的代码',
  twoFactorCodeCheckbox: '不要再要求我2FA',
  reportsTitle: '报告',
  newReport: '新报告',
  admin: '行政',
  tags: '标签',
  users: 'Users',
  exportreport: '导出报表',
  profile: '轮廓',
  terminology: '术语',
  mysettings: '我的设置',
  edit: '编辑',
  organizations: '组织',
  analytics: 'Analytics（分析）',
  analyticsTitle: '这周焦点',
  analyticsSubmissions: '提交',
  analyticsReportByCat: '按类别热门报告',
  analyticsFromLastWeek: '从上周',
  analyticsRepSubmited: '提交报告',
  analyticsRepVerified: '报告验证',
  analyticsIssuesResp: '问题回答',
  analyticsStoriesCreated: '创建故事',
  analyticsGeographicPrio: '地理优先级',
  analyticsPopularKeywords: '热门关键词',
  analyticsKeyword: '关键词',
  analyticsMentions: 'MENTIONS',
  analyticsUsertypes: '用户类型',
  analyticsTopUsers: '用户排行',
  analyticsUsers: 'Users',
  analyticsActivity: '活动',
  contactUsers: '联系用户',
  send: '发送',
  sendToEveryone: '发送到大家',
  sendSpecificUsers: '发送信息给特定用户',
  selectUsers: '选择用户',
  enterMessage: '在这里输入您的信息',
  search: '搜索',
  addNew: '添新',
  save: '保存',
  ok: 'Ok',
  enterFirstName: '输入名字',
  enterLastName: '输入姓氏',
  enterEmailPlaceholder: '请输入电邮地址',
  createReport: '创建报告',
  reportDetails: '报告详细信息',
  reportTitleLabel: '报告标题',
  reportTitlePlaceholder: '输入报告标题',
  reportDescriptionLabel: '报告说明',
  reportDescriptionPlaceholder: '输入报表说明',
  reportAuthorLabel: '作者选择',
  reportAuthorPlaceholder: '选择作者',
  reportHour: '小时',
  reportMinute: '分钟',
  reportMapLabel: '在地图上选择',
  reportAddMedia: '添加媒体',
  reportUploadMedia: '上传照片，视频，音频或PDF',
  reportDragMedia: '这里拖放媒体文件',
  reportSupportMedia: '我们支持照片，视频，音频或PDF',
  reportBrowseMedia: '浏览',
  reportUploadFiles: '上传文件',
  reportPublic: '上市',
  reportSeeActivity: '查看活动',
  reportUnverified: '未验证',
  reportVerified: '验证',
  reportVerify: '核实',
  reportSearch: '搜索报告',
  reportedBy: '通过报道',
  reportWhatHappening: '发生了什么',
  reportLocation: '地点',
  reportCoordinates: '坐标',
  copyDMS: '复制DMS',
  copyDMM: '复制DD',
  reportImageEvidence: '图片证据',
  reportImageDescription: '图片说明',
  reportSaveClose: '保存并关闭',
  reportAudioEvidence: '音频证据',
  reportPDFEvidence: 'PDF上传',
  reportVideoEvidence: '视频证据',
  reportVideoDescription: '视频介绍',
  reportUpdates: '更新',
  reportActivityUploadMedia: '上传媒体',
  reportActivityAssignUser: '分配用户',
  reportActivitySetUrgency: '设置紧急',
  reportActivityPlaceholder: '向此报告添加评论或其他媒体',
  updateAction: '更新',
  editReport: '编辑报告',
  goBack: '后退',
  reportCritical: '这份报告是关键',
  reportVeryUrgent: '这份报告非常紧急',
  reportUrgent: '这份报告很紧急',
  reportFilter: '地图',
  reportStatus: '筛选',
  reportFolders: '文件夹',
  urgent: '紧迫的',
  reportDateCreated: '创建日期',
  reportAssignment: '任务',
  reportAssignee: '受让人',
  reportAuthor: '作者',
  reportDateCreatedPlaceholder: '选择日期',
  reportDateLabel: '事件何时发生？',
  reportDatePlaceholder: '输入报告日期',
  reportMapTitle: '发生的地方',
  reportDMSCoordinate: 'DMS坐标',
  reportDMMCoordinate: 'DMM坐标',
  contactRecent: '最近的通知',
  storyTitle: '创建内容',
  storyCreate: '创建故事',
  storySearch: '搜索内容',
  published: '发布时间',
  updated: '更新',
  publishedOn: '发表于',
  storyType: '故事类型',
  unpublish: '取消发布',
  publish: '发布',
  unpublished: '未发表',
  readFullReport: '阅读报告全文',
  coverImage: '封面图片',
  storyTextContent: '文字内容',
  storyTitlePlaceholder: '在这里输入您的标题',
  storySubtitlePlaceholder: '在这里输入您的字幕',
  closeReport: '关闭报告',
  storyAddNew: '添加新文本框',
  saveChanges: '保存更改',
  userOrganizationPlaceholder: '输入用户的组织',
  enable2FA: '启用2FA',
  cancel: '取消',
  deleteWarning: '无法撤消',
  createTagGroup: '创建新的标签组',
  createTag: '创建新标签进行分组',
  createUser: '建立新使用者',
  createOrganization: '建立新的组织',
  createLayer: '建立新图层',
  deleteTagGroup: '删除标签组',
  deleteTagGroupDescription: '您确定要删除此标签组吗？',
  deleteTag: '删除标签元素',
  deleteTagDescription: '您确定要删除此标记元素吗？',
  myTags: '我的标签',
  editTagGroup: '编辑标签组',
  mapParameters: '地图参数',
  latitude: '纬度',
  longitude: '经度',
  zoomLevel: '缩放级别',
  layers: '图层',
  vector: '向量',
  raster: '光栅',
  layerName: '图层名称',
  layerShapeFiles: '我们支持shape文件，KML和GeoJSON的',
  dragdropLayers: '在此处拖放图层',
  color: '颜色',
  translations: '翻译',
  name: '名称',
  defaultLanguage: '预设语言',
  selectReportToExport: '出口选择报告',
  exportAll: '导出所有',
  export: '出口',
  enterNameIn: '在输入名称',
  enterLayerNameIn: '在中输入图层名称',
  enterLayerName: '输入图层名称',
  rasterHostedUrl: '光栅托管网址',
  enterRasterHostedUrl: '输入栅格托管URL',
  enterColor: '输入颜色',
  enterUserRole: '输入用户角色',
  deleteSuccess: '是的删除',
  subject: '主题',
  enterSubject: '输入主题',
  recipients: '收件者',
  deleteUser: '删除用户',
  deleteUserDescription: '您确定要删除该用户吗？',
  deleteOrganization: '删除组织',
  deleteOrganizationDescription: '您确定要删除此组织吗？',
  singleSelect: '单选',
  multiSelect: '多选',
  textField: '文本域',
  searchInTheMap: '在移动地图时进行搜索',
  select: '选择',
  enterReportTag: '输入报告的{{tag}}',
  enterZoom: '选择缩放',
  basemaps: '底图',
  map: '地图',
  satelitte: '卫星',
  deleteReport: '删除报告',
  deleteReportDescription: '您确定要删除此报告吗？',
  closeAction: '关闭',
  reportLifecycle: '生命周期',
  languages: '语言',
  additionalSettings: '其他设置',
  deleteLayer: '删除图层',
  deleteLayerDescription: '您确定要删除此图层吗？',
  apply: '申请',
  reset: '重启',
  unverify: '拒绝',
  enabledLanguages: '启用的语言',
  selectLanguages: '选择语言',
  updateTerminology: '更新术语',
  currentString: '当前字符串',
  resetToDefault: '重置为默认',
  dragCsv: '将语言 CSV 文件拖放到此处',
  supportCsv: '我们只支持 CSV',
  anonymousSignIn: '移动设备上的匿名登录',
  mapsOnMobile: '手机地图',
  lessonPath: '课程路径',
  emergencyAlerts: '紧急警报',
  emergencyContactUser: '紧急联络用户',
  emergencyContactNumber: '紧急联络号码',
  contactNumber: '联系电话',
  enterCustomLessonPath: '输入自定义课程路径',
  urgency: '紧迫性',
  analyticsPage: '分析页面',
  notificationsPage: '通知页面',
  storiesPage: '故事页面',
  dashboardConfiguration: '仪表板配置',
  publicSite: '公共站点',
  mapOnPublicSite: '公共网站上的地图',
  grievencePortal: '申诉门户',
  localization: '本土化',
  timeZone: '时区',
  mobileOrganizationLabel: '移动应用组织',
  mobileOrganizationPlaceholder: '选择一个组织',
  sendByUsers: '用户发送',
  sendByRoles: '按角色发送',
  private: '私人的',
  reportState: '状态',
  date: '日期',
  startDate: '开始日期',
  endDate: '结束日期',
  organization: '组织',
  seconds: '秒',
  degrees: '学位',
  direction: '方向',
  default: '默认',
  custom: '风俗',
  addAdditionalEmergencyNumber: '添加其他紧急联系电话',
  stories: '故事',
  messages: '留言',
  addTagGroup: '添加标签组',
  addNewUser: '添加用户',
  addNewOrganization: '添加组织',
  addNewLayer: '添加图层',
  exportAction: '出口',
};

export default {
  login: 'உள்நுழைய',
  username: 'பயனர்பெயர்',
  password: 'கடவுச்சொல்',
  email: 'மின்னஞ்சல்',
  firstname: 'முதல் பெயர்',
  lastname: 'கடைசிப் பெயர்',
  role: 'பங்கு',
  passwordPlaceholder: 'கடவுச்சொல்லை உள்ளிடவும்',
  usernamePlaceholder: 'பயனர்பெயர் உள்ளிடவும்',
  signin: 'உள்நுழையவும்',
  forgotMyPassword: 'என் கடவு சொல்லை மறந்து விட்டேன்',
  requirements: 'தேவைகள்',
  resetPassword: 'கடவுச்சொல்லை மீட்டமைக்க',
  resetPasswordSubtitle: 'உங்கள் கணக்குடன் தொடர்புடைய பயனர்பெயரை உள்ளிடவும்.',
  resetPasswordSubmit: 'என்னை ஒரு ரீசெட் கடவுச்சொல்லை இணைப்பை அனுப்பவும்',
  resetLinkSent: 'மீட்டமை இணைப்பை அனுப்பப்பட்டன',
  resetLinkSubtitle: 'உங்கள் கடவுச்சொல்லை உங்கள் கணக்குடன் தொடர்புடைய மின்னஞ்சலுக்கு மீட்டமைக்க ஒரு இணைப்பை அனுப்பியுள்ளோம்.',
  resetLinkSubmit: 'மீண்டும் என்னை இணைப்பை அனுப்பவும்',
  createNewPassword: 'புதிய கடவுச்சொல்லை உருவாக்கவும்',
  passwordReq1: 'குறைந்தது 7 எழுத்துக்கள்',
  passwordReq2: '1 பெரிய எழுத்து உள்ளது',
  passwordReq3: '1 எண் கொண்டுள்ளது',
  passwordReq4: '1 சிறப்பு எழுத்து உள்ளது',
  passwordReq5: '1 சிறிய எழுத்து உள்ளது',
  newPassword: 'புதிய கடவுச்சொல்',
  newPasswordPlaceholder: 'புதிய கடவுச்சொல்லை உள்ளிடவும்',
  reEnterNewPassword: 'உங்கள் புதிய கடவுச்சொல்லை மீண்டும் நுழைய',
  reEnterNewPasswordPlaceholder: 'புதிய கடவுச்சொல்லை மீண்டும் நுழைய',
  createNewPasswordSubmit: 'எனது கடவுச்சொல்லை மாற்றவும்',
  twoFactorAuth: 'மின்னஞ்சல் முகவரியை உள்ளிடவும்',
  twoFactorAuthSubtitle: 'உங்கள் கணக்குடன் தொடர்புடைய மின்னஞ்சலுக்கு சரிபார்ப்புக் குறியீட்டை அனுப்பியுள்ளோம்.',
  twoFactorAuthSubmit: 'என்னை சரிபார்ப்புக் குறியீட்டை அனுப்புவோம்',
  twoFactorCode: 'சரிபார்ப்புக் குறியீட்டை உள்ளிடவும்',
  twoFactorCodeSubtitle: 'இந்தக் கணக்குடன் தொடர்புடைய மின்னஞ்சல் முகவரிக்கு சரிபார்ப்புக் குறியீட்டை அனுப்பியுள்ளோம்.',
  enterCode: 'குறியீட்டை உள்ளிடவும்',
  enterCodePlaceholder: '6 இலக்கக் குறியீடு உள்ளிடவும்',
  twoFactorCodeSubmit: 'உறுதிப்படுத்து',
  twoFactorCodeLink: 'என் குறியீட்டை அனுப்பவும்',
  twoFactorCodeCheckbox: 'மீண்டும் 2FA ஐ என்னிடம் கேட்க வேண்டாம்',
  reportsTitle: 'அறிக்கைகள்',
  newReport: 'புதிய அறிக்கை',
  admin: 'நிர்வாகம்',
  tags: 'குறிச்சொற்கள்',
  users: 'பயனர்கள்',
  exportreport: 'ஏற்றுமதி அறிக்கைகள்',
  profile: 'பதிவு செய்தது',
  terminology: 'சொல்',
  mysettings: 'என் அமைப்புகள்',
  edit: 'தொகு',
  organizations: 'அமைப்புக்கள்',
  analytics: 'அனலிட்டிக்ஸ்',
  analyticsTitle: 'இந்த வாரங்கள் கவனம் செலுத்துகிறது',
  analyticsSubmissions: 'சமர்ப்பிப்புகள்',
  analyticsReportByCat: 'வகை படிமுதன்மையான அறிக்கைகள்',
  analyticsFromLastWeek: 'கடந்த வாரம்',
  analyticsRepSubmited: 'அறிக்கைகள் சமர்ப்பிக்க',
  analyticsRepVerified: 'அறிக்கைகள் சரிபார்க்கப்பட்டது',
  analyticsIssuesResp: 'சிக்கல்கள் பதிலளித்தார்',
  analyticsStoriesCreated: 'கதைகள் உருவாக்கப்படுகின்றன',
  analyticsGeographicPrio: 'புவியியல் முன்னுரிமைகள்',
  analyticsPopularKeywords: 'பிரபலமான முக்கிய வார்த்தைகள்',
  analyticsKeyword: 'Keyword ஐக்',
  analyticsMentions: 'குறிப்பிடுகிறார்',
  analyticsUsertypes: 'பயனர் வகையான',
  analyticsTopUsers: 'சிறந்த பயனர்கள்',
  analyticsUsers: 'பயனர்கள்',
  analyticsActivity: 'நடவடிக்கை',
  contactUsers: 'தொடர்பு பயனர்கள்',
  send: 'அனுப்புக',
  sendToEveryone: 'அனைவருக்கும் அனுப்பவும்',
  sendSpecificUsers: 'குறிப்பிட்ட பயனர்கள் செய்தியை அனுப்பு',
  selectUsers: 'பயனர்களைத் தேர்ந்தெடுக்கவும்',
  enterMessage: 'உங்கள் செய்தியை இங்கு உள்ளிடவும்',
  search: 'தேடல்',
  addNew: 'புதியனவற்றை சேர்',
  save: 'சேமி',
  ok: 'Ok',
  enterFirstName: 'முதல் பெயரை உள்ளிடவும்',
  enterLastName: 'கடைசி பெயரை உள்ளிடவும்',
  enterEmailPlaceholder: 'மின்னஞ்சல் முகவரியை உள்ளிடவும்',
  createReport: 'அறிக்கை உருவாக்கவும்',
  reportDetails: 'அறிக்கை விவரம்',
  reportTitleLabel: 'அறிக்கை தலைப்பு',
  reportTitlePlaceholder: 'அறிக்கை தலைப்பை உள்ளிடவும்',
  reportDescriptionLabel: 'அறிக்கை விளக்கம்',
  reportDescriptionPlaceholder: 'அறிக்கை விளக்கம் உள்ளிடவும்',
  reportAuthorLabel: 'தேர்வு ஆசிரியர்',
  reportAuthorPlaceholder: 'ஆசிரியர் தேர்வு',
  reportHour: 'ஹவர்',
  reportMinute: 'மினிட்',
  reportMapLabel: 'வரைபடத்தில் தேர்வுசெய்யவும்',
  reportAddMedia: 'சேர் ஊடக',
  reportUploadMedia: 'பதிவேற்றப் புகைப்படங்கள், வீடியோக்கள், ஆடியோ அல்லது PDF',
  reportDragMedia: 'விடுவித்தல் ஊடக இங்கே கோப்புகளை',
  reportSupportMedia: 'நாம் புகைப்படங்கள், வீடியோக்கள், ஆடியோ அல்லது கள் ஆதரவு',
  reportBrowseMedia: 'உலவு',
  reportUploadFiles: 'பதிவேற்றிய கோப்புகள்',
  reportPublic: 'பொது',
  reportSeeActivity: 'காண்க நடவடிக்கை',
  reportUnverified: 'சரிபார்க்கப்படாத',
  reportVerified: 'சரிபார்க்கப்பட்ட',
  reportVerify: 'சரிபார்க்கவும்',
  reportSearch: 'தேடுதல் அறிக்கைகள்',
  reportedBy: 'மூலம் தெரிவிக்கப்பட்டது',
  reportWhatHappening: 'क्या हो रहा है',
  reportLocation: 'இருப்பிடம்',
  reportCoordinates: 'ஒருங்கிணைப்புகள்',
  copyDMS: 'நகல், DMS',
  copyDMM: 'நகல் DDs கலை',
  reportImageEvidence: 'பட ஆதாரம்',
  reportImageDescription: 'பட விளக்கம்',
  reportSaveClose: 'சேமித்து மூடு',
  reportAudioEvidence: 'ஆடியோ ஆதாரம்',
  reportPDFEvidence: 'பிடிஎப் பதிவேற்றங்கள்',
  reportVideoEvidence: 'வீடியோ ஆதாரம்',
  reportVideoDescription: 'வீடியோ விளக்கம்',
  reportUpdates: 'மேம்படுத்தல்கள்',
  reportActivityUploadMedia: 'பதிவேற்றம் ஊடக',
  reportActivityAssignUser: 'ஒதுக்கு பயனர்',
  reportActivitySetUrgency: 'அவசரம் அமை',
  reportActivityPlaceholder: 'இந்த அறிக்கையில் ஒரு கருத்து அல்லது கூடுதல் ஊடகத்தைச் சேர்க்கவும்',
  updateAction: 'புதுப்பிக்கப்பட்டது',
  editReport: 'அறிக்கை திருத்த',
  goBack: 'மீண்டும்',
  reportCritical: 'இந்த அறிக்கை முக்கியமானதாகும்',
  reportVeryUrgent: 'இந்த அறிக்கை மிகவும் அவசரமானது',
  reportUrgent: 'இந்த அறிக்கை அவசரமானது',
  reportFilter: 'பட்டியல்',
  reportStatus: 'வரைபடம்',
  reportFolders: 'கோப்புறைகள்',
  urgent: 'அவசரம்',
  reportDateCreated: 'உருவாக்கிய தேதி',
  reportAssignment: 'பணி',
  reportAssignee: 'ஒதுக்குநர்',
  reportAuthor: 'நூலாசிரியர்',
  reportDateCreatedPlaceholder: 'தேதி தேர்ந்தெடுக்கவும்',
  reportDateLabel: 'நிகழ்வு எப்போது நடந்தது?',
  reportDatePlaceholder: 'அறிக்கை தேதியை உள்ளிடவும்',
  reportMapTitle: 'அது நடந்த இடம்',
  reportDMSCoordinate: 'டி.எம்.எஸ் ஒருங்கிணைப்புகள்',
  reportDMMCoordinate: 'டி.எம்.எம் ஒருங்கிணைப்புகள்',
  contactRecent: 'சமீபத்திய அறிவிப்புகள்',
  storyTitle: 'உள்ளடக்கத்தை உருவாக்குங்கள்',
  storyCreate: 'கதை உருவாக்கு',
  storySearch: 'உள்ளடக்கத்தைத் தேடு',
  published: 'வெளியிடப்பட்ட',
  updated: 'புதுப்பிக்கப்பட்ட',
  publishedOn: 'வெளியிடப்படும்',
  storyType: 'கதை வகை',
  unpublish: 'வெளியிடாதே',
  publish: 'வெளியிடு',
  unpublished: 'வெளியிடப்படாதது',
  readFullReport: 'முழு அறிக்கை படிக்க',
  coverImage: 'கவர் படத்தை',
  storyTextContent: 'உரை உள்ளடக்கம்',
  storyTitlePlaceholder: 'உங்கள் தலைப்பை இங்கே உள்ளிடவும்',
  storySubtitlePlaceholder: 'உங்கள் வசனத்தை இங்கே உள்ளிடவும்',
  closeReport: 'அறிக்கையை மூடு',
  storyAddNew: 'புதிய உரை பெட்டியைச் சேர்க்கவும்',
  saveChanges: 'மாற்றங்களை சேமியுங்கள்',
  userOrganizationPlaceholder: 'பயனரின் அமைப்புகளை உள்ளிடவும்',
  enable2FA: '2FA ஐ இயக்கு',
  cancel: 'ரத்துசெய்',
  deleteWarning: 'அதை செயல்தவிர்க்க முடியாது',
  createTagGroup: 'புதிய குறிச்சொல் குழுவை உருவாக்கவும்',
  createTag: 'குழுவிற்கு புதிய குறிச்சொல்லை உருவாக்கவும்',
  createUser: 'புதிய பயனரை உருவாக்கவும்',
  createOrganization: 'புதிய அமைப்பை உருவாக்கவும்',
  createLayer: 'புதிய லேயரை உருவாக்கவும்',
  deleteTagGroup: 'குறிச்சொல் குழுவை நீக்கு',
  deleteTagGroupDescription: 'இந்த குறிச்சொல் குழுவை நீக்க விரும்புகிறீர்களா?',
  deleteTag: 'குறிச்சொல் உறுப்பை நீக்கு',
  deleteTagDescription: 'இந்த குறிச்சொல் உறுப்பை நீக்க விரும்புகிறீர்களா?',
  myTags: 'எனது குறிச்சொற்கள்',
  editTagGroup: 'குறிச்சொல் குழுவைத் திருத்து',
  mapParameters: 'வரைபட அளவுருக்கள்',
  latitude: 'அட்சரேகை',
  longitude: 'தீர்க்கரேகை',
  zoomLevel: 'பெரிதாக்கு நிலை',
  layers: 'அடுக்குகள்',
  vector: 'திசையன்',
  raster: 'ராஸ்டெரிலிருந்து',
  layerName: 'அடுக்கு பெயர்',
  layerShapeFiles: 'நாம் shapefiles, KML மற்றும் GeoJSON ஐப் ஆதரவு',
  dragdropLayers: 'அடுக்குகளை இங்கே இழுத்து விடுங்கள்',
  color: 'நிறம்',
  translations: 'மொழிபெயர்ப்பு',
  name: 'பெயர்',
  defaultLanguage: 'இயல்புநிலை மொழி',
  selectReportToExport: 'ஏற்றுமதி அறிக்கைகள் தேர்வு',
  exportAll: 'ஏற்றுமதி அனைத்து',
  export: 'ஏற்றுமதி செய்ய',
  enterNameIn: 'பெயரை உள்ளிடவும்',
  enterLayerNameIn: 'அடுக்கு பெயரை உள்ளிடவும்',
  enterLayerName: 'அடுக்கு பெயரை உள்ளிடவும்',
  rasterHostedUrl: 'ராஸ்டெரிலிருந்து Hosted URL ஐ',
  enterRasterHostedUrl: 'ராஸ்டர் ஹோஸ்ட் செய்யப்பட்ட URL ஐ உள்ளிடவும்',
  enterColor: 'வண்ணத்தை உள்ளிடவும்',
  enterUserRole: 'பயனர் பாத்திரத்தை உள்ளிடவும்',
  deleteSuccess: 'ஆம் நீக்கு',
  subject: 'பொருள்',
  enterSubject: 'பொருள் உள்ளிடவும்',
  recipients: 'பெறுநர்கள்',
  deleteUser: 'பயனரை நீக்கு',
  deleteUserDescription: 'இந்த பயனரை நீக்க விரும்புகிறீர்களா?',
  deleteOrganization: 'அமைப்பை நீக்கு',
  deleteOrganizationDescription: 'இந்த அமைப்பை நீக்க விரும்புகிறீர்களா?',
  singleSelect: 'தனித் தேர்வு',
  multiSelect: 'மல்டி தேர்ந்தெடுக்க',
  textField: 'உரை துறையில்',
  searchInTheMap: 'நான் வரைபடத்தை நகர்த்தும்போது தேடுங்கள்',
  select: 'தேர்வு',
  enterReportTag: 'அறிக்கையின் {{tag}} ஐ உள்ளிடவும்',
  enterZoom: 'பெரிதாக்கு என்பதைத் தேர்ந்தெடுக்கவும்',
  basemaps: 'Basemaps',
  map: 'வரைபடம்',
  satelitte: 'செயற்கைக்கோள்',
  deleteReport: 'அறிக்கையை நீக்கு',
  deleteReportDescription: 'இந்த அறிக்கையை நீக்க விரும்புகிறீர்களா?',
  closeAction: 'நெருக்கமான',
  reportLifecycle: 'வாழ்க்கை சுழற்சி',
  languages: 'மொழிகள்',
  additionalSettings: 'கூடுதல் அமைப்புகள்',
  deleteLayer: 'லேயரை நீக்கு',
  deleteLayerDescription: 'இந்த அடுக்கை நீக்க விரும்புகிறீர்களா?',
  apply: 'இடு',
  reset: 'மீட்டமை',
  unverify: 'நிராகரி',
  enabledLanguages: 'இயக்கப்பட்ட மொழிகள்',
  selectLanguages: 'மொழிகளைத் தேர்ந்தெடுக்கவும்',
  updateTerminology: 'சொற்களைப் புதுப்பிக்கவும்',
  currentString: 'தற்போதைய சரம்',
  resetToDefault: 'இயல்புநிலைக்கு மீட்டமை',
  dragCsv: 'மொழி CSV கோப்பை இங்கே இழுத்து விடுங்கள்',
  supportCsv: 'நாங்கள் CSV ஐ மட்டுமே ஆதரிக்கிறோம்',
  anonymousSignIn: 'மொபைலில் அநாமதேய உள்நுழைவுகள்',
  mapsOnMobile: 'மொபைலில் வரைபடங்கள்',
  lessonPath: 'பாடங்கள் பாதை',
  emergencyAlerts: 'அவசர எச்சரிக்கைகள்',
  emergencyContactUser: 'அவசர தொடர்பு பயனர்',
  emergencyContactNumber: 'அவசர தொடர்பு எண்',
  contactNumber: 'தொடர்பு எண்',
  enterCustomLessonPath: 'தனிப்பயன் பாடம் பாதையை உள்ளிடவும்',
  urgency: 'அவசர',
  analyticsPage: 'பகுப்பாய்வு பக்கம்',
  notificationsPage: 'அறிவிப்புகள் பக்கம்',
  storiesPage: 'கதைகள் பக்கம்',
  dashboardConfiguration: 'டாஷ்போர்டு உள்ளமைவுகள்',
  publicSite: 'பொது தளம்',
  mapOnPublicSite: 'பொது தளத்தில் வரைபடம்',
  grievencePortal: 'குறை தீர்க்கும் போர்டல்',
  localization: 'உள்ளூர்மயமாக்கல்',
  timeZone: 'நேரம் மண்டலம்',
  mobileOrganizationLabel: 'மொபைல் பயன்பாட்டிற்கான அமைப்பு',
  mobileOrganizationPlaceholder: 'ஒரு அமைப்பைத் தேர்ந்தெடுக்கவும்',
  sendByUsers: 'பயனர்களால் அனுப்பவும்',
  sendByRoles: 'பங்கு மூலம் அனுப்பு',
  private: 'தனியார்',
  reportState: 'நிலை',
  date: 'தேதி',
  startDate: 'தொடக்க தேதி',
  endDate: 'கடைசி தேதி',
  organization: 'அமைப்பு',
  seconds: 'விநாடிகள்',
  degrees: 'டிகிரி',
  direction: 'திசையில்',
  default: 'வழுவுதல்',
  custom: 'தனிப்பயன்',
  addAdditionalEmergencyNumber: 'கூடுதல் அவசர தொடர்பு எண்ணைச் சேர்க்கவும்',
  stories: 'கதைகள்',
  messages: 'செய்திகள்',
  addTagGroup: 'குறிச்சொல் குழுவைச் சேர்க்கவும்',
  addNewUser: 'பயனரைச் சேர்க்கவும்',
  addNewOrganization: 'அமைப்பைச் சேர்',
  addNewLayer: 'லேயரைச் சேர்க்கவும்',
  exportAction: 'ஏற்றுமதி',
};

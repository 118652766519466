import * as React from 'react';
import { Redirect, Route } from 'react-router-dom';

const PasswordRoute = ({ component: Component, tempMail, ...rest }) => {
  const routeComponent = (props) =>
    tempMail ? (
      <Component {...props} />
    ) : (
      <Redirect to={{ pathname: '/forgot-password', state: { from: props.location } }} />
    );

  return <Route {...rest} render={routeComponent} />;
};

export default PasswordRoute;

import { addMinutes } from 'date-fns';
import i18n from '../translations/i18n';

export const getUTCDatenow = () => {
  const date = new Date();
  return addMinutes(date, date.getTimezoneOffset());
};

export const formatDate = (val, toUTC = false) => {
  let date = new Date(val);
  if(toUTC) date = addMinutes(date, date.getTimezoneOffset());
  return date.toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' });
};

export function timeSince(date) {
  const seconds = Math.floor((getUTCDatenow() - date) / 1000);

  let interval = seconds / 31536000;

  if (interval >= 1) {
    return i18n.t('dateTrans.years', { count: Math.floor(interval) });
  }
  interval = seconds / 2592000;
  if (interval >= 1) {
    return i18n.t('dateTrans.months', { count: Math.floor(interval) });
  }
  interval = seconds / 86400;
  if (interval >= 1) {
    return i18n.t('dateTrans.days', { count: Math.floor(interval) });
  }
  interval = seconds / 3600;
  if (interval >= 1) {
    return i18n.t('dateTrans.hours', { count: Math.floor(interval) });
  }
  interval = seconds / 60;
  if (interval >= 1) {
    return i18n.t('dateTrans.minutes', { count: Math.floor(interval) });
  }
  interval = seconds % 60;
  return i18n.t('dateTrans.seconds', { count: Math.floor(interval) });
}

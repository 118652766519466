import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import t from 'prop-types';
import { connect } from 'react-redux';
import PrivateRoute from './PrivateRoute';
import ReportsContainer from '../components/dashboard/ReportsContainer/ReportsContainer';
import TwoFactorCode from '../components/login/TwoFactorCode/TwoFactorCode';
import SetPassword from '../components/login/SetPassword/SetPassword';
import ResetPassword from '../components/login/ResetPassword/ResetPassword';
import ForgotPassword from '../components/login/ForgotPassword/ForgotPassword';
import Login from '../components/login/Login/Login';
import TwoFactorRoute from './TwoFactorRoute';
import LoginRoute from './LoginRoute';
import PasswordRoute from './PasswordRoute';
import CreateReport from '../components/dashboard/CreateReport/CreateReport';
import AdminContainer from '../components/dashboard/AdminContainer/AdminContainer';
import AnalyticsContainer from '../components/dashboard/AnalyticsContainer/AnalyticsContainer';
import MessagesContainer from '../components/dashboard/MessagesContainer/MessagesContainer';
import StoriesContainer from '../components/dashboard/StoriesContainer/StoriesContainer';
import UserContainer from '../components/dashboard/UserContainer/UserContainer';
import StoryCreate from '../components/dashboard/StoriesContainer/StoryCreate/StoryCreate';
import StoryInfoContainer from '../components/dashboard/StoryInfoContainer/StoryInfoContainer';
import ReportsView from '../components/dashboard/ReportView/ReportView';
import {PrivateNotFound, PublicNotFound} from '../components/NotFound/NotFound';
import EditPrivateRoute from './EditPrivateRoute';
import OrgAdminRoute from './OrgAdminRoute';
import AdminRoute from './AdminRoute';

function Main({
  isLoggedIn, is2FA, userInfo, tempMail,
}) {
  const twoFactor = userInfo.needs2FA ? is2FA : true;
  return (
    <Switch>
      <LoginRoute
        path="/"
        exact
        component={Login}
        isLoggedIn={isLoggedIn}
        is2FA={twoFactor}
      />
      <LoginRoute
        path="/login"
        exact
        component={Login}
        isLoggedIn={isLoggedIn}
        is2FA={twoFactor}
      />
      <LoginRoute
        path="/forgot-password"
        exact
        component={ForgotPassword}
        isLoggedIn={isLoggedIn}
        is2FA={twoFactor}
      />
      <PrivateRoute
        path="/dashboard"
        isLoggedIn={isLoggedIn}
        is2FA={twoFactor}
        exact
        component={ReportsContainer}
      />
      <EditPrivateRoute
        path="/new-report"
        isLoggedIn={isLoggedIn}
        is2FA={twoFactor}
        userInfo={userInfo}
        exact
        component={CreateReport}
      />
      <PrivateRoute
        path="/report/:id"
        isLoggedIn={isLoggedIn}
        is2FA={twoFactor}
        exact
        component={ReportsView}
      />
      <EditPrivateRoute
        path="/stories/create"
        isLoggedIn={isLoggedIn}
        is2FA={twoFactor}
        userInfo={userInfo}
        exact
        component={StoryCreate}
      />
      <PrivateRoute
        path="/stories/:id"
        isLoggedIn={isLoggedIn}
        is2FA={twoFactor}
        exact
        component={StoryInfoContainer}
      />
      <PrivateRoute
        path="/stories"
        isLoggedIn={isLoggedIn}
        is2FA={twoFactor}
        exact
        component={StoriesContainer}
      />
      <OrgAdminRoute
        path="/messages"
        isLoggedIn={isLoggedIn}
        is2FA={twoFactor}
        userInfo={userInfo}
        exact
        component={MessagesContainer}
      />
      <PrivateRoute
        path="/analytics"
        isLoggedIn={isLoggedIn}
        is2FA={twoFactor}
        exact
        component={AnalyticsContainer}
      />
      <OrgAdminRoute
        path="/admin/tags"
        isLoggedIn={isLoggedIn}
        is2FA={twoFactor}
        exact
        userInfo={userInfo}
        component={AdminContainer}
      />
      <OrgAdminRoute
        path="/admin/users"
        isLoggedIn={isLoggedIn}
        is2FA={twoFactor}
        exact
        userInfo={userInfo}
        component={AdminContainer}
      />
      <AdminRoute
        path="/admin/organizations"
        isLoggedIn={isLoggedIn}
        is2FA={twoFactor}
        exact
        userInfo={userInfo}
        component={AdminContainer}
      />
      <OrgAdminRoute
        path="/admin/layers"
        isLoggedIn={isLoggedIn}
        is2FA={twoFactor}
        exact
        userInfo={userInfo}
        component={AdminContainer}
      />
      <OrgAdminRoute
        path="/admin/export-reports"
        isLoggedIn={isLoggedIn}
        is2FA={twoFactor}
        exact
        userInfo={userInfo}
        component={AdminContainer}
      />
      <AdminRoute
        path="/admin/settings/languages"
        isLoggedIn={isLoggedIn}
        is2FA={twoFactor}
        exact
        userInfo={userInfo}
        component={AdminContainer}
      />
      <AdminRoute
        path="/admin/settings/map"
        isLoggedIn={isLoggedIn}
        is2FA={twoFactor}
        exact
        userInfo={userInfo}
        component={AdminContainer}
      />
      <AdminRoute
        path="/admin/settings/dashboard"
        isLoggedIn={isLoggedIn}
        is2FA={twoFactor}
        exact
        userInfo={userInfo}
        component={AdminContainer}
      />
      <AdminRoute
        path="/admin/settings/app"
        isLoggedIn={isLoggedIn}
        is2FA={twoFactor}
        exact
        userInfo={userInfo}
        component={AdminContainer}
      />
      <AdminRoute
        path="/admin/settings/public-site"
        isLoggedIn={isLoggedIn}
        is2FA={twoFactor}
        exact
        userInfo={userInfo}
        component={AdminContainer}
      />
      <AdminRoute
        path="/admin/settings"
        isLoggedIn={isLoggedIn}
        is2FA={twoFactor}
        exact
        userInfo={userInfo}
        component={AdminContainer}
      />
      <PrivateRoute
        path="/admin"
        isLoggedIn={isLoggedIn}
        is2FA={twoFactor}
        component={AdminContainer}
      />
      <PrivateRoute
        path="/profile"
        isLoggedIn={isLoggedIn}
        is2FA={twoFactor}
        exact
        userInfo={userInfo}
        component={UserContainer}
      />
      <PasswordRoute
        path="/confirm-password"
        component={ResetPassword}
        tempMail={tempMail}
      />
      <LoginRoute
        path="/reset-password"
        component={SetPassword}
        isLoggedIn={isLoggedIn}
        is2FA={twoFactor}
      />
      <TwoFactorRoute
        path="/two-factor-code"
        isLoggedIn={isLoggedIn}
        needs2FA={!twoFactor}
        component={TwoFactorCode}
      />
      <Route
        path="/404"
        component={isLoggedIn && twoFactor ? PrivateNotFound : PublicNotFound}
      />
      <Redirect to="/404"/>

    </Switch>
  );
}

const mapStateToProps = ({user}) => ({
  userInfo: user.userInfo,
  tempMail: user.tempMail,
  isLoggedIn: user.token,
  is2FA: user.twoFactor,
});

Main.propTypes = {
  isLoggedIn: t.oneOfType([t.bool, t.string]),
  is2FA: t.bool,
  userInfo: t.object.isRequired,
  tempMail: t.string.isRequired,
};

Main.defaultProps = {
  isLoggedIn: false,
  is2FA: false,
};

export default connect(mapStateToProps, null)(Main);

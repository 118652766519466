import React from 'react'
import { Radio } from 'semantic-ui-react'

const Toggle = ({ label, value, handleChange, customLabelClass, inverse=false, disabled }) => (
  <div className="toggle__wrapper">
    {!inverse && (
      <div className={`toggle__label ${customLabelClass}`}>
        {label}
      </div>
    )}
    <Radio
      className="toggle__element"
      checked={value}
      onChange={handleChange}
      toggle
      disabled={disabled}
    />
    {inverse && (
      <div className={`toggle__label toggle__label-inverse ${customLabelClass}`}>
        {label}
      </div>
    )}
  </div>
);

export default Toggle

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import t from 'prop-types';
import { format, parse } from 'date-fns';
import '../../../styles/index.scss';
import mapDispatchToProps from '../../../actions/index';
import dashboardHoc from '../DashboardHoc/DashboardHoc';
import FullHeader from '../../ui/FullHeader/FullHeader';
import MessageElement from '../MessageElement/MessageElement';
import MessageForm from '../MessageForm/MessageForm';
import { REPORT_EVENT_DATE_PARSER } from '../../../shared/consts';
import { isUserAdmin } from '../../../utils';
import SelectBox from '../../ui/SelectBox/SelectBox';

function MessagesContainer(props) {
  const { t } = useTranslation();

  useEffect(() => {
    const getMessages = async () => {
      await props.getMessages();
    };
    if (isUserAdmin(props.userInfo)) props.getOrganizations();
    if (props.messages.length === 0) getMessages();
    document.title = 'Timby - Broadcasts';
  }, []);

  useEffect(() => {
    const onChangeOrganization = async () => {
      await props.getMessages();
      await props.getUsers();
    };
    onChangeOrganization();
  }, [props.selectedOrganization]);

  const handleChange = (value) =>{
    props.updateSelectedOrganization(value);
  };

  const organizationOptions = props?.organizations?.map((elem) => (
    { key: elem.id, value: elem.id, text: elem.name }
  ));

  return (
    <div className="messages-container">
      <FullHeader title={t('contactUsers')}>
        {isUserAdmin(props.userInfo) && (
          <SelectBox
            medium
            options={organizationOptions}
            value={props.selectedOrganization}
            handleChange={(e, { value }) => handleChange(value)}
          />
        )}
      </FullHeader>
      <div className="messages-container__wrapper whitepagescrollbar">
        <MessageForm />
        <div className="messages-container__wrapper__recents">
          <p className="messages-container__title">{t('contactRecent')}</p>
          <div className="messages-container__wrapper__recents-box defaultscrollbar">
            {props.messages.map((elem) => (
              <MessageElement
                id={`${elem.sent_date}-${elem.subject}`}
                text={elem.message}
                key={`messages-${elem.id}`}
                date={format(parse(elem.sent_date, REPORT_EVENT_DATE_PARSER, new Date()), 'dd MMMM yyyy')}
                title={elem.subject}
                recipients={elem.recipient}
                author={elem.author}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = ({ messages, user, admin }) => ({
  messages: messages.messages,
  userInfo: user.userInfo,
  organizations: admin.organizations,
  selectedOrganization: admin.selectedOrganizationId,

});

MessagesContainer.propTypes = {
  messages: t.array,
  getMessages: t.func.isRequired,
  getOrganizations: t.func.isRequired,
  organizations: t.array,
  selectedOrganization: t.string.isRequired,
  userInfo: t.object.isRequired,
};

MessagesContainer.defaultProps = {
  messages: [],
  organizations: [],
};

export default dashboardHoc(connect(mapStateToProps, mapDispatchToProps)(MessagesContainer));
